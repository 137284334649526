// SpiritualFeedbackModal.js
import React, { useEffect, useCallback } from 'react';
import { 
  BookOpen, 
  Calendar, 
  X 
} from 'lucide-react';
import './SpiritualFeedbackModal.css';

const SpiritualFeedbackModal = ({ 
  isOpen,
  sections = {
    Understanding: { content: '', icon: '🌱', complete: false },
    Scripture: { content: '', icon: '📖', complete: false },
    Spiritual: { content: '', icon: '✝️', complete: false },
    Growth: { content: '', icon: '🌟', complete: false },
    Prayer: { content: '', icon: '🙏', complete: false }
  },
  currentSection = '',
  isComplete = false,
  nextCheckIn,
  onClose 
}) => {
  // Debug section data
  useEffect(() => {
    if (isOpen) {
      console.log('=== SpiritualFeedbackModal Render ===', {
        currentSection,
        isComplete,
        sections: Object.entries(sections).map(([name, data]) => ({
          name,
          contentLength: data.content?.length || 0,
          complete: data.complete
        }))
      });
    }
  }, [isOpen, sections, currentSection, isComplete]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const renderFormattedContent = useCallback((content, sectionName) => {
    if (!content) {
      console.log(`No content for section ${sectionName}`);
      return null;
    }

    console.log(`Rendering content for section ${sectionName}:`, {
      contentLength: content.length,
      firstChars: content.substring(0, 50)
    });

    return content.split('\n').map((line, idx) => {
      const trimmedLine = line.trim();
      if (!trimmedLine) return null;

      // Handle section headers
      if (trimmedLine.startsWith('# ')) {
        return (
          <h4 key={idx} className="section-header">
            {trimmedLine.replace('# ', '')}
          </h4>
        );
      }
      // Handle scripture quotes
      else if (trimmedLine.startsWith('> ')) {
        return (
          <blockquote key={idx} className="scripture-quote">
            {trimmedLine.replace('> ', '')}
          </blockquote>
        );
      }
      // Handle bullet points
      else if (trimmedLine.startsWith('* ')) {
        return <li key={idx}>{trimmedLine.replace('* ', '')}</li>;
      }
      // Handle emphasis points
      else if (trimmedLine.startsWith('⭐ ')) {
        return (
          <div key={idx} className="emphasis-point">
            <span className="emphasis-icon">⭐</span>
            <span className="emphasis-text">{trimmedLine.replace('⭐ ', '')}</span>
          </div>
        );
      }
      // Handle reflection points
      else if (trimmedLine.startsWith('💭 ')) {
        return (
          <div key={idx} className="reflection-point">
            <span className="reflection-icon">💭</span>
            <span className="reflection-text">{trimmedLine.replace('💭 ', '')}</span>
          </div>
        );
      }
      // Handle questions
      else if (trimmedLine.includes('<?>')){
        return (
          <div key={idx} className="question-container">
            <span className="question-icon">❓</span>
            <span className="question-text">{trimmedLine.replace('<?>', '').trim()}</span>
          </div>
        );
      }
      // Handle special markers with section-specific styling
      else if (line.match(/^[🙏💭✝️📖❤️🌟⭐]/)) {
        const [marker, ...text] = line.split(' ');
        return (
          <div key={idx} className={`special-content ${sectionName.toLowerCase()}`}>
            <span className="marker-icon">{marker}</span>
            <span className="marker-text">{text.join(' ')}</span>
          </div>
        );
      }
      // Regular text with section-specific styling
      return (
        <p key={idx} className={`regular-text ${sectionName.toLowerCase()}`}>
          {trimmedLine}
        </p>
      );
    }).filter(Boolean); // Remove null entries
  }, []);

  if (!isOpen) return null;

  console.log('=== Rendering Modal Sections ===');
  return (
    <div className="modal-backdrop">
      <div className="modal-dialog">
        {/* Header */}
        <div className="modal-header">
          <div className="header-wrapper">
            <BookOpen className="header-icon" />
            <h2 className="header-title">Spiritual Reflection</h2>
          </div>
          <button 
            onClick={onClose}
            className="close-button-icon"
            aria-label="Close modal"
          >
            <X />
          </button>
        </div>

        {/* Content */}
        <div className="modal-body">
          {/* Dynamically render sections */}
          {Object.entries(sections).map(([name, section]) => {
            console.log(`Processing section ${name}:`, {
              hasContent: Boolean(section.content),
              contentLength: section.content?.length || 0,
              isComplete: section.complete
            });

            if (!section.content) return null;

            return (
              <section 
                key={name}
                className={`content-section ${currentSection === name ? 'active' : ''} ${section.complete ? 'complete' : ''}`}
              >
                <div className="section-title">
                  <span className="section-icon">{section.icon}</span>
                  <h3>{name.replace(/([A-Z])/g, ' $1').trim()}</h3>
                </div>
                <div className={`guidance-text ${section.complete ? 'complete' : 'streaming'}`}>
                  {renderFormattedContent(section.content, name)}
                </div>
                {currentSection === name && !section.complete && (
                  <div className="section-typing-indicator">
                    <div className="typing-dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}
              </section>
            );
          })}

          {/* Next Check-in */}
          {nextCheckIn && isComplete && (
            <section className="content-section next-checkin">
              <div className="section-title">
                <Calendar className="section-icon" />
                <h3>Next Check-in</h3>
              </div>
              <div className="checkin-info">
                <span className="date-label">Your next scheduled check-in:</span>
                <span className="date-value">{nextCheckIn}</span>
              </div>
            </section>
          )}

          {/* Loading Indicator */}
          {!isComplete && (
            <div className="streaming-indicator">
              <div className="typing-dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>Preparing spiritual guidance...</p>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="modal-footer">
          <button onClick={onClose} className="close-button">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpiritualFeedbackModal;