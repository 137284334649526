import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FiMessageCircle,
  FiSun,
  FiTarget,
  FiUsers,
  FiArrowRight,
  FiCheck,
  FiHeart,
  FiMail,
  FiPhone,
  FiMapPin,
  FiInstagram,
  FiLinkedin,
  FiFacebook,
  FiBook,
  FiCompass,
  FiCalendar,
  FiStar,
} from "react-icons/fi";
import "./CaseStudyPage.css";
import PublicLayout from "../../components/PublicLayout/PublicLayout";

const CaseStudyPage = () => {
  const navigate = useNavigate();

  const handleDemoRequest = () => {
    navigate("/demo");
  };

  const keyHighlights = [
    {
      icon: <FiUsers size={24} />,
      title: "Study Demographics",
      points: [
        "3 active participants aged 30-33",
        "St. Raymond's Parish, Menlo Park",
        "2-week intensive pilot period",
        "Part of 7-week Gospel series",
      ],
    },
    {
      icon: <FiTarget size={24} />,
      title: "Focus Areas",
      points: [
        "Saints' lives integration",
        "Professional faith application",
        "Sacramental understanding",
        "Personal spiritual growth",
      ],
    },
    {
      icon: <FiCalendar size={24} />,
      title: "Engagement Metrics",
      points: [
        "100% completion rate",
        "Daily prayer participation",
        "Multiple weekly reflections",
        "Continuous feedback loop",
      ],
    },
  ];

  const implementationSteps = [
    {
      title: "Initial Setup",
      content:
        "Created dedicated Rescue Project group space with automated email invitations for participants.",
      icon: <FiCompass size={24} />,
      color: "blue",
    },
    {
      title: "Content Integration",
      content:
        "Weekly activities configured to align with video content and group reflection prompts.",
      icon: <FiBook size={24} />,
      color: "green",
    },
    {
      title: "AI Configuration",
      content:
        "Customized spiritual direction responses incorporating current theological themes.",
      icon: <FiStar size={24} />,
      color: "purple",
    },
  ];

  const findings = [
    {
      icon: <FiMessageCircle size={24} />,
      title: "User Interaction",
      points: [
        "Strong preference for personalized guidance",
        "Integration of faith with daily life",
        "Deep engagement with theological content",
        "Regular reflection submissions",
      ],
    },
    {
      icon: <FiSun size={24} />,
      title: "Content Impact",
      points: [
        "Enhanced spiritual awareness",
        "Practical faith application",
        "Improved prayer consistency",
        "Deeper theological understanding",
      ],
    },
  ];

  const productUpdates = [
    {
      title: "Enhanced Engagement",
      description:
        "Implemented structured daily prayer prompts across morning, midday, and evening times.",
      icon: <FiHeart size={24} />,
    },
    {
      title: "Admin Controls",
      description:
        "Expanded administration tools for customized content and group management.",
      icon: <FiTarget size={24} />,
    },
  ];

  return (
    <PublicLayout
      className="case-study-container"
      render={() => (
        <>
          <header className="case-study-hero">
            <div className="hero-content">
              <h1>Rescue Project Small Group</h1>
              <p className="subtitle">St. Raymond's Parish Case Study</p>
              <p className="description">
                Exploring the impact of AI-enhanced spiritual direction in a
                parish small group context
              </p>
            </div>
          </header>

          <section className="key-highlights">
            <div className="section-header">
              <h2>Study Overview</h2>
              <p>Key metrics and observations from the pilot program</p>
            </div>
            <div className="highlights-grid">
              {keyHighlights.map((highlight, index) => (
                <div key={index} className="highlight-card">
                  <div className="highlight-icon">{highlight.icon}</div>
                  <h3>{highlight.title}</h3>
                  <ul>
                    {highlight.points.map((point, i) => (
                      <li key={i}>
                        <FiCheck className="check-icon" />
                        <span>{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          <section className="implementation">
            <div className="section-header">
              <h2>Implementation Process</h2>
              <p>Technical configuration and deployment steps</p>
            </div>
            <div className="implementation-grid">
              {implementationSteps.map((step, index) => (
                <div
                  key={index}
                  className={`implementation-card ${step.color}`}
                >
                  <div className="step-icon">{step.icon}</div>
                  <h3>{step.title}</h3>
                  <p>{step.content}</p>
                </div>
              ))}
            </div>
          </section>

          <section className="key-findings">
            <div className="section-header">
              <h2>Key Findings</h2>
              <p>Critical insights from the pilot program</p>
            </div>
            <div className="findings-grid">
              {findings.map((finding, index) => (
                <div key={index} className="finding-card">
                  <div className="finding-icon">{finding.icon}</div>
                  <h3>{finding.title}</h3>
                  <ul>
                    {finding.points.map((point, i) => (
                      <li key={i}>
                        <FiCheck className="check-icon" />
                        <span>{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          <section className="product-evolution">
            <div className="section-header">
              <h2>Product Evolution</h2>
              <p>Key improvements implemented based on pilot feedback</p>
            </div>
            <div className="updates-grid">
              {productUpdates.map((update, index) => (
                <div key={index} className="update-card">
                  <div className="update-icon">{update.icon}</div>
                  <h3>{update.title}</h3>
                  <p>{update.description}</p>
                </div>
              ))}
            </div>
          </section>

          <section className="demo-cta">
            <div className="cta-content">
              <h2>Transform Your Parish's Digital Ministry</h2>
              <p>
                Join forward-thinking parishes in providing AI-enhanced
                spiritual guidance
              </p>

              <div className="feature-list">
                <div className="feature-item">
                  <FiCheck className="feature-check" />
                  <span>24/7 AI Spiritual Direction</span>
                </div>
                <div className="feature-item">
                  <FiCheck className="feature-check" />
                  <span>Custom Group Management</span>
                </div>
                <div className="feature-item">
                  <FiCheck className="feature-check" />
                  <span>Customizable AI Responses</span>
                </div>
                <div className="feature-item">
                  <FiCheck className="feature-check" />
                  <span>Comprehensive Analytics</span>
                </div>
              </div>

              <button onClick={handleDemoRequest} className="demo-button">
                Request Parish Demo
                <FiArrowRight className="button-icon" />
              </button>
            </div>
          </section>

          <footer className="case-study-footer">
            <div className="footer-content">
              <div className="footer-section brand">
                <Link to="/" className="footer-logo">
                  <img src="/logo.png" alt="CrossValidation.ai" />
                  <span>CrossValidation.ai</span>
                </Link>
                <p>
                  Combining Catholic wisdom with AI technology to provide
                  personalized spiritual guidance for modern Catholics.
                </p>
              </div>

              <div className="footer-section contact">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <FiMapPin className="contact-icon" />
                    <span>500 Fremont Street, Menlo Park, CA 94025</span>
                  </li>
                  <li>
                    <FiPhone className="contact-icon" />
                    <a href="tel:+16506902002">(650) 690-2002</a>
                  </li>
                  <li>
                    <FiMail className="contact-icon" />
                    <a href="mailto:Adrian@CrossValidation.ai">
                      Adrian@CrossValidation.ai
                    </a>
                  </li>
                </ul>
              </div>

              <div className="footer-section social">
                <h4>Connect With Us</h4>
                <div className="social-links">
                  <a
                    href="https://www.facebook.com/people/CrossValidationai/61567003652786/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <FiFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/crossvalidationai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <FiInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/trycrossvalidation/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <FiLinkedin />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <p>
                &copy; {new Date().getFullYear()} CrossValidation.ai. All rights
                reserved.
              </p>
            </div>
          </footer>
        </>
      )}
    />
  );
};

export default CaseStudyPage;