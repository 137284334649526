import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FiLoader,
  FiHeart,
  FiSend,
  FiUsers,
  FiChevronDown,
  FiCheck,
  FiGlobe,
} from "react-icons/fi";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import { API_ENDPOINTS } from "../../config/api";
import axios from 'axios';
import './DailyPrayer.css';

const DailyPrayer = () => {
  const { userId, tempUserId } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const effectiveUserId = userId || tempUserId;

  // Core state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCompletionFeedback, setShowCompletionFeedback] = useState(false);
  const [sessionActive, setSessionActive] = useState(false);
  const [wsConnection, setWsConnection] = useState(null);

  const [messageSequence, setMessageSequence] = useState([]);
  const [lastMessageId, setLastMessageId] = useState(null);


  // Chat state
  const [userInput, setUserInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [currentResponse, setCurrentResponse] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);

  // Group navigation state
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  // Refs
  const chatRef = useRef(null);
  const textareaRef = useRef(null);
  const mountedRef = useRef(true);
  const shouldScrollRef = useRef(true);

  const handleWebSocketMessage = useCallback((event) => {
    try {
      const data = JSON.parse(event.data);
      console.log('WebSocket message received:', data);

      switch (data.type) {
        case 'session.created':
        case 'session.updated':
          console.log('Session status:', data.type);
          break;

        case 'response.text.delta':
          // Accumulate streaming text
          setCurrentResponse(prev => prev + data.delta);
          if (shouldScrollRef.current && chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
          }
          break;

        case 'conversation.item.created':
          const item = data.item;
          if (item.role === 'assistant' && item.type === 'message') {
            const textContent = item.content.find(c => c.type === 'text');
            if (textContent) {
              setConversation(prev => {
                // Check if we already have this message
                const exists = prev.some(msg => msg.id === item.id);
                if (exists) return prev;
                
                return [...prev, {
                  id: item.id,
                  type: 'response',
                  content: textContent.text,
                  timestamp: new Date().toISOString()
                }];
              });
            }
          }
          break;

        case 'response.text.done':
          // Don't clear currentResponse immediately
          if (data.text) {
            setConversation(prev => {
              // Check if this response is already in the conversation
              const exists = prev.some(msg => msg.content === data.text);
              if (exists) return prev;

              return [...prev, {
                id: `response-${Date.now()}`,
                type: 'response',
                content: data.text,
                timestamp: new Date().toISOString()
              }];
            });
            setCurrentResponse("");
            setIsStreaming(false);
            setLoading(false);
          }
          break;

        case 'response.output_item.done':
          const outputItem = data.item;
          if (outputItem?.content?.[0]?.text) {
            setConversation(prev => {
              // Check if this response is already in the conversation
              const exists = prev.some(msg => msg.content === outputItem.content[0].text);
              if (exists) return prev;

              return [...prev, {
                id: outputItem.id || `response-${Date.now()}`,
                type: 'response',
                content: outputItem.content[0].text,
                timestamp: new Date().toISOString()
              }];
            });
          }
          setCurrentResponse("");
          setIsStreaming(false);
          setLoading(false);
          break;

        case 'response.done':
          // Keep the final message in conversation
          setIsStreaming(false);
          setLoading(false);
          break;

        case 'error':
          console.error('API error:', data.error);
          setError(data.error?.message || 'An error occurred during conversation');
          setLoading(false);
          setIsStreaming(false);
          break;

        case 'response.cancelled':
          setCurrentResponse("");
          setIsStreaming(false);
          setLoading(false);
          break;

        default:
          console.log('Other message type:', data.type);
          break;
      }
    } catch (err) {
      console.error('Error handling WebSocket message:', err);
      setError('Error processing response');
      setLoading(false);
      setIsStreaming(false);
    }
  }, []);




  const initializeWebSocket = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams();
      if (selectedGroupId) queryParams.append('group_id', selectedGroupId);
      if (effectiveUserId) queryParams.append('user_id', effectiveUserId);
      
      const wsUrl = `${API_ENDPOINTS.TEXT_SOCKET}${
        queryParams.toString() ? `?${queryParams.toString()}` : ''
      }`;

      const ws = new WebSocket(wsUrl);
      ws.onmessage = handleWebSocketMessage;

      ws.onerror = (error) => {
        console.error('WebSocket error:', { 
          error, 
          groupId: selectedGroupId,
          userId: effectiveUserId 
        });
        setError('Connection error occurred');
        setLoading(false);
      };

      ws.onclose = () => {
        console.log('WebSocket connection closed', { 
          groupId: selectedGroupId,
          userId: effectiveUserId 
        });
        setWsConnection(null);
        setSessionActive(false);
      };

      ws.onopen = () => {
        console.log('WebSocket connected successfully', { 
          groupId: selectedGroupId,
          userId: effectiveUserId 
        });
        
        setWsConnection(ws);
        setSessionActive(true);
        setLoading(false);

        ws.send(JSON.stringify({
          type: 'session.initialize',
          data: {
            userId: effectiveUserId,
            groupId: selectedGroupId,
            timestamp: new Date().toISOString()
          }
        }));
      };

      const connectionTimeout = setTimeout(() => {
        if (ws.readyState !== WebSocket.OPEN) {
          console.error('WebSocket connection timeout');
          ws.close();
          setError('Connection timeout - please try again');
          setLoading(false);
        }
      }, 10000);

      ws.addEventListener('open', () => {
        clearTimeout(connectionTimeout);
      });

      const cleanup = () => {
        clearTimeout(connectionTimeout);
        if (ws.readyState === WebSocket.OPEN) {
          ws.close(1000, 'Deliberate disconnection');
        }
      };

      window.addEventListener('beforeunload', cleanup);

      return ws;

    } catch (err) {
      console.error('WebSocket initialization error:', {
        error: err,
        message: err.message,
        stack: err.stack,
        groupId: selectedGroupId,
        userId: effectiveUserId
      });
      setError('Failed to connect to chat service');
      setLoading(false);
      throw err;
    }
  }, [selectedGroupId, effectiveUserId, handleWebSocketMessage]);


  useEffect(() => {
    const initWs = async () => {
      if (!wsConnection) {
        try {
          const ws = await initializeWebSocket();
          if (mountedRef.current) {
            setWsConnection(ws);
            setLoading(false);  // Reset loading after successful connection
          }
        } catch (err) {
          console.error('Failed to initialize WebSocket:', err);
          if (mountedRef.current) {
            setError('Failed to connect to chat service');
            setLoading(false);  // Reset loading on error
          }
        }
      }
    };

    initWs();

    return () => {
      mountedRef.current = false;
      if (wsConnection) {
        wsConnection.close();
        setWsConnection(null);  // Clear the connection state
        setLoading(false);      // Reset loading on cleanup
      }
    };
  }, [wsConnection, initializeWebSocket]);


  useEffect(() => {
    if (chatRef.current && shouldScrollRef.current) {
      requestAnimationFrame(() => {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      });
    }
  }, [conversation, currentResponse]);


  const handleTextSubmission = async () => {
    if (!userInput.trim() || loading || !wsConnection) return;

    try {
      setLoading(true);
      setError(null);
      shouldScrollRef.current = true;

      const currentInput = userInput.trim();
      
      // Add user message immediately to conversation
      setConversation(prev => [...prev, {
        id: `user-${Date.now()}`,
        type: 'user',
        content: currentInput,
        timestamp: new Date().toISOString()
      }]);
      
      setUserInput('');

      if (wsConnection?.readyState === WebSocket.OPEN) {
        // Add streaming placeholder
        setIsStreaming(true);
        wsConnection.send(JSON.stringify({
          type: "input_text",
          content: currentInput
        }));
      } else {
        setError('Connection lost. Please try again.');
        setLoading(false);
        const newWs = await initializeWebSocket();
        setWsConnection(newWs);
      }
    } catch (err) {
      console.error('Error sending message:', err);
      setError('Unable to send message');
      setLoading(false);
    }
  };



  const formatMessage = (content) => {
    if (!content) return null;
    
    return content.split('\n').map((line, i) => {
      if (line.trim().startsWith('•')) {
        return <div key={i} className="bullet-point">{line}</div>;
      } else if (line.trim().startsWith('>')) {
        return <blockquote key={i} className="scripture-quote">{line.replace('>', '')}</blockquote>;
      } else if (line.trim().startsWith('#')) {
        const headingText = line.replace(/^#+\s/, '');
        return <h4 key={i} className="message-heading">{headingText}</h4>;
      }
      return line.trim() && <p key={i}>{line}</p>;
    });
  };



  const renderMessage = (message) => {
    if (message.type === 'user') {
      return (
        <div className="user-message">
          <p>{message.content}</p>
        </div>
      );
    }

    return (
      <div className="assistant-message">
        <div className="message-content">
          {formatMessage(message.content)}
        </div>
      </div>
    );
  };

  return (
    <div className="page-container daily-prayer-page">
      <Header />
      <Navbar />

      {showCompletionFeedback && (
        <div className="completion-feedback">
          Prayer completed successfully! Redirecting to your Faith Journey...
        </div>
      )}

      <div className="main-content">
        <div className="direction-content">
          <div className="direction-scrollable">
            <div className="direction-chat">
              <div className="chat-title">
                <h2>Chat with your Spiritual Director</h2>
                <p>Talk about Scripture, Personal Activities, Work Activities or ask any question</p>
              </div>

              <div className="direction-messages" ref={chatRef}>
                {conversation.map((msg) => (
                  <div key={msg.id} className={`message ${msg.type}`}>
                    {renderMessage(msg)}
                  </div>
                ))}

                {currentResponse && (
                  <div className="message response">
                    <div className="assistant-message">
                      <div className="message-content">
                        {formatMessage(currentResponse)}
                      </div>
                    </div>
                  </div>
                )}

                {isStreaming && !currentResponse && (
                  <div className="message response">
                    <div className="assistant-message">
                      <div className="typing-indicator">
                        <div className="dots">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Input Area */}
              <div className="conversation-input">
                <div className="input-container">
                  <textarea
                    ref={textareaRef}
                    value={userInput}
                    onChange={(e) => {
                      setUserInput(e.target.value);
                      e.target.style.height = 'auto';
                      e.target.style.height = `${Math.min(e.target.scrollHeight, 120)}px`;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey && !loading) {
                        e.preventDefault();
                        handleTextSubmission();
                      }
                    }}
                    placeholder="Share what's on your heart..."
                    className="input-textarea"
                    disabled={loading}
                  />
                  <button
                    onClick={handleTextSubmission}
                    disabled={loading || !userInput.trim()}
                    className="send-button"
                  >
                    {loading ? <FiLoader className="spinner" /> : <FiSend />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyPrayer;