import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Database, 
    Code,
    Server, 
    Network,
    Send,
    HelpCircle,
    ChevronDown,
} from 'lucide-react';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import { API_ENDPOINTS } from '../../config/api';
import './DatabentoChat.css';

const TOPIC_AREAS = {
    SCHEMAS: {
        icon: <Database className="w-4 h-4" />,
        label: 'Schemas & Data',
        description: 'Understanding market data structures',
        suggestedPrompts: [
            "How does MBO data normalization work?",
            "Explain MBP vs TBBO data formats",
            "What timestamps are available in the data?"
        ],
        subTopics: ['Market by Order (MBO)', 'Market by Price (MBP)', 'Trades', 'OHLCV']
    },
    STANDARDS: {
        icon: <Code className="w-4 h-4" />,
        label: 'Standards & Conventions',
        description: 'Data normalization and encoding',
        suggestedPrompts: [
            "How are symbols normalized?",
            "Explain the binary encoding format",
            "How are timestamps standardized?"
        ],
        subTopics: ['Normalization', 'Symbology', 'Binary Encoding', 'Reference Data']
    },
    ARCHITECTURE: {
        icon: <Server className="w-4 h-4" />,
        label: 'System Architecture',
        description: 'Performance and connectivity',
        suggestedPrompts: [
            "How does timestamping work?",
            "What's the network latency?",
            "Explain the data delivery process"
        ],
        subTopics: ['Timestamping', 'Network Connectivity', 'Performance', 'Data Flow']
    },
    VENUES: {
        icon: <Network className="w-4 h-4" />,
        label: 'Venues & Datasets',
        description: 'Available market data sources',
        suggestedPrompts: [
            "What CME data is available?",
            "How is NASDAQ data normalized?",
            "Explain ICE data structure"
        ],
        subTopics: ['CME', 'ICE', 'NASDAQ', 'NYSE']
    }
};

const DabentoChat = () => {
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState('SCHEMAS');
    const [selectedSubTopic, setSelectedSubTopic] = useState('Market by Order (MBO)');
    const [showWelcome, setShowWelcome] = useState(true);
    const [showTooltip, setShowTooltip] = useState({ id: null, show: false });
    const [currentResponse, setCurrentResponse] = useState('');

    const navigate = useNavigate();
    const chatRef = useRef(null);
    const eventSourceRef = useRef(null);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages, currentResponse]);

    useEffect(() => {
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
        };
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!inputText.trim()) return;

        try {
            setIsLoading(true);
            setMessages(prev => [...prev, { type: 'user', content: inputText }]);

            const userMessage = inputText;
            setInputText('');
            setCurrentResponse('');

            const response = await fetch(API_ENDPOINTS.BENTO_DOCS, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    topic: selectedTopic,
                    subtopic: selectedSubTopic,
                    user_input: userMessage
                })
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedResponse = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                console.log('Raw chunk:', chunk);
                
                const lines = chunk.split('\n').filter(line => line.trim());

                for (const line of lines) {
                    if (!line.startsWith('data: ')) continue;

                    try {
                        const data = JSON.parse(line.slice(6));
                        console.log('Parsed data:', data);
                        console.log('Data type:', data.type);
                        console.log('Content structure:', data.content);

                        if (data.error) {
                            console.error('Error in response:', data.error);
                            setMessages(prev => [...prev, { type: 'error', content: data.error }]);
                            setIsLoading(false);
                            return;
                        }

                        if (data.type === 'content_update') {
                            console.log('Content update received:', data.content);
                            accumulatedResponse += data.content;
                            setCurrentResponse(accumulatedResponse);
                        } else if (data.type === 'complete') {
                            console.log('Final accumulated response:', accumulatedResponse);
                            setMessages(prev => [...prev, { 
                                type: 'assistant', 
                                content: accumulatedResponse 
                            }]);
                            setCurrentResponse('');
                            setIsLoading(false);
                        }
                    } catch (e) {
                        console.error('Error processing stream data:', e);
                        console.error('Problematic line:', line);
                        setMessages(prev => [...prev, {
                            type: 'error',
                            content: 'Failed to process response data'
                        }]);
                        setIsLoading(false);
                    }
                }
            }

        } catch (error) {
            console.error('Fetch error:', error);
            setMessages(prev => [...prev, {
                type: 'error',
                content: 'An error occurred. Please try again.'
            }]);
            setIsLoading(false);
        }
    };


    const handleTopicSelect = (topic) => {
        setSelectedTopic(topic.toUpperCase()); // Ensure uppercase for database match
        const defaultSubTopic = TOPIC_AREAS[topic]?.subTopics?.[0] || 'General';
        setSelectedSubTopic(defaultSubTopic);
        
        if (TOPIC_AREAS[topic]?.suggestedPrompts?.length > 0) {
            const randomPrompt = TOPIC_AREAS[topic].suggestedPrompts[0];
            setInputText(randomPrompt);
        }
    };

    const handleSubTopicSelect = (subtopic) => {
        setSelectedSubTopic(subtopic);
    };


    const renderMessage = (message) => {
        switch (message.type) {
            case 'welcome':
                return (
                    <div className="welcome-message">
                        <h3>Welcome to Databento Documentation Assistant</h3>
                        <p>Ask questions about Databento's market data API, schemas, and implementation.
                           Select a topic to get started.</p>
                    </div>
                );
            case 'user':
                return (
                    <div className="message-bubble user">
                        {message.content}
                    </div>
                );
            case 'assistant':
                return (
                    <div className="structured-guidance">
                        {renderGuidanceContent(message.content)}
                    </div>
                );
            case 'error':
                return (
                    <div className="error-message">
                        {message.content}
                    </div>
                );
            default:
                return null;
        }
    };



    const renderGuidanceContent = (content) => {
        if (!content) return null;
        
        const sections = content.split(/\n\n+/).filter(section => section.trim());
        const formatted = [];
        let currentList = [];
        let inCodeBlock = false;
        
        sections.forEach((section, index) => {
            const lines = section.trim().split('\n');
            
            lines.forEach((line, lineIndex) => {
                const trimmedLine = line.trim();
                
                if (trimmedLine.startsWith('# ')) {
                    if (currentList.length > 0) {
                        formatted.push(<ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>);
                        currentList = [];
                    }
                    formatted.push(
                        <div className="guidance-section" key={`section-${index}-${lineIndex}`}>
                            <h2>{trimmedLine.replace('# ', '')}</h2>
                        </div>
                    );
                } else if (trimmedLine.startsWith('* ')) {
                    currentList.push(
                        <li key={`item-${index}-${lineIndex}`}>
                            {trimmedLine.replace('* ', '')}
                        </li>
                    );
                } else if (trimmedLine.startsWith('> ')) {
                    if (currentList.length > 0) {
                        formatted.push(<ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>);
                        currentList = [];
                    }
                    formatted.push(
                        <div className="highlight-block" key={`highlight-${index}-${lineIndex}`}>
                            {trimmedLine.replace('> ', '')}
                        </div>
                    );
                } else if (trimmedLine.startsWith('```')) {
                    if (currentList.length > 0) {
                        formatted.push(<ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>);
                        currentList = [];
                    }
                    inCodeBlock = !inCodeBlock;
                    if (inCodeBlock) {
                        formatted.push(
                            <pre key={`code-${index}-${lineIndex}`} className="code-block">
                                <code></code>
                            </pre>
                        );
                    }
                } else if (inCodeBlock) {
                    const codeBlock = formatted[formatted.length - 1];
                    const code = codeBlock.props.children.props.children + line + '\n';
                    formatted[formatted.length - 1] = (
                        <pre key={`code-${index}-${lineIndex}`} className="code-block">
                            <code>{code}</code>
                        </pre>
                    );
                } else if (trimmedLine) {
                    if (currentList.length > 0) {
                        formatted.push(<ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>);
                        currentList = [];
                    }
                    formatted.push(
                        <p className="content-text" key={`text-${index}-${lineIndex}`}>
                            {trimmedLine}
                        </p>
                    );
                }
            });
        });

        if (currentList.length > 0) {
            formatted.push(<ul key="final-list">{currentList}</ul>);
        }

        return <div className="guidance-content">{formatted}</div>;
    };



    return (
        <div className="page-container">
            <Header />
            <Navbar />
            
            <div className="main-content">
                <div className="direction-content">
                    <div className="direction-scrollable">
                        <div className="direction-chat">
                            <div className="direction-header">
                                <div className="spiritual-areas">
                                    {Object.entries(TOPIC_AREAS).map(([key, area]) => (
                                        <div 
                                            key={key} 
                                            className="area-button-container"
                                            onMouseEnter={() => setShowTooltip({ id: key, show: true })}
                                            onMouseLeave={() => setShowTooltip({ id: null, show: false })}
                                        >
                                            <button
                                                className={`area-button ${selectedTopic === key ? 'selected' : ''}`}
                                                onClick={() => handleTopicSelect(key)}
                                                aria-label={area.label}
                                            >
                                                {area.icon}
                                                <span>{area.label}</span>
                                            </button>
                                            
                                            {showTooltip.id === key && showTooltip.show && (
                                                <div className="area-tooltip">
                                                    {area.description}
                                                </div>
                                            )}

                                            {selectedTopic === key && (
                                                <div className="subtopics">
                                                    {area.subTopics.map((subtopic) => (
                                                        <button
                                                            key={subtopic}
                                                            className={`subtopic-button ${selectedSubTopic === subtopic ? 'selected' : ''}`}
                                                            onClick={() => handleSubTopicSelect(subtopic)}
                                                        >
                                                            {subtopic}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="direction-messages" ref={chatRef}>
                                {showWelcome && renderMessage({ type: 'welcome' })}
                                
                                {messages.map((message, index) => (
                                    <div key={index} className={`message ${message.type}`}>
                                        {renderMessage(message)}
                                    </div>
                                ))}

                                {isLoading && currentResponse && (
                                    <div className="message assistant streaming">
                                        <div className="structured-guidance">
                                            {renderGuidanceContent(currentResponse)}
                                            <div className="typing-indicator">
                                                <span>Assistant is typing</span>
                                                <div className="dots">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <form onSubmit={handleSubmit} className="direction-input">
                                <div className="input-wrapper">
                                    <textarea
                                        value={inputText}
                                        onChange={(e) => setInputText(e.target.value)}
                                        placeholder="Ask about Databento's market data API, schemas, or implementation..."
                                        disabled={isLoading}
                                        rows="3"
                                    />
                                    <button
                                        type="submit"
                                        disabled={!inputText.trim()}
                                        className={`send-button ${!inputText.trim() ? 'disabled' : ''}`}
                                    >
                                        <Send className="w-4 h-4" />
                                        <span>Send</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DabentoChat;