import React from 'react';
import { Link } from 'react-router-dom';
import {
  FiHome,
  FiMessageCircle,
  FiTarget,
  FiUsers,
  FiCalendar,
  FiBook,
  FiHeart,
  FiSun,
  FiMoon,
  FiStar,
  FiArrowRight,
  FiCheckCircle,
  FiInfo,
  FiAward
} from 'react-icons/fi';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import './UserGuide.css';

const UserGuide = () => {
  const sections = [
    {
      id: 'faith-journey',
      title: 'Faith Journey Dashboard',
      icon: <FiHome />,
      path: '/faith-journey',
      description: 'Your central hub for spiritual growth and guidance',
      features: [
        {
          title: 'Growth Goals',
          description: 'View your spiritual development objectives focused on regular direction, daily prayer, challenges, and community participation.'
        },
        {
          title: 'Daily Prayer Schedule',
          description: 'Access structured prayer times throughout the day (Morning, Midday, Evening) with guided reflections and scripture readings.'
        },
        {
          title: 'Active Challenges',
          description: 'Track your ongoing spiritual challenges and growth activities with weekly or monthly check-ins.'
        },
        {
          title: 'Community Groups',
          description: 'View and access your faith communities and discover new groups aligned with your spiritual journey.'
        }
      ]
    },
    {
      id: 'spiritual-direction',
      title: 'Spiritual Direction Chat',
      icon: <FiMessageCircle />,
      path: '/direction-chat',
      description: 'Personal guidance through AI-assisted Catholic spiritual direction',
      features: [
        {
          title: 'One-on-One Guidance',
          description: 'Engage in meaningful conversations about your faith journey, challenges, and spiritual growth.'
        },
        {
          title: 'Scripture-Based Wisdom',
          description: 'Receive guidance grounded in Catholic teaching and Biblical wisdom.'
        },
        {
          title: 'Personalized Support',
          description: 'Get tailored advice and reflection prompts based on your unique spiritual journey.'
        },
        {
          title: 'Prayer Suggestions',
          description: 'Receive recommended prayers and devotional practices aligned with your current spiritual needs.'
        }
      ]
    },
    {
      id: 'daily-prayer',
      title: 'Daily Prayer Times',
      icon: <FiCalendar />,
      path: '/daily-prayer',
      description: 'Structured prayer and reflection throughout your day',
      features: [
        {
          title: 'Morning Prayer (5 AM - 11 AM)',
          icon: <FiSun />,
          description: 'Start your day with guided meditation, scripture reading, and intention setting.'
        },
        {
          title: 'Midday Prayer (11 AM - 3 PM)',
          icon: <FiStar />,
          description: 'Take a spiritual pause with brief reflection and gratitude practice.'
        },
        {
          title: 'Evening Prayer (3 PM - 5 AM)',
          icon: <FiMoon />,
          description: 'End your day with examination of conscience and peaceful reflection.'
        }
      ]
    },
    {
      id: 'challenges',
      title: 'Faith Challenges',
      icon: <FiTarget />,
      path: '/challenges',
      description: 'Structured activities for spiritual growth and development',
      features: [
        {
          title: 'Weekly Check-ins',
          description: 'Regular reflection prompts to track your spiritual progress and insights.'
        },
        {
          title: 'Custom Challenges',
          description: 'Create personalized spiritual goals based on your journey and needs.'
        },
        {
          title: 'Progress Tracking',
          description: 'Monitor your growth through completion rates and reflection history.'
        },
        {
          title: 'Community Challenges',
          description: 'Join group challenges to grow alongside others in your faith community.'
        }
      ]
    },
    {
      id: 'community',
      title: 'Faith Communities',
      icon: <FiUsers />,
      path: '/group-directory',
      description: 'Connect and grow with others on their faith journey',
      features: [
        {
          title: 'Discussion Groups',
          description: 'Join topic-based groups for shared learning and support.'
        },
        {
          title: 'Prayer Circles',
          description: 'Participate in group prayer and intercession.'
        },
        {
          title: 'Study Groups',
          description: 'Engage in scripture study and theological discussions.'
        },
        {
          title: 'Support Network',
          description: 'Share experiences and encourage others in their faith journey.'
        }
      ]
    },
    {
      id: 'scripture-chat',
      title: 'Scripture Discussion',
      icon: <FiBook />,
      path: '/chat',
      description: 'Deepen your understanding of Scripture through guided discussion',
      features: [
        {
          title: 'Biblical Exploration',
          description: 'Ask questions and receive insights about specific scripture passages.'
        },
        {
          title: 'Catholic Context',
          description: 'Understanding scripture within Catholic teaching and tradition.'
        },
        {
          title: 'Practical Application',
          description: 'Learn how to apply biblical wisdom to daily life.'
        },
        {
          title: 'Theological Discussion',
          description: 'Explore deeper theological concepts and their scriptural basis.'
        }
      ]
    }
  ];

  const renderFeatureCard = (feature, index) => (
    <div key={index} className="feature-card">
      {feature.icon && <div className="feature-icon">{feature.icon}</div>}
      <h4>{feature.title}</h4>
      <p>{feature.description}</p>
    </div>
  );

  const renderSection = (section) => (
    <div key={section.id} className="guide-section" id={section.id}>
      <div className="section-header">
        <div className="section-icon">{section.icon}</div>
        <div className="section-title">
          <h2>{section.title}</h2>
          <p>{section.description}</p>
        </div>
      </div>
      <div className="features-grid">
        {section.features.map((feature, index) => renderFeatureCard(feature, index))}
      </div>
      <Link to={section.path} className="section-link">
        Go to {section.title} <FiArrowRight />
      </Link>
    </div>
  );


  return (
    <div className="page-container">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="content-wrapper">
          <div className="scrollable-content">
            <div className="guide-welcome">
              <h1>Welcome to Your Faith Journey Guide</h1>
              <p>
                Discover how each part of our platform can support your spiritual growth
                and help you develop a deeper relationship with God.
              </p>
            </div>
            
            <div className="quick-nav">
              <h3>Quick Navigation</h3>
              <div className="nav-buttons">
                {sections.map(section => (
                  <a 
                    key={section.id} 
                    href={`#${section.id}`}
                    className="nav-button"
                  >
                    <span className="nav-icon">{section.icon}</span>
                    <span className="nav-text">{section.title}</span>
                  </a>
                ))}
              </div>
            </div>

            <div className="guide-sections">
              {sections.map(section => renderSection(section))}
            </div>

            <div className="guide-footer">
              <div className="help-box">
                <FiInfo className="help-icon" />
                <div className="help-content">
                  <h3>Need Additional Help?</h3>
                  <p>
                    If you have questions about using any feature or need spiritual guidance,
                    you can always start a conversation in the Spiritual Direction Chat.
                  </p>
                  <Link to="/direction-chat" className="help-link">
                    Begin Spiritual Direction <FiArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGuide;