import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { FiArrowLeft } from 'react-icons/fi';
import { Book } from 'lucide-react';
import Swal from "sweetalert2";
import "./Profile.css";
import { API_ENDPOINTS } from '../../config/api';

const Profile = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const { userId, isAuthorized } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
      return;
    }
    
    document.body.classList.add('hide-navbar');
    
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINTS.GET_SUBSCRIPTION_STATUS}/${userId}`);
        setSubscriptionStatus(response.data);
      } catch (error) {
        console.error("Error fetching subscription:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to load subscription information. Please try again later."
        });
      }
    };

    if (userId) {
      fetchSubscriptionStatus();
    }

    return () => {
      document.body.classList.remove('hide-navbar');
    };
  }, [userId, isAuthorized, navigate]);

  const handleCancelSubscription = async () => {
    try {
      const result = await Swal.fire({
        title: 'Cancel Subscription?',
        text: "Your subscription will remain active until the end of your billing period.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, cancel subscription',
        cancelButtonText: 'Keep subscription'
      });

      if (result.isConfirmed) {
        const response = await axios.post(`${API_ENDPOINTS.CANCEL_SUBSCRIPTION}/${userId}`);
        
        if (response.status === 200) {
          const statusResponse = await axios.get(`${API_ENDPOINTS.GET_SUBSCRIPTION_STATUS}/${userId}`);
          setSubscriptionStatus(statusResponse.data);
          
          Swal.fire(
            'Cancelled!',
            `Your subscription has been cancelled and will end on ${new Date(response.data.effectiveDate).toLocaleDateString()}.`,
            'success'
          );
        }
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to cancel subscription. Please try again later."
      });
    }
  };

  if (!isAuthorized) return null;

  return (
    <div className="profile-page">
      <nav className="profile-nav">
        <div className="nav-content">
          <Link to="/" className="nav-logo">
            <img src="/logo.png" alt="CrossValidation.ai" />
            <span>CrossValidation.ai</span>
          </Link>
          <div className="nav-links">
            <Link to="/faith-journey" className="nav-link">
              <FiArrowLeft /> Back to Faith Journey
            </Link>
            <Link to="/user-guide" className="nav-link guide-link">
              <Book size={16} /> Platform Guide
            </Link>
          </div>
        </div>
      </nav>

      <div className="profile-container">
        <div className="profile-content">
          <div className="profile-header">
            <h1>Your Subscription</h1>
            <p className="guide-text">
              Learn more about our platform features and capabilities in our
              <Link to="/user-guide" className="guide-link-text"> comprehensive user guide</Link>.
            </p>
          </div>

          <div className="subscription-wrapper">
            {subscriptionStatus?.hasSubscription ? (
              <>
                <div className="subscription-details">
                  <div className="status-section">
                    <h2>Current Plan Status</h2>
                    <div className="status-badge-container">
                      <span className={`status-badge ${
                        subscriptionStatus.status === 'active' && !subscriptionStatus.canceling
                          ? 'status-active'
                          : subscriptionStatus.canceling
                          ? 'status-canceling'
                          : 'status-other'
                      }`}>
                        {subscriptionStatus.canceling ? 'Canceling' : subscriptionStatus.status}
                      </span>
                    </div>
                  </div>

                  <div className="plan-info">
                    <p><strong>Plan:</strong> {subscriptionStatus.plan}</p>
                    <p>
                      <strong>
                        {subscriptionStatus.canceling ? 'Access Until:' : 'Renews On:'}
                      </strong>
                      {' '}
                      {new Date(subscriptionStatus.current_period_end * 1000).toLocaleDateString()}
                    </p>
                    {subscriptionStatus.amount && (
                      <p>
                        <strong>Amount:</strong>
                        ${subscriptionStatus.amount}/{subscriptionStatus.interval || 'month'}
                      </p>
                    )}
                  </div>

                  {subscriptionStatus.status === 'active' && !subscriptionStatus.canceling && (
                    <button 
                      onClick={handleCancelSubscription}
                      className="cancel-subscription-btn"
                    >
                      Cancel Subscription
                    </button>
                  )}
                </div>
              </>
            ) : (
              <div className="no-subscription">
                <h2>No Active Subscription</h2>
                <p>Upgrade to access premium features and enhanced spiritual guidance.</p>
                <button 
                  onClick={() => navigate('/upgrade-to-premium')}
                  className="upgrade-btn"
                >
                  Upgrade to Premium
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;