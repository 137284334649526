//current code
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment"; // Add this line
import { useDispatch } from 'react-redux';
import { USER_DATA_SUCCESS } from '../../store/constants/userConstants';  // Adjust path if needed
import Swal from 'sweetalert2';  // Add this import


import {
  FiSun,
  FiMoon,
  FiStar,
  FiUsers,
  FiTarget,
  FiArrowRight,
  FiCalendar,
  FiHeart,
  FiChevronRight,
  FiCheckCircle,
  FiInfo,
  FiPlus,
  FiX,
  FiMaximize2,
  FiMinimize2,
  FiCheck,
  FiLoader,
  FiClock,
  FiBook,
  FiPhone,  // Add this
  FiMessageCircle,
} from "react-icons/fi";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import AssessmentModal from "../../components/AssessmentModal/AssessmentModal";
import NotificationManager from "../../components/NotificationManager/NotificationManager";
import SimplifiedAssessment from "../../components/SimplifiedAssessment/SimplifiedAssessment";
import ChallengesDashboard from "../../components/ChallengesDashboard/ChallengesDashboard"; // Add this
import AssessmentBanner from '../../components/AssessmentBanner/AssessmentBanner';
import CallScheduleManager from '../../components/CallSchedulerManager/CallSchedulerManager';

import { API_ENDPOINTS } from "../../config/api";
import "./FaithJourney.css";

const FaithJourney = () => {
  // Existing state
  const { userId, tempUserId, isAuthorized, phone } = useSelector(
    (state) => state.user
  );


  const [activeGroups, setActiveGroups] = useState([]);
  const [recommendedTopics, setRecommendedTopics] = useState([]);
  const [dailyStatus, setDailyStatus] = useState({
    morning: { completed: false, available: true },
    midday: { completed: false, available: false },
    evening: { completed: false, available: false },
  });
  const [upcomingCheckins, setUpcomingCheckins] = useState([]);
  const [userStats, setUserStats] = useState(null);
  const [showAssessment, setShowAssessment] = useState(false);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  // New state for challenges and loading
  const [challenges, setChallenges] = useState([]);
  const [personalGoals, setPersonalGoals] = useState([]);
  const [userLevels, setUserLevels] = useState({});
  const [showChallengeCreation, setShowChallengeCreation] = useState(false);
  const [expandedChallenge, setExpandedChallenge] = useState(null);
  const [loadingChatOverride, setLoadingChatOverride] = useState(false);
  const [challengeReflections, setChallengeReflections] = useState({});
  const [loadingReflection, setLoadingReflection] = useState({});
  const [shareAsComment, setShareAsComment] = useState({});
  const [fullScreenChallenge, setFullScreenChallenge] = useState(null);
  const [loadingStates, setLoadingStates] = useState({
    userData: false,
    challenges: false,
    dailyStatus: false,
    groups: false,
  });

  const navigate = useNavigate();
  const effectiveUserId = userId || tempUserId;
  const isUnregisteredUser = effectiveUserId > 4000;
  const dispatch = useDispatch();  // Add this line


  // Effects for data loading
  useEffect(() => {
    if (effectiveUserId) {
      loadUserProgressData();
      loadDailyStatus();
      loadGroupData();
      if (!isUnregisteredUser) {
        loadChallengesData();
      }
    }
  }, [effectiveUserId, isUnregisteredUser]);

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
  };

  useEffect(() => {
    if (!phone && !isUnregisteredUser) {
      const storedPhone = localStorage.getItem('userPhone');
      if (storedPhone) {
        dispatch({ 
          type: USER_DATA_SUCCESS, 
          payload: { 
            phone: storedPhone 
          } 
        });
      }
    }
  }, [phone, isUnregisteredUser, dispatch]);


  useEffect(() => {
    const loadChatOverride = async () => {
      if (!effectiveUserId) return;
      
      setLoadingChatOverride(true);
      try {
        const response = await axios.get(`${API_ENDPOINTS.GET_CHAT_OVERRIDE}/${effectiveUserId}`);
        if (response.data.success && response.data.chat_override) {
          setSelectedGroupId(response.data.chat_override.toString());
        }
      } catch (error) {
        console.error('Error loading chat override:', error);
      } finally {
        setLoadingChatOverride(false);
      }
    };

    loadChatOverride();
  }, [effectiveUserId]);





  // 3. Update the interval effect to preserve completion status
  useEffect(() => {
    const interval = setInterval(() => {
      const currentHour = new Date().getHours();
      const availability = checkPrayerAvailability();

      setDailyStatus((prevStatus) => ({
        morning: {
          ...prevStatus.morning,
          available: availability.morning && !prevStatus.morning.completed,
        },
        midday: {
          ...prevStatus.midday,
          available: availability.midday && !prevStatus.midday.completed,
        },
        evening: {
          ...prevStatus.evening,
          available: availability.evening && !prevStatus.evening.completed,
        },
      }));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const loadUserProgressData = async () => {
    if (!effectiveUserId || isUnregisteredUser) return;

    try {
      setLoadingStates((prev) => ({ ...prev, userData: true }));
      // Remove the assessment status check
      const statsResponse = await axios.get(
        `${API_ENDPOINTS.USER_PROGRESS}/${effectiveUserId}`
      );
      setUserStats(statsResponse.data);
    } catch (error) {
      console.error("Error loading user progress data:", error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, userData: false }));
    }
  };



  const loadDailyStatus = async () => {
    if (!effectiveUserId) return;

    try {
      setLoadingStates((prev) => ({ ...prev, dailyStatus: true }));
      const currentHour = new Date().getHours();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get(
        `${API_ENDPOINTS.DAILY_PRAYER_STATUS}/${effectiveUserId}?hour=${currentHour}&timezone=${timezone}`
      );
      setDailyStatus(response.data);
    } catch (error) {
      console.error("Error loading daily status:", error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, dailyStatus: false }));
    }
  };


  const loadGroupData = async () => {
    if (!effectiveUserId) return;

    try {
      setLoadingStates((prev) => ({ ...prev, groups: true }));
      const groupsResponse = await axios.get(
        `${API_ENDPOINTS.GET_GROUPS}?user_id=${effectiveUserId}`
      );
      // Access the groups array from the response
      const groups = groupsResponse.data.groups;
      setActiveGroups(groups.filter((g) => g.is_member));
      setRecommendedTopics(
        groups.filter((g) => !g.is_member).slice(0, 2)
      );
    } catch (error) {
      console.error("Error loading group data:", error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, groups: false }));
    }
  };


  const loadChallengesData = async () => {
    if (!effectiveUserId || isUnregisteredUser) {
      console.log("Early return - Invalid user:", {
        effectiveUserId,
        isUnregisteredUser,
      });
      return;
    }

    try {
      console.log("=== Loading All Challenges Data ===");
      console.log("User ID:", effectiveUserId);
      setLoadingStates((prev) => ({ ...prev, challenges: true }));

      // Regular challenges and levels
      console.log("Fetching regular challenges and user levels...");
      const [challengesResponse, levelsResponse] = await Promise.all([
        axios.get(`${API_ENDPOINTS.CHALLENGES}/${effectiveUserId}`),
        axios.get(`${API_ENDPOINTS.USER_LEVELS}/${effectiveUserId}`),
      ]);

      // Transform regular challenges
      const transformedRegularChallenges = Array.isArray(challengesResponse.data) ? 
        challengesResponse.data.map(challenge => ({
          ...challenge,
          prompts: challenge.period_prompts || challenge.weekly_prompts || [],
          period_progress: challenge.period_progress || Array(4).fill("not_started"),
          period_reflections: typeof challenge.period_reflections === "string"
            ? JSON.parse(challenge.period_reflections)
            : challenge.period_reflections || {}
        })) : [];

      console.log("Regular challenges processed:", {
        challenges: transformedRegularChallenges,
        reflectionsData: transformedRegularChallenges.map((c) => ({
          id: c.id,
          reflections: c.period_reflections,
          weeklyReflections: c.weekly_reflections,
          progress: c.period_progress,
        })),
      });

      console.log("User levels response:", levelsResponse.data);

      try {
        // Group data
        console.log("Fetching group data...");
        const groupResponse = await axios.get(
          `${API_ENDPOINTS.GET_GROUPS}?user_id=${effectiveUserId}`
        );
        
        // Ensure we're working with an array for groups
        const groupsData = Array.isArray(groupResponse.data) ? 
          groupResponse.data : 
          (groupResponse.data?.groups || []);
        
        const userGroups = groupsData.filter(g => g.is_member);
        
        console.log("User groups data:", {
          allGroups: groupsData,
          memberGroups: userGroups,
        });

        // Group challenges
        console.log("Fetching challenges for each group...");
        const groupChallenges = await Promise.all(
          userGroups.map(async (group) => {
            try {
              const endpoint = `${API_ENDPOINTS.GROUP_CHALLENGES_LIST.replace(
                ":groupId",
                group.id
              )}?userId=${effectiveUserId}`;
              
              const response = await axios.get(endpoint);
              return Array.isArray(response.data) ? response.data : [];
            } catch (error) {
              console.error(`Error fetching group ${group.id} challenges:`, error);
              return [];
            }
          })
        );

        // Transform group challenges
        const transformedGroupChallenges = groupChallenges
          .flat()
          .filter((c) => c.is_participating)
          .map((challenge) => ({
            ...challenge,
            prompts: challenge.period_prompts || challenge.weekly_prompts || [],
            period_progress: challenge.period_progress || Array(4).fill("not_started"),
            period_reflections: challenge.period_reflections || Array(4).fill([]),
            isGroupChallenge: true
          }));

        // Merge all challenges
        const allChallenges = [...transformedRegularChallenges, ...transformedGroupChallenges];
        
        console.log("Final merged challenges:", {
          count: allChallenges.length,
          challenges: allChallenges,
        });

        // Update state
        setChallenges(allChallenges);
        setPersonalGoals(challengesResponse.data.personalGoals || []);
        setUserLevels(levelsResponse.data || {});
        setUpcomingCheckins(
          Array.isArray(challengesResponse.data.upcomingCheckins) ? 
            challengesResponse.data.upcomingCheckins : 
            []
        );
        
      } catch (groupError) {
        console.error("Error processing group data:", groupError);
        // Still set regular challenges even if group processing fails
        setChallenges(transformedRegularChallenges);
        setPersonalGoals(challengesResponse.data.personalGoals || []);
        setUserLevels(levelsResponse.data || {});
      }

    } catch (error) {
      console.error("Error loading challenges:", {
        error,
        message: error.message,
        response: error.response?.data,
      });
      // Reset states on error
      setChallenges([]);
      setPersonalGoals([]);
      setUpcomingCheckins([]);
    } finally {
      setLoadingStates((prev) => ({ ...prev, challenges: false }));
      console.log("=== Challenge Loading Complete ===");
    }
  };


  const handleChallengeReflection = async (
    challengeId,
    periodIndex,
    reflection,
    completed
  ) => {
    try {
      setLoadingReflection((prev) => ({
        ...prev,
        [`${challengeId}-${periodIndex}`]: true,
      }));

      const challenge = challenges.find((c) => c.id === challengeId);
      if (!challenge) return;

      const payload = {
        user_id: effectiveUserId,
        challenge_id: challengeId,
        period_index: periodIndex,
        reflection: reflection.trim(),
        completed: Boolean(completed),
        timestamp: new Date().toISOString(),
        share_to_group: Boolean(
          shareAsComment[`${challengeId}-${periodIndex}`]
        ),
      };

      const isGroupChallenge = Boolean(challenge.group_id);
      const endpoint = isGroupChallenge
        ? API_ENDPOINTS.GROUP_CHALLENGE_CHECKIN.replace(
            ":groupId",
            challenge.group_id
          ).replace(":challengeId", challengeId)
        : `${API_ENDPOINTS.CHALLENGES}/${effectiveUserId}`;

      const response = await axios.post(endpoint, payload);

      if (response.data) {
        // Update challenges
        const updatedChallenges = challenges.map((c) => {
          if (c.id === challengeId) {
            return {
              ...c,
              period_progress: response.data.period_progress,
              current_period: response.data.current_period,
              next_check_in: response.data.next_check_in,
            };
          }
          return c;
        });

        setChallenges(updatedChallenges);

        // Update reflections
        setChallengeReflections((prev) => ({
          ...prev,
          [challengeId]: {
            ...(prev[challengeId] || {}),
            [periodIndex]: [
              ...(prev[challengeId]?.[periodIndex] || []),
              {
                text: reflection,
                timestamp: payload.timestamp,
                completed: payload.completed,
              },
            ],
          },
        }));

        // Share to group if selected
        if (
          isGroupChallenge &&
          shareAsComment[`${challengeId}-${periodIndex}`]
        ) {
          await axios.post(
            API_ENDPOINTS.CREATE_CHALLENGE_COMMENT.replace(
              ":groupId",
              challenge.group_id
            ).replace(":challengeId", challengeId),
            {
              content: reflection,
              period_index: periodIndex,
              completed: completed,
            }
          );
        }

        if (response.data.evaluation) {
          // Handle spiritual direction feedback
          setShowAssessment(false);
          // TODO: Show spiritual direction modal
        } else {
          alert("Reflection submitted successfully!");
        }

        // Reload challenges data to ensure everything is up to date
        loadChallengesData();
      }
    } catch (error) {
      console.error("Error submitting reflection:", error);
      alert("Failed to submit reflection. Please try again.");
    } finally {
      setLoadingReflection((prev) => ({
        ...prev,
        [`${challengeId}-${periodIndex}`]: false,
      }));
    }
  };

  const handleGroupSelect = async (e) => {
    const newGroupId = e.target.value;
    setSelectedGroupId(newGroupId);
    
    try {
      const response = await axios.post(API_ENDPOINTS.SET_CHAT_OVERRIDE, {
        user_id: effectiveUserId,
        chat_override: newGroupId ? parseInt(newGroupId) : null
      });
      
      if (!response.data.success) {
        throw new Error('Failed to save group selection');
      }
    } catch (error) {
      console.error('Error saving chat override:', error);
      // Revert selection on error
      setSelectedGroupId(prevGroupId => prevGroupId);
      // Optional: Show error message to user
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update group selection. Please try again.',
        timer: 3000
      });
    }
  };



  // 2. Update handleDailyPrayer to check both availability and completion
  const handleDailyPrayer = () => {
    const currentHour = new Date().getHours();
    let activeTimeSlot = null;

    // Determine which time slot is currently active
    if (currentHour >= 5 && currentHour < 11) {
      activeTimeSlot = "morning";
    } else if (currentHour >= 11 && currentHour < 15) {
      activeTimeSlot = "midday";
    } else if (currentHour >= 15 || currentHour < 5) {
      activeTimeSlot = "evening";
    }

    // Only navigate if there's an active time slot and it hasn't been completed
    if (
      activeTimeSlot &&
      dailyStatus[activeTimeSlot]?.available &&
      !dailyStatus[activeTimeSlot]?.completed
    ) {
      navigate("/daily-prayer");
    }
  };

  // 4. Update handleAssessmentComplete to remove assessment status setting:
  const handleAssessmentComplete = async (challengeData) => {
    try {
      console.log("FaithJourney - Starting handleAssessmentComplete");
      console.log("FaithJourney - Challenge data received:", challengeData);

      // Remove these lines:
      // setAssessmentCompleted(true);
      // setShowAssessment(false);

      // Reload data to show new challenges
      await loadChallengesData();
      await loadUserProgressData();
      console.log("FaithJourney - Assessment complete and data reloaded");
    } catch (error) {
      console.error("FaithJourney - Error in handleAssessmentComplete:", error);
      alert("Failed to submit assessment. Please try again.");
    }
  };

  const handleChallengeCreate = async (challengeData) => {
    try {
      console.log("FaithJourney - Starting handleChallengeCreate");
      console.log("FaithJourney - Challenge data received:", challengeData);

      setShowChallengeCreation(false);

      // Reload all data to ensure consistency
      await loadUserProgressData();
      await loadChallengesData();
      console.log("FaithJourney - Challenge created and data reloaded");
    } catch (error) {
      console.error("FaithJourney - Error in handleChallengeCreate:", error);
      alert("Failed to create challenge. Please try again.");
    }
  };

  const handleExpandChallenge = (challengeId) => {
    setExpandedChallenge(
      expandedChallenge === challengeId ? null : challengeId
    );
    setFullScreenChallenge(null);
  };

  const handleFullScreenToggle = (challengeId) => {
    setFullScreenChallenge(
      fullScreenChallenge === challengeId ? null : challengeId
    );
    setExpandedChallenge(challengeId);
  };

  // Helper functions
  const checkPrayerAvailability = () => {
    const hour = new Date().getHours();
    return {
      morning: hour >= 5 && hour < 11,
      midday: hour >= 11 && hour < 15,
      evening: hour >= 15 || hour < 5,
    };
  };

  const getTimeWindow = (timeOfDay) => {
    switch (timeOfDay) {
      case "morning":
        return "5 AM - 11 AM";
      case "midday":
        return "11 AM - 3 PM";
      case "evening":
        return "3 PM - 5 AM";
      default:
        return "";
    }
  };

  const handleReflectionChange = (challengeId, periodIndex, value) => {
    setChallengeReflections((prev) => ({
      ...prev,
      [challengeId]: {
        ...(prev[challengeId] || {}),
        [periodIndex]: value,
      },
    }));
  };


  const handleCallRequest = async () => {
    try {
      // First get the user's phone number
      const userResponse = await axios.get(`${API_ENDPOINTS.GET_USER_INFO}/${effectiveUserId}`);
      
      if (!userResponse.data.success || !userResponse.data.phone_number) {
        throw new Error('Could not retrieve phone number');
      }

      // Make the call using the retrieved phone number
      const response = await axios.post(API_ENDPOINTS.MAKE_CALL, {
        phone_number: userResponse.data.phone_number
      });

      // Log the response for debugging
      console.log('Call API response:', response.data);

      // Show success message regardless of response.data.success
      Swal.fire({
        icon: 'success',
        title: 'Connecting Your Call',
        text: 'Please stay on the line while we connect you with your spiritual advisor.',
        timer: 3000,
        showConfirmButton: false
      });

    } catch (error) {
      console.error('Error initiating call:', {
        error,
        message: error.message,
        response: error.response?.data
      });
      
      Swal.fire({
        icon: 'error',
        title: 'Call Failed',
        text: 'Unable to connect your call. Please try again.',
        confirmButtonText: 'OK'
      });
    }
  };




  const renderWelcomeBanner = () => (
    <div className="welcome-banner">
      <div className="banner-controls">
        {isAuthorized && (
          <>
            <div className="notification-container">
              <NotificationManager
                userId={effectiveUserId}
                onNotificationClick={handleNotificationClick}
              />
            </div>
            <div className="group-override-selector">
              <select 
                value={selectedGroupId || ''} 
                onChange={handleGroupSelect}
                className="group-select"
                disabled={loadingChatOverride}
              >
                <option value="">General Chat</option>
                {activeGroups.map(group => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
              {loadingChatOverride && <small className="loading-hint">Loading...</small>}
              <small className="group-hint">AI Response Context</small>
            </div>
          </>
        )}
      </div>

      <div className="welcome-content">
        <h1>Welcome to Spiritual Direction</h1>
        {isUnregisteredUser ? (
          <p className="welcome-message">
            Explore Catholic spirituality and prayer with our AI-guided
            platform. Register to receive personalized guidance for your unique
            faith journey.
          </p>
        ) : (
          <div className="welcome-actions">
            <div className="action-buttons">
              <button 
                className="call-action-button"
                onClick={handleCallRequest}
              >
                <FiPhone className="button-icon" />
                Have AI Advisor Call You Now
              </button>
              <button 
                className="chat-action-button"
                onClick={() => navigate('/daily-prayer')}
              >
                <FiMessageCircle className="button-icon" />
                Chat with Spiritual Guide Now
              </button>
            </div>
            <p className="direct-call-info">
              Or call us directly at: <span className="phone-number">888-800-0000</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );



  const renderPersonalizationBanner = () => (
    <div className="personalization-banner">
      <div className="banner-header">
        <FiInfo className="info-icon" />
        <h3>Enhance Your Faith Journey</h3>
      </div>
      <p>
        Create an account to receive personalized spiritual guidance based on
        your unique journey. Your personal insights will help us tailor content
        specifically for your faith development.
      </p>
      <button onClick={() => navigate("/signup")} className="register-button">
        Register for Personalized Guidance
      </button>
    </div>
  );

  const updateChallenges = (newChallenge) => {
    if (!newChallenge || !newChallenge.id) return;

    setChallenges((prevChallenges) => {
      if (!prevChallenges) return prevChallenges;
      return prevChallenges.map((challenge) =>
        challenge.id === newChallenge.id ? newChallenge : challenge
      );
    });
  };

  useEffect(() => {
    if (expandedChallenge) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => document.body.classList.remove("modal-open");
  }, [expandedChallenge]);

  const calculateProgress = (challenge) => {
    if (!challenge?.period_progress) return 0;
    const completed = challenge.period_progress.filter(
      (p) => p === "completed"
    ).length;
    const total = challenge.period_progress.length || 1;
    return Math.round((completed / total) * 100);
  };

  const renderChallengesSection = () => {
    const shouldShowEmptyState =
      challenges?.length === 0 && !isUnregisteredUser;

    const getCurrentPeriodInfo = (challenge) => {
      const today = new Date();

      if (challenge.period_dates) {
        const currentPeriod = challenge.period_dates.findIndex((period) => {
          const startDate = new Date(period.start);
          const endDate = new Date(period.end);
          return today >= startDate && today <= endDate;
        });

        if (currentPeriod === -1) {
          const upcomingPeriod = challenge.period_dates.findIndex(
            (period) => today < new Date(period.start)
          );

          if (upcomingPeriod !== -1) {
            return {
              periodNumber: upcomingPeriod,
              prompt:
                challenge.period_prompts?.[upcomingPeriod] ||
                challenge.prompts?.[upcomingPeriod],
              startDate: challenge.period_dates[upcomingPeriod].start,
              endDate: challenge.period_dates[upcomingPeriod].end,
              status:
                challenge.period_progress?.[upcomingPeriod] || "not_started",
              reflection: challenge.period_reflections?.[upcomingPeriod] || "",
              isUpcoming: true,
            };
          }
        } else {
          return {
            periodNumber: currentPeriod,
            prompt:
              challenge.period_prompts?.[currentPeriod] ||
              challenge.prompts?.[currentPeriod],
            startDate: challenge.period_dates[currentPeriod].start,
            endDate: challenge.period_dates[currentPeriod].end,
            status: challenge.period_progress?.[currentPeriod] || "not_started",
            reflection: challenge.period_reflections?.[currentPeriod] || "",
            isUpcoming: false,
          };
        }
      }

      const challengeStart = new Date(challenge.start_date);
      const periodLength = challenge.duration_type === "weekly" ? 7 : 30;
      const daysSinceStart = Math.floor(
        (today - challengeStart) / (1000 * 60 * 60 * 24)
      );
      const currentPeriod = Math.floor(daysSinceStart / periodLength);

      if (currentPeriod < 0) {
        return {
          periodNumber: 0,
          prompt: challenge.period_prompts?.[0] || challenge.prompts?.[0],
          startDate: challenge.start_date,
          endDate: new Date(
            challengeStart.getTime() + periodLength * 24 * 60 * 60 * 1000
          ),
          status: challenge.period_progress?.[0] || "not_started",
          reflection: challenge.period_reflections?.[0] || "",
          isUpcoming: true,
        };
      }

      const periodNumber = Math.min(
        currentPeriod,
        (challenge.prompts?.length || 4) - 1
      );
      return {
        periodNumber,
        prompt:
          challenge.period_prompts?.[periodNumber] ||
          challenge.prompts?.[periodNumber],
        startDate: new Date(
          challengeStart.getTime() +
            periodNumber * periodLength * 24 * 60 * 60 * 1000
        ),
        endDate: new Date(
          challengeStart.getTime() +
            (periodNumber + 1) * periodLength * 24 * 60 * 60 * 1000
        ),
        status: challenge.period_progress?.[periodNumber] || "not_started",
        reflection: challenge.period_reflections?.[periodNumber] || "",
        isUpcoming: false,
      };
    };

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    };

    return (
      <div className="challenges-section">
        <h2>
          <FiTarget className="section-icon" />
          Faith Journey Activities
        </h2>

        <div className="section-actions">
          <button
            className="new-challenge-button"
            onClick={() => setShowChallengeCreation(true)}
          >
            <FiPlus className="button-icon" />
            New Activity
          </button>
        </div>

        <p className="section-description">
          Track your spiritual growth through guided activities and reflections.
        </p>

        {shouldShowEmptyState ? (
          <div className="empty-challenges">
            <div className="empty-content">
              <FiTarget className="empty-icon" />
              <h3>Begin Your Faith Journey</h3>
              <p>
                Start by creating your first spiritual activity to track your
                growth.
              </p>
              <button
                className="start-challenge-button"
                onClick={() => setShowChallengeCreation(true)}
              >
                <FiPlus /> Create First Activity
              </button>
            </div>
          </div>
        ) : (
          <div className="challenge-cards-grid">
            {challenges?.map((challenge) => {
              if (!challenge?.id) return null;

              const progress = calculateProgress(challenge);
              const currentPeriod = getCurrentPeriodInfo(challenge);
              const isExpanded = expandedChallenge === challenge.id;

              return (
                <div className="challenge-card-container" key={challenge.id}>
                  {console.log("=== Challenge Card Container Render ===", {
                    challengeId: challenge.id,
                    hasClickHandler: true,
                  })}
                  <div
                    className={`challenge-card ${isExpanded ? "active" : ""}`}
                    onClick={() => {
                      console.log("=== Challenge Card Click ===", {
                        challengeId: challenge.id,
                        currentExpanded: expandedChallenge,
                        isExpanded,
                        reflection_data: challenge.period_reflections,
                      });
                      setExpandedChallenge(isExpanded ? null : challenge.id);
                    }}
                  >
                    <div className="challenge-preview">
                      {challenge.group_id && (
                        <div className="group-badge">
                          <FiUsers /> Group Activity
                        </div>
                      )}
                      <h3>{challenge.title || challenge.description}</h3>

                      <div className="current-period-info">
                        <div className="period-header">
                          <span className="period-label">
                            {challenge.duration_type === "weekly"
                              ? "Week"
                              : "Month"}{" "}
                            {currentPeriod.periodNumber + 1}
                            {currentPeriod.isUpcoming && " (Upcoming)"}
                          </span>
                          <span className="period-dates">
                            {formatDate(currentPeriod.startDate)} -{" "}
                            {formatDate(currentPeriod.endDate)}
                          </span>
                        </div>

                        <div className="period-content">
                          <div className="period-prompt">
                            {currentPeriod.prompt}
                          </div>
                          {currentPeriod.reflection && (
                            <div className="period-reflection">
                              <FiMessageCircle className="reflection-icon" />
                              <div className="reflection-content">
                                {Array.isArray(currentPeriod.reflection)
                                  ? currentPeriod.reflection.map((ref, idx) => (
                                      <div
                                        key={ref.timestamp || idx}
                                        className="reflection-entry"
                                      >
                                        <div className="reflection-text">
                                          {ref.text}
                                        </div>
                                        {ref.timestamp && (
                                          <small className="reflection-date">
                                            {moment(ref.timestamp).format(
                                              "MMM D, YYYY h:mm A"
                                            )}
                                          </small>
                                        )}
                                      </div>
                                    ))
                                  : typeof currentPeriod.reflection === "string"
                                  ? currentPeriod.reflection
                                  : JSON.parse(currentPeriod.reflection).map(
                                      (ref, idx) => (
                                        <div
                                          key={ref.timestamp || idx}
                                          className="reflection-entry"
                                        >
                                          <div className="reflection-text">
                                            {ref.text}
                                          </div>
                                          {ref.timestamp && (
                                            <small className="reflection-date">
                                              {moment(ref.timestamp).format(
                                                "MMM D, YYYY h:mm A"
                                              )}
                                            </small>
                                          )}
                                        </div>
                                      )
                                    )}
                              </div>
                            </div>
                          )}
                          {currentPeriod.status === "completed" && (
                            <div className="period-status completed">
                              <FiCheckCircle /> Completed
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="challenge-footer">
                        <div className="challenge-progress">
                          <div
                            className="progress-bar"
                            style={{ width: `${progress}%` }}
                          />
                          <span className="progress-text">
                            {progress}% Complete
                          </span>
                        </div>
                        <div className="challenge-type">
                          {challenge.duration_type === "weekly"
                            ? "Weekly"
                            : "Monthly"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="challenge-connector" />

                  {isExpanded && (
                    <div
                      className="challenge-modal-overlay"
                      onClick={(e) => {
                        if (e.target === e.currentTarget) {
                          e.stopPropagation();
                          setExpandedChallenge(null);
                        }
                      }}
                    >
                      <div className="challenge-modal">
                        <button
                          className="close-modal"
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpandedChallenge(null);
                          }}
                        >
                          <FiX />
                        </button>
                        <div
                          className="challenge-modal-content"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ChallengesDashboard
                            challenges={[{ ...challenge }]}
                            personalGoals={
                              personalGoals?.filter(
                                (g) => g.id === challenge.id
                              ) || []
                            }
                            userLevels={userLevels || {}}
                            onChallengeUpdate={(updatedChallenges) => {
                              console.log(
                                "=== ChallengesDashboard Update ===",
                                {
                                  challengeId: challenge.id,
                                  reflections: challenge.period_reflections,
                                  expanded: expandedChallenge,
                                }
                              );
                              if (updatedChallenges?.[0]) {
                                updateChallenges(updatedChallenges[0]);
                                loadChallengesData();
                              }
                            }}
                            onPersonalGoalUpdate={async (
                              goalId,
                              periodIndex,
                              reflection,
                              completed
                            ) => {
                              try {
                                await loadChallengesData();
                              } catch (error) {
                                console.error("Error updating goal:", error);
                              }
                            }}
                            onInitiateNextChallenges={() => {
                              setExpandedChallenge(null);
                              setShowChallengeCreation(true);
                            }}
                            userId={effectiveUserId}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            <div
              className="challenge-card add-challenge"
              onClick={() => setShowChallengeCreation(true)}
            >
              <div className="add-challenge-content">
                <FiPlus className="add-icon" />
                <span>Add New Activity</span>
              </div>
            </div>
          </div>
        )}

        {showChallengeCreation && (
          <div className="modal-overlay">
            <div className="modal-content challenge-creation-modal">
              <button
                className="close-modal"
                onClick={() => setShowChallengeCreation(false)}
              >
                <FiX />
              </button>
              <SimplifiedAssessment
                userId={effectiveUserId}
                onAssessmentComplete={(data) => {
                  handleChallengeCreate(data);
                  setExpandedChallenge(null);
                }}
              />
            </div>
          </div>
        )}

        <style jsx global>{`
          body {
            overflow: ${expandedChallenge || showChallengeCreation
              ? "hidden"
              : "auto"};
          }
        `}</style>
      </div>
    );
  };

  const renderScheduleSection = () => (
    <div className="schedule-section">
      <CallScheduleManager userId={effectiveUserId} />
    </div>
  );


  const renderCommunitySection = () => (
    <div className="community-section">
      <h2>
        <FiUsers className="section-icon" />
        Your Faith Communities
      </h2>

      <p className="section-description">
        Connect with others in faith-focused discussion groups and grow together
        in community.
      </p>

      <div className="section-actions">
        <Link to="/group-directory" className="view-all-link">
          <FiChevronRight /> Explore All Groups
        </Link>
      </div>

      {isUnregisteredUser && (
        <p className="section-description">
          Join faith-focused discussion groups. Register to get personalized
          group recommendations.
        </p>
      )}
      <div className="community-grid">
        <div className="active-groups">
          <h3>Active Groups</h3>
          <div className="groups-list">
            {loadingStates.groups ? (
              <div className="loading-placeholder groups-loading"></div>
            ) : activeGroups.length > 0 ? (
              activeGroups.map((group) => (
                <div key={group.id} className="group-card">
                  <div className="group-info">
                    <h4>{group.name}</h4>
                    <p>{group.description}</p>
                  </div>
                  <Link to={`/group/${group.id}`} className="view-group-button">
                    View Group <FiArrowRight />
                  </Link>
                </div>
              ))
            ) : (
              <p className="empty-message">No active groups yet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="faith-journey-container">
      <Header />
      <Navbar />
      <div className="faith-journey-content">
        <AssessmentBanner 
          userId={effectiveUserId} 
          userPhone={phone} // This will now be defined from Redux state
        />
        <div className="faith-journey-main">
          <div className="faith-journey-scrollable">
            {renderWelcomeBanner()}
            {isUnregisteredUser && renderPersonalizationBanner()}
            {selectedNotification && (
              <div className="selected-notification">
                <div className="notification-header">
                  <h3>{selectedNotification.title}</h3>
                  <button
                    className="close-notification"
                    onClick={() => setSelectedNotification(null)}
                  >
                    ×
                  </button>
                </div>
                <div className="notification-content">
                  {selectedNotification.type === "reflection" ? (
                    <div className="reflection-content">
                      {selectedNotification.message
                        .split("\n")
                        .map((line, i) => (
                          <p key={i}>{line}</p>
                        ))}
                    </div>
                  ) : (
                    <p>{selectedNotification.message}</p>
                  )}
                  <span className="notification-timestamp">
                    {new Date(selectedNotification.timestamp).toLocaleString()}
                  </span>
                </div>
              </div>
            )}
            <div className="faith-journey-sections">
              <div className="primary-sections">
                {renderScheduleSection()}
              </div>
              <div className="secondary-sections">
                {renderChallengesSection()}
                {renderCommunitySection()}
              </div>
            </div>
            {/* Challenge Creation Modal */}
            {showChallengeCreation && (
              <div className="modal-overlay">
                <div className="modal-content challenge-creation-modal">
                  <button
                    className="close-modal"
                    onClick={() => setShowChallengeCreation(false)}
                  >
                    <FiX className="close-icon" />
                  </button>
                  <SimplifiedAssessment
                    userId={effectiveUserId}
                    onAssessmentComplete={handleChallengeCreate}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaithJourney;