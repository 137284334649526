import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
    Book, 
    Heart, 
    MessageCircle, 
    Users, 
    Cross, 
    Calendar,
    Send, 
    Lightbulb, 
    HelpCircle,
    ChevronDown, 
    Star,
    Menu, 
    Check,
    Globe, 
    Church,    // For SACRAMENTS
    Briefcase, // For PROFESSIONAL_LIFE
    Coffee,    // For LEISURE
    Target     // For DISCERNMENT (if needed)
} from 'lucide-react';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import AssessmentModal from '../../components/AssessmentModal/AssessmentModal';
import { API_ENDPOINTS } from '../../config/api';
import axios from 'axios';
import './DirectionChat.css';
import RealtimeVoiceControls from '../../components/RealtimeVoiceControls/RealtimeVoiceControls';




// Then update the SPIRITUAL_AREAS object order and structure to match backend exactly:
const SPIRITUAL_AREAS = {
    RELATIONSHIPS: {
        icon: <Users className="w-4 h-4" />,
        label: 'Relationships',
        description: 'Navigate relationships with faith and grace',
        suggestedPrompts: [
            "How can I bring God into my relationships?",
            "I need guidance with a family situation",
            "Help me understand God's role in my relationships"
        ]
    },
    DAILY_LIFE: {
        icon: <Calendar className="w-4 h-4" />,
        label: 'Daily Life',
        description: 'Find God in your daily activities',
        suggestedPrompts: [
            "How can I better integrate my faith into my daily routine?",
            "I'm struggling with work-life balance...",
            "Help me find God in my daily activities"
        ]
    },
    PRAYER: {
        icon: <Heart className="w-4 h-4" />,
        label: 'Prayer',
        description: 'Deepen your prayer life and spiritual connection',
        suggestedPrompts: [
            "I want to develop a better prayer routine",
            "How can I pray when I feel distracted?",
            "Help me connect with God through prayer"
        ]
    },
    PROFESSIONAL_LIFE: {
        icon: <Briefcase className="w-4 h-4" />,
        label: 'Professional Life',
        description: 'Integrate faith into your work life',
        suggestedPrompts: [
            "How can I live my faith at work?",
            "I'm struggling with workplace challenges...",
            "Help me find meaning in my work"
        ]
    },
    LEISURE: {
        icon: <Coffee className="w-4 h-4" />,
        label: 'Leisure',
        description: 'Find God in recreation and rest',
        suggestedPrompts: [
            "How can I make my free time more meaningful?",
            "I want to balance recreation with spirituality...",
            "Help me find God in my hobbies"
        ]
    },
    SACRAMENTS: {
        icon: <Church className="w-4 h-4" />,
        label: 'Sacraments',
        description: 'Grow through the sacramental life',
        suggestedPrompts: [
            "How can I get more out of Mass?",
            "I want to prepare better for Confession...",
            "Help me appreciate the Eucharist more deeply"
        ]
    }
};

const DirectionChat = () => {
    // Existing state
    const { userId, tempUserId, isAuthorized } = useSelector(state => state.user);
    const effectiveUserId = userId || tempUserId;
    const isUnregisteredUser = effectiveUserId > 4000;
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedArea, setSelectedArea] = useState(null);
    const [showWelcome, setShowWelcome] = useState(true);
    const [spiritualProfile, setSpiritualProfile] = useState(null);
    const [recentTopics, setRecentTopics] = useState([]);
    const [showAssessment, setShowAssessment] = useState(false);
    const [isFirstVisit, setIsFirstVisit] = useState(false);
    const [showTooltip, setShowTooltip] = useState({ id: null, show: false });
    const [topicResponses, setTopicResponses] = useState({});
    const [currentSubTopic, setCurrentSubTopic] = useState(null);

    // Add these state variables to your existing state declarations
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedGroupName, setSelectedGroupName] = useState('General');
    const shouldScrollRef = useRef(true);  // Add this new ref


    // New streaming state
    const [streamingResponse, setStreamingResponse] = useState(null);
    const [currentResponse, setCurrentResponse] = useState("");
    const [isVoiceProcessing, setIsVoiceProcessing] = useState(false);
    
    const chatRef = useRef(null);
    const eventSourceRef = useRef(null);

    // Existing assessment check effect
    useEffect(() => {
        const checkAssessmentStatus = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.ASSESSMENT_STATUS);
                const { needsAssessment, isFirst } = response.data;
                setShowAssessment(needsAssessment);
                setIsFirstVisit(isFirst);
            } catch (error) {
                console.error('Error checking assessment status:', error);
            }
        };

        checkAssessmentStatus();
    }, []);


    useEffect(() => {
        const fetchUserGroups = async () => {
            if (effectiveUserId) {
                try {
                    const url = `${API_ENDPOINTS.GET_GROUPS}?user_id=${effectiveUserId}`;
                    const response = await axios.get(url);
                    const memberGroups = response.data.groups.filter(group => group.is_member);
                    setUserGroups(memberGroups);
                    
                    // Set default group if exists
                    if (response.data.default_group_id) {
                        setSelectedGroupId(response.data.default_group_id);
                        const defaultGroup = memberGroups.find(g => g.id === response.data.default_group_id);
                        if (defaultGroup) {
                            setSelectedGroupName(defaultGroup.name);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user groups:', error);
                }
            }
        };
        fetchUserGroups();
    }, [effectiveUserId]);


    // Add click outside handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.group-selector')) return;
            if (isSettingsOpen && !event.target.closest('.group-dropdown')) {
                setIsSettingsOpen(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isSettingsOpen]);


    // Add this handler
    const handleGroupChange = (groupId) => {
        if (groupId === '') {
            setSelectedGroupId(null);
            setSelectedGroupName('General');
        } else {
            const selectedGroup = userGroups.find(g => g.id.toString() === groupId);
            if (selectedGroup) {
                setSelectedGroupId(Number(groupId));
                setSelectedGroupName(selectedGroup.name);
            }
        }
        setIsSettingsOpen(false);
    };


    // Existing user context effect
    useEffect(() => {
        const fetchUserContext = async () => {
            try {
                const [profileRes, topicsRes] = await Promise.all([
                    axios.get(API_ENDPOINTS.SPIRITUAL_PROFILE),
                    axios.get(API_ENDPOINTS.RECENT_DIRECTION_TOPICS)
                ]);
                
                setSpiritualProfile(profileRes.data);
                setRecentTopics(topicsRes.data);
            } catch (error) {
                console.error('Error fetching user context:', error);
            }
        };

        fetchUserContext();
    }, []);


    // 2. Replace the existing scroll effects with these three scroll-related effects:
    useEffect(() => {
        const chatContainer = chatRef.current;
        if (!chatContainer) return;

        const handleScroll = () => {
            const isNearBottom =
                chatContainer.scrollHeight -
                chatContainer.scrollTop -
                chatContainer.clientHeight <
                100;
            shouldScrollRef.current = isNearBottom;
        };

        chatContainer.addEventListener("scroll", handleScroll);
        return () => chatContainer.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (isLoading && !currentResponse) {  // Only scroll when loading starts, before any response
            requestAnimationFrame(() => {
                if (chatRef.current) {
                    chatRef.current.scrollTop = chatRef.current.scrollHeight;
                }
            });
        }
    }, [isLoading, currentResponse]);

    // Helper function for manual scrolling if needed
    const scrollToBottom = () => {
        if (chatRef.current) {
            shouldScrollRef.current = true;
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    };


    const handleVoiceInput = async (data) => {
        try {
            // Handle status updates
            if (data.type === 'status') {
                setIsVoiceProcessing(data.status === 'recording');
                // Add user's voice message when starting
                if (data.status === 'recording') {
                    setMessages(prev => [...prev, { 
                        type: 'user', 
                        content: 'Voice message...' 
                    }]);
                    setIsLoading(true);
                }
                return;
            }

            // Handle text responses
            if (data.type === 'text') {
                setCurrentResponse(prev => prev + data.content);
            } 
            // Handle audio responses
            else if (data.type === 'audio') {
                // Play audio
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const audioData = atob(data.audio);
                const arrayBuffer = new ArrayBuffer(audioData.length);
                const view = new Uint8Array(arrayBuffer);
                for (let i = 0; i < audioData.length; i++) {
                    view[i] = audioData.charCodeAt(i);
                }
                
                const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
                const source = audioContext.createBufferSource();
                source.buffer = audioBuffer;
                source.connect(audioContext.destination);
                source.start();
            }

            // When response is complete
            if (data.type === 'complete') {
                setMessages(prev => [...prev, {
                    type: 'director',
                    content: currentResponse
                }]);
                setCurrentResponse('');
                setIsLoading(false);
                setIsVoiceProcessing(false);
            }
        } catch (error) {
            console.error('Error handling voice input:', error);
            setMessages(prev => [...prev, {
                type: 'error',
                content: 'Error processing voice message'
            }]);
            setIsLoading(false);
            setIsVoiceProcessing(false);
        }
    };


    // Cleanup effect for EventSource
    useEffect(() => {
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
        };
    }, []);

    const handleAssessmentComplete = async (assessmentData) => {
        try {
            await axios.post(API_ENDPOINTS.SUBMIT_ASSESSMENT, assessmentData);
            setShowAssessment(false);
            const profileRes = await axios.get(API_ENDPOINTS.SPIRITUAL_PROFILE);
            setSpiritualProfile(profileRes.data);
        } catch (error) {
            console.error('Error submitting assessment:', error);
        }
    };


    // Also add this style for streaming content:
    const streamingStyles = {
        '.structured-guidance.streaming .guidance-section': {
            opacity: 1,
            transform: 'translateY(0)',
            transition: 'all 0.3s ease-in-out'
        },
        '.guidance-section:not(:first-child)': {
            marginTop: '1rem'
        },
        '.guidance-section.understanding.stream-active': {
            backgroundColor: 'var(--background-secondary)',
            padding: '1rem',
            borderRadius: '0.5rem',
            borderLeft: '4px solid var(--primary-color)'
        },
        '.guidance-section.scripture.stream-active': {
            backgroundColor: '#fffbeb',
            padding: '1rem',
            borderRadius: '0.5rem',
            borderLeft: '4px solid var(--warning-color)'
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!inputText.trim() || isLoading || !effectiveUserId) return;

        try {
            setIsLoading(true);
            setMessages((prev) => [
                ...prev,
                { type: "user", content: inputText },
            ]);

            const userMessage = inputText;
            setInputText("");
            setCurrentResponse("");

            const requestBody = {
                user_id: effectiveUserId,
                message: userMessage,
                category: selectedArea,
                group_id: selectedGroupId,  // Keep your existing group logic
                context: {
                    recentTopics,
                    spiritualProfile,
                    topic_context: topicResponses[selectedArea] || {},
                    current_topic: selectedArea,
                    current_sub_topic: currentSubTopic,
                },
            };

            const response = await fetch(API_ENDPOINTS.SPIRITUAL_DIRECTION, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedText = "";
            let currentSection = null;
            let questionBuffer = "";
            let contextTags = "";
            let inContextTags = false;

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split("\n").filter((line) => line.trim());

                for (const line of lines) {
                    try {
                        if (!line.startsWith("data: ")) continue;

                        const data = JSON.parse(line.slice(6));
                        console.log("Received stream data:", data);

                        if (data.error) {
                            setMessages((prev) => [
                                ...prev,
                                { type: "error", content: data.error },
                            ]);
                            setIsLoading(false);
                            return;
                        }

                        if (data.type === "content") {
                            const chunk = data.content.trim();

                            // Handle context tags section - stop streaming here
                            if (chunk.includes("<context_tags>")) {
                                // Finalize and send the complete message
                                setMessages((messages) => [
                                    ...messages,
                                    { type: "director", content: accumulatedText },
                                ]);
                                setIsLoading(false);
                                setCurrentResponse("");
                                return;
                            }

                            // Only process content if we haven't hit context tags
                            if (!inContextTags) {
                                // Handle question sections
                                if (data.section === "?" || data.section === "S") {
                                    if (chunk === "?") {
                                        continue;
                                    }
                                    
                                    if (!questionBuffer && chunk.trim()) {
                                        if (accumulatedText) {
                                            accumulatedText = accumulatedText.replace(/\?$/, "");
                                            if (!accumulatedText.endsWith("\n\n")) {
                                                accumulatedText += "\n\n";
                                            }
                                        }
                                        questionBuffer = "<?>";
                                    }
                                    
                                    if (chunk.trim() && !chunk.includes("context_tags")) {
                                        questionBuffer += (questionBuffer === "<?>" ? " " : " ") + chunk;
                                    }
                                    
                                    if (data.section === "S" && chunk.endsWith("?")) {
                                        accumulatedText += questionBuffer;
                                        questionBuffer = "";
                                    }
                                    continue;
                                }

                                // Handle section transitions
                                if (currentSection !== data.section && data.section) {
                                    if (accumulatedText && !accumulatedText.endsWith("\n\n")) {
                                        accumulatedText += "\n\n";
                                    }
                                    currentSection = data.section;
                                }

                                // Process content based on section type
                                switch (data.section) {
                                    case "#":
                                        accumulatedText += "# " + chunk;
                                        break;
                                    case "*":
                                        if (chunk.trim()) {
                                            let bulletContent = chunk.trim();
                                            if (!accumulatedText.endsWith('\n')) {
                                                accumulatedText += "\n";
                                            }
                                            if (bulletContent.includes('*')) {
                                                const points = bulletContent.split('*')
                                                    .filter(point => point.trim())
                                                    .map(point => `* ${point.trim()}`);
                                                accumulatedText += points.join('\n');
                                            } else {
                                                accumulatedText += `* ${bulletContent}`;
                                            }
                                            if (!accumulatedText.endsWith('\n')) {
                                                accumulatedText += "\n";
                                            }
                                        }
                                        break;
                                    case "~":
                                        accumulatedText += "~ " + chunk;
                                        break;
                                    case "@":
                                    default:
                                        if (chunk.trim() && !chunk.includes("context_tags")) {
                                            const cleanedChunk = chunk.replace(/\?$/, "");
                                            if (accumulatedText && 
                                                !accumulatedText.endsWith(" ") && 
                                                !accumulatedText.endsWith("\n") &&
                                                cleanedChunk.match(/^[A-Z]/)) {
                                                accumulatedText += " ";
                                            }
                                            accumulatedText += cleanedChunk;
                                        }
                                }

                                setCurrentResponse(accumulatedText);
                            }
                        }
                    } catch (e) {
                        console.error("Error processing stream data:", e);
                    }
                }
            }
        } catch (error) {
            console.error("Error:", error);
            setMessages((prev) => [
                ...prev,
                { type: "error", content: "An error occurred. Please try again." },
            ]);
            setIsLoading(false);
        }
    };



    // Also add this helper function:
    const parseStreamContent = (content) => {
        if (typeof content !== 'string') return content;
        
        try {
            return JSON.parse(content);
        } catch (e) {
            return content;
        }
    };


    const renderGuidanceContent = (content) => {
        if (!content) return null;
        
        // Pre-process content to fix any malformed question tags
        const processContent = (text) => {
            return text
                .replace(/<\?\s*(?!>)/g, "<?>")  // Fix incomplete <?> tags
                .replace(/<\?(?!>)/g, "<?>")      // Fix malformed <? tags
                .replace(/<\?$/, "<?>");          // Fix trailing incomplete tags
        };
        
        const processedContent = processContent(content);
        
        // Split by double newlines to separate major sections
        const sections = processedContent.split(/\n\n+/).filter(section => section.trim());
        const formatted = [];
        let currentList = [];
        
        sections.forEach((section, index) => {
            const trimmedSection = section.trim();
            const lines = trimmedSection.split('\n');
            
            lines.forEach((line, lineIndex) => {
                const trimmedLine = line.trim();
                
                // Enhanced question detection
                const isQuestion = 
                    trimmedLine.includes("<?>") || 
                    trimmedLine.includes("<?") || 
                    (trimmedLine.startsWith("Could you") && trimmedLine.endsWith("?")) ||
                    (trimmedLine.startsWith("What") && trimmedLine.endsWith("?")) ||
                    (trimmedLine.startsWith("How") && trimmedLine.endsWith("?"));
                    
                if (isQuestion) {
                    // Handle any remaining list items before the question
                    if (currentList.length > 0) {
                        formatted.push(
                            <ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>
                        );
                        currentList = [];
                    }
                    
                    // Format question with special styling
                    formatted.push(
                        <div key={`question-${index}-${lineIndex}`} className="spiritual-question">
                            {trimmedLine.replace(/<\?>|<\?/g, "").trim()}
                        </div>
                    );
                } else if (trimmedLine.startsWith('# ')) {
                    // Rest of the formatting logic remains the same...
                    if (currentList.length > 0) {
                        formatted.push(
                            <ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>
                        );
                        currentList = [];
                    }
                    formatted.push(
                        <h2 key={`title-${index}-${lineIndex}`}>
                            {trimmedLine.replace('# ', '')}
                        </h2>
                    );
                } else if (trimmedLine.startsWith('* ')) {
                    currentList.push(
                        <li key={`item-${index}-${lineIndex}`}>
                            {trimmedLine.replace('* ', '')}
                        </li>
                    );
                } else if (trimmedLine.startsWith('> ')) {
                    if (currentList.length > 0) {
                        formatted.push(
                            <ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>
                        );
                        currentList = [];
                    }
                    formatted.push(
                        <blockquote key={`quote-${index}-${lineIndex}`}>
                            {trimmedLine.replace('> ', '')}
                        </blockquote>
                    );
                } else if (trimmedLine && !trimmedLine.includes('<context_tags>')) {
                    if (currentList.length > 0) {
                        formatted.push(
                            <ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>
                        );
                        currentList = [];
                    }
                    formatted.push(
                        <p key={`text-${index}-${lineIndex}`}>{trimmedLine}</p>
                    );
                }
            });
        });

        // Add any remaining list items
        if (currentList.length > 0) {
            formatted.push(<ul key="final-list">{currentList}</ul>);
        }

        return <div className="guidance-content">{formatted}</div>;
    };


    const renderMessage = (message) => {
        switch (message.type) {
            case 'welcome':
                return (
                    <div className="welcome-message">
                        <h3>Welcome to Catholic Spiritual Direction</h3>
                        <p>
                            Share your thoughts, questions, or concerns about any aspect of your faith journey. 
                            Think of this as a conversation with a spiritual director who's here to help you 
                            grow closer to God.
                        </p>
                    </div>
                );

            case 'user':
                return (
                    <div className="message-bubble user">
                        {message.content}
                    </div>
                );

            case 'director':
                return (
                    <div className="structured-guidance">
                        {renderGuidanceContent(message.content)}
                    </div>
                );

            case 'error':
                return (
                    <div className="error-message">
                        {message.content}
                    </div>
                );

            default:
                return null;
        }
    };



    const handleAreaSelect = (area) => {
        setSelectedArea(area);
        if (SPIRITUAL_AREAS[area]?.suggestedPrompts?.length > 0) {
            const randomPrompt = SPIRITUAL_AREAS[area].suggestedPrompts[
                Math.floor(Math.random() * SPIRITUAL_AREAS[area].suggestedPrompts.length)
            ];
            setInputText(randomPrompt);
        }
    };


    return (
        <div className="page-container">
            <Header />
            <Navbar />
            
            <div className="main-content">
                <div className="direction-content">
                    <div className="direction-scrollable">
                        <div className="direction-chat">
                            {/* Group Selector - New Addition */}
                            <div className="group-navigation">
                                <button 
                                    className="group-selector"
                                    onClick={() => setIsSettingsOpen(!isSettingsOpen)}
                                    aria-label="Select chat group"
                                >
                                    <Users size={16} className="group-icon" />
                                    <span className="group-name">{selectedGroupName}</span>
                                    <ChevronDown 
                                        size={12} 
                                        className={`chevron ${isSettingsOpen ? 'rotated' : ''}`}
                                    />
                                </button>
                                
                                {isSettingsOpen && (
                                    <div className="group-dropdown">
                                        <div className="dropdown-header">
                                            Select Chat Group
                                        </div>
                                        <div 
                                            className={`group-option ${!selectedGroupId ? 'selected' : ''}`}
                                            onClick={() => handleGroupChange('')}
                                        >
                                            <Globe size={14} />
                                            <span>General Chat</span>
                                            {!selectedGroupId && <Check className="check-icon" size={12} />}
                                        </div>
                                        {userGroups.map(group => (
                                            <div 
                                                key={group.id}
                                                className={`group-option ${selectedGroupId === group.id ? 'selected' : ''}`}
                                                onClick={() => handleGroupChange(group.id.toString())}
                                            >
                                                <Users size={14} />
                                                <span>{group.name}</span>
                                                {selectedGroupId === group.id && <Check className="check-icon" size={12} />}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {/* Topic Selection Header */}
                            <div className="direction-header">
                                <div className="spiritual-areas">
                                    {Object.entries(SPIRITUAL_AREAS).map(([key, area]) => (
                                        <div 
                                            key={key} 
                                            className="area-button-container"
                                            onMouseEnter={() => setShowTooltip({ id: key, show: true })}
                                            onMouseLeave={() => setShowTooltip({ id: null, show: false })}
                                        >
                                            <button
                                                className={`area-button ${selectedArea === key ? 'selected' : ''}`}
                                                onClick={() => handleAreaSelect(key)}
                                                aria-label={area.label}
                                                title={area.description}
                                            >
                                                {area.icon}
                                                <span>{area.label}</span>
                                            </button>
                                            
                                            {showTooltip.id === key && showTooltip.show && (
                                                <div className="area-tooltip" role="tooltip">
                                                    {area.description}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Chat Messages Area */}
                            <div 
                                className="direction-messages" 
                                ref={chatRef}
                                role="log"
                                aria-live="polite"
                            >
                                {/* Welcome Message */}
                                {showWelcome && renderMessage({ type: 'welcome' })}
                                
                                {/* Existing Messages */}
                                {messages.map((message, index) => (
                                    <div 
                                        key={index} 
                                        className={`message ${message.type}`}
                                        role={message.type === 'user' ? 'note' : 'article'}
                                    >
                                        {renderMessage(message)}
                                    </div>
                                ))}
                                
                                {/* Updated Streaming Response */}
                                {isLoading && currentResponse && (
                                    <div className="message director" role="article">
                                        <div className="structured-guidance streaming">
                                            {renderGuidanceContent(currentResponse)}
                                            <div className="typing-indicator" aria-label="Director is typing">
                                                <div className="dots">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                                <span className="sr-only">
                                                    Spiritual director is composing a response
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {/* Updated Loading Indicator */}

                                {isLoading && !currentResponse && (
                                    <div className="loading-indicator" role="status" aria-label="Loading response">
                                        <div className="loading-dots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <span>
                                            {isVoiceProcessing 
                                                ? "Listening to your message..." 
                                                : "Reflecting on your message..."}
                                        </span>
                                    </div>
                                )}

                            </div>

                            {/* Input Form */}
                            <form 
                                onSubmit={handleSubmit} 
                                className="direction-input"
                                role="form"
                            >
                                <div className="input-wrapper">
                                    <textarea
                                        value={inputText}
                                        onChange={(e) => setInputText(e.target.value)}
                                        placeholder="Share your thoughts, questions, or concerns..."
                                        rows="3"
                                        className="input-textarea"
                                        disabled={isLoading}
                                        aria-label="Message input"
                                        role="textbox"
                                        aria-multiline="true"
                                    />

                                    <RealtimeVoiceControls
                                      effectiveUserId={effectiveUserId}
                                      context={{
                                        current_topic: selectedArea,
                                        spiritualProfile,
                                        group_id: selectedGroupId
                                      }}
                                    />

                                    <button
                                        type="submit"
                                        disabled={isLoading || !inputText.trim()}
                                        className="send-button"
                                        aria-label="Send message"
                                    >

                                        <Send className="send-icon" aria-hidden="true" />
                                        <span>Send</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Assessment Modal */}
            {showAssessment && (
                <AssessmentModal
                    onSubmit={handleAssessmentComplete}
                    onClose={() => setShowAssessment(false)}
                    isFirstVisit={isFirstVisit}
                />
            )}
        </div>
    );

};

export default DirectionChat;