import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FiPhone, FiCalendar, FiClock, FiRefreshCw, FiPlus, FiX } from 'react-icons/fi';
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import StripeCheckout from '../../components/StripeCheckout/StripeCheckout';
import PublicLayout from '../../components/PublicLayout/PublicLayout';
import './CallScheduled.css';
import { API_ENDPOINTS } from "../../config/api";
import axios from 'axios';
import Swal from 'sweetalert2';
import { signup } from '../../store/actions/userActions';

const TWILIO_PHONE = '888-800-0000';
const PARISH_CODES = ['straymond_mp', 'our_lady_assumption', 'cathedral_sacramento', 'all_souls'];

const TimeSelector = ({ onTimeSelect, onRemove, initialTime = '' }) => {
  const [hours, setHours] = useState(initialTime ? parseInt(initialTime.split(':')[0]) : 9);
  const [minutes, setMinutes] = useState(initialTime ? parseInt(initialTime.split(':')[1]) : 0);
  const [period, setPeriod] = useState(initialTime ? (parseInt(initialTime.split(':')[0]) >= 12 ? 'PM' : 'AM') : 'AM');

  const handleChange = (type, value) => {
    let newHours = hours;
    let newMinutes = minutes;
    let newPeriod = period;

    switch(type) {
      case 'hours':
        newHours = parseInt(value);
        break;
      case 'minutes':
        newMinutes = parseInt(value);
        break;
      case 'period':
        newPeriod = value;
        break;
      default:
        break;
    }

    let finalHours = newHours;
    if (newPeriod === 'PM' && newHours !== 12) {
      finalHours = newHours + 12;
    } else if (newPeriod === 'AM' && newHours === 12) {
      finalHours = 0;
    }

    setHours(newHours);
    setMinutes(newMinutes);
    setPeriod(newPeriod);
    
    onTimeSelect(`${finalHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`);
  };

  return (
    <div className="time-selector">
      <select
        value={hours}
        onChange={(e) => handleChange('hours', e.target.value)}
        className="time-select"
      >
        {[12, ...Array(11).keys().map(i => i + 1)].map(hour => (
          <option key={hour} value={hour}>{hour}</option>
        ))}
      </select>
      <span>:</span>
      <select
        value={minutes}
        onChange={(e) => handleChange('minutes', e.target.value)}
        className="time-select"
      >
        {Array(12).fill().map((_, i) => (
          <option key={i * 5} value={i * 5}>{(i * 5).toString().padStart(2, '0')}</option>
        ))}
      </select>
      <select
        value={period}
        onChange={(e) => handleChange('period', e.target.value)}
        className="time-select"
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
      <button onClick={onRemove} className="remove-time">
        <FiX />
      </button>
    </div>
  );
};

const CallScheduled = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { scheduledTime } = location.state || {};
  const [phoneNumber, setPhoneNumber] = useState(() => {
    return location.state?.phoneNumber || localStorage.getItem('userPhone');
  });
  
  const daysOfWeek = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ];

  const [schedule, setSchedule] = useState(
    daysOfWeek.map(day => ({
      day,
      isSelected: false,
      times: []
    }))
  );

  const [step, setStep] = useState('registration');
  const [formData, setFormData] = useState(null);
  const [affiliationCode, setAffiliationCode] = useState('');
  const [showAffiliationInput, setShowAffiliationInput] = useState(false);
  const [isProcessingAffiliation, setIsProcessingAffiliation] = useState(false);

  useEffect(() => {
    document.body.classList.add('call-scheduled-page');
    return () => document.body.classList.remove('call-scheduled-page');
  }, []);

  const handleDaySelect = (day) => {
    setSchedule(currentSchedule => 
      currentSchedule.map(item => {
        if (item.day === day) {
          return {
            ...item,
            isSelected: !item.isSelected,
            times: !item.isSelected && item.times.length === 0 ? ['09:00'] : item.times
          };
        }
        return item;
      })
    );
  };



const addTimeToDay = (day) => {
    setSchedule(currentSchedule =>
      currentSchedule.map(item => {
        if (item.day === day && item.times.length < 3) {
          return {
            ...item,
            times: [...item.times, '09:00']
          };
        }
        return item;
      })
    );
  };

  const updateTimeForDay = (day, timeIndex, newTime) => {
    setSchedule(currentSchedule =>
      currentSchedule.map(item => {
        if (item.day === day) {
          const newTimes = [...item.times];
          newTimes[timeIndex] = newTime;
          return {
            ...item,
            times: newTimes
          };
        }
        return item;
      })
    );
  };

  const removeTimeFromDay = (day, timeIndex) => {
    setSchedule(currentSchedule =>
      currentSchedule.map(item => {
        if (item.day === day) {
          const newTimes = item.times.filter((_, i) => i !== timeIndex);
          return {
            ...item,
            times: newTimes,
            isSelected: newTimes.length > 0
          };
        }
        return item;
      })
    );
  };

  const handleRegistrationComplete = async (data) => {
    try {
      const scheduleData = schedule
        .filter(item => item.isSelected && item.times.length > 0)
        .map(item => ({
          day: item.day,
          times: item.times.filter(time => time && time.length > 0)
        }))
        .filter(item => item.times.length > 0);

      setFormData({
        fullName: data.fullName,
        email: data.email,
        phone: phoneNumber || data.phone,
        password: data.password,
        initialAssessment: data.initialAssessment || [],
        schedule: scheduleData
      });
      setStep('affiliation');
    } catch (error) {
      console.error('Error processing registration:', error);
      await Swal.fire({
        icon: "error",
        title: "Registration Error",
        text: "An error occurred. Please try again.",
        confirmButtonText: "OK"
      });
    }
  };

  const handleAffiliationSubmit = async (e) => {
    e.preventDefault();
    setIsProcessingAffiliation(true);

    try {
      if (PARISH_CODES.includes(affiliationCode.toLowerCase())) {
        const userData = {
          ...formData,
          subscriptionType: "standard",
          subscription_status: "parish_affiliated"
        };
        
        const userId = await dispatch(signup(userData, affiliationCode));

        await Swal.fire({
          icon: 'success',
          title: 'Parish Affiliation Verified!',
          text: 'Your parish affiliation has been confirmed. Redirecting to your faith journey...',
          showConfirmButton: false,
          timer: 2000
        });

        navigate('/faith-journey', { 
          state: { 
            newUser: true, 
            userId: userId 
          } 
        });
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Invalid Parish Code',
          text: 'The parish code you entered is not valid. Please check the code and try again.',
          confirmButtonColor: '#4a90e2'
        });
      }
    } catch (error) {
      console.error('Error during registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: error.response?.data?.message || 'Failed to complete registration. Please try again.'
      });
    } finally {
      setIsProcessingAffiliation(false);
    }
  };

  const handleTrialStart = async () => {
    try {
      const userData = {
        ...formData,
        subscriptionType: "trial",
        subscription_status: "trial"
      };
      
      const userId = await dispatch(signup(userData));

      await Swal.fire({
        icon: 'success',
        title: 'Welcome!',
        text: 'Your trial has started! You will receive an email before it ends.',
        showConfirmButton: true,
        confirmButtonColor: '#4a90e2'
      });

      navigate('/faith-journey', { 
        state: { 
          newUser: true, 
          userId: userId 
        } 
      });
    } catch (error) {
      console.error('Error during trial registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Failed to start trial. Please try again.'
      });
    }
  };

  const handlePaymentStart = async () => {
    try {
      const userData = {
        ...formData,
        subscriptionType: "standard",
        subscription_status: "regular"
      };
      
      const userId = await dispatch(signup(userData));
      setFormData(prev => ({ ...prev, userId }));
      setStep('payment');
    } catch (error) {
      console.error('Error during registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: error.response?.data?.message || 'Failed to complete registration. Please try again.'
      });
    }
  };

  return (
    <PublicLayout
      className="call-scheduled-page"
      render={() => (
        <div className="call-scheduled-content">
          <div className="top-section">
            <div className="call-status">
              <FiPhone className="status-icon" />
              <h1>{scheduledTime ? 'Call Scheduled' : 'Set Your Daily or Weekly Recurring Spiritual Directions Calls'}</h1>
              <p>{scheduledTime ? 
                `Calling ${phoneNumber} at ${scheduledTime}` : 
                `Register to Begin with Personalized Conversations, Set Goals, and Spiritual Direction Accessible 24/7.`}
              </p>
            </div>

            <div className="schedule-section">
              <div className="days-grid">
                {schedule.map((dayItem) => (
                  <div 
                    key={dayItem.day}
                    className={`day-card ${dayItem.isSelected ? 'selected' : ''}`}
                  >
                    <button
                      onClick={() => handleDaySelect(dayItem.day)}
                      className="day-header"
                    >
                      <span className="day-name">{dayItem.day}</span>
                      {dayItem.times.length > 0 && (
                        <span className="times-count">{dayItem.times.length}</span>
                      )}
                    </button>
                    
                    {dayItem.isSelected && (
                      <div className="day-times">
                        {dayItem.times.map((time, index) => (
                          <TimeSelector
                            key={index}
                            initialTime={time}
                            onTimeSelect={(newTime) => updateTimeForDay(dayItem.day, index, newTime)}
                            onRemove={() => removeTimeFromDay(dayItem.day, index)}
                          />
                        ))}
                        {dayItem.times.length < 3 && (
                          <button 
                            onClick={() => addTimeToDay(dayItem.day)}
                            className="add-time"
                          >
                            <FiPlus /> Add Time
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {step === 'registration' && (
            <div className="signup-section">
              <h2>Register To Begin</h2>
              
              <div className="mini-features">
                <div className="feature">
                  <FiClock /> <span>24/7 Access</span>
                </div>
                <div className="feature">
                  <FiCalendar /> <span>Daily Check-ins</span>
                </div>
                <div className="feature">
                  <FiRefreshCw /> <span>Progress Tracking</span>
                </div>
              </div>
              
              <SignUpForm 
                prefillPhone={phoneNumber}
                onSuccess={handleRegistrationComplete}
              />
            </div>
          )}

          {step === 'affiliation' && (
            <div className="signup-section">
              <div className="signup-header">
                <h1>Complete Your Registration</h1>
                <p className="lead-text">Choose how to continue</p>
              </div>

              <div className="signup-form-wrapper">
                <div className="affiliation-options">
                  <button 
                    className="option-btn"
                    onClick={() => setShowAffiliationInput(true)}
                  >
                    I have a parish affiliation code
                  </button>
                  
                  <div className="option-divider">or</div>
                  
                  <button 
                    className="option-btn trial"
                    onClick={handleTrialStart}
                  >
                    <div className="trial-text">
                      <span className="trial-main">Start 30 Day Trial</span>
                      <span className="trial-details">Set up payment after trial period</span>
                    </div>
                  </button>

                  <div className="option-divider">or</div>

                  <button 
                    className="option-btn premium"
                    onClick={handlePaymentStart}
                  >
                    <div className="trial-text">
                      <span className="trial-main">Set Up Payment Now</span>
                      <span className="trial-details">$8/month after trial unless cancelled</span>
                    </div>
                  </button>
                </div>

                {showAffiliationInput && (
                  <form onSubmit={handleAffiliationSubmit} className="affiliation-form">
                    <div className="form-group">
                      <label htmlFor="affiliation-code">Parish Affiliation Code</label>
                      <input
                        type="text"
                        id="affiliation-code"
                        value={affiliationCode}
                        onChange={(e) => setAffiliationCode(e.target.value)}
                        className="form-input"
                        placeholder="Enter your parish code"
                        disabled={isProcessingAffiliation}
                      />
                    </div>
                    <button 
                      type="submit" 
                      className="signup-submit-btn"
                      disabled={isProcessingAffiliation}
                    >
                      {isProcessingAffiliation ? "Verifying..." : "Verify Code"}
                    </button>
                  </form>
                )}
              </div>
            </div>
          )}

          {step === 'payment' && (
            <div className="signup-section">
              <div className="signup-header">
                <h1>Set Up Your Account</h1>
                <p className="lead-text">Complete your premium subscription</p>
              </div>

              <div className="signup-form-wrapper">
                <StripeCheckout />
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default CallScheduled;

