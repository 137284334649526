import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import './SimplifiedAssessment.css';
import { FiX, FiCheck, FiCalendar, FiClock } from 'react-icons/fi';

const SimplifiedAssessment = ({ userId, onAssessmentComplete }) => {
  const [step, setStep] = useState('customGoal');
  const [selectedGoal, setSelectedGoal] = useState({
    id: 'custom',
    description: '',
    level: 'Custom',
    prompts: {
      weekly: Array(4).fill(''),
      monthly: Array(4).fill('')
    },
    default_schedule: {
      durationType: 'weekly',
      numberOfPeriods: 4,
      scheduleType: 'weekly',
      scheduleDays: []
    }
  });
  
  const [weeklyPrompts, setWeeklyPrompts] = useState(['', '', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  
  const [scheduleConfig, setScheduleConfig] = useState({
    durationType: 'weekly',
    numberOfPeriods: 4,
    scheduleType: 'weekly',
    scheduleDays: [],
    scheduleWeek: 0,
    startDate: new Date().toISOString().split('T')[0]
  });

  const weekdays = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];

  const weekOptions = ['First', 'Second', 'Third', 'Fourth', 'Last'];

  const handleCustomGoalChange = (description) => {
    if (userId > 4000) {
      setShowRegistrationModal(true);
      return;
    }

    setSelectedGoal(prev => ({
      ...prev,
      description
    }));
  };

  const handleDurationTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      durationType: type,
      scheduleType: type,
      scheduleDays: [],
      scheduleWeek: 0
    }));
    
    if (selectedGoal.prompts && selectedGoal.prompts[type]) {
      const promptsToUse = selectedGoal.prompts[type];
      setWeeklyPrompts(prev => {
        const newPrompts = [...prev];
        for (let i = 0; i < newPrompts.length; i++) {
          if (promptsToUse[i]) {
            newPrompts[i] = promptsToUse[i];
          }
        }
        return newPrompts;
      });
    }
  };

  const handleScheduleTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      scheduleType: type,
      scheduleDays: [],
      scheduleWeek: type === 'specific_weekday' ? 0 : null
    }));
  };

  const handleDaySelection = (day) => {
    setScheduleConfig(prev => {
      const newDays = prev.scheduleDays.includes(day)
        ? prev.scheduleDays.filter(d => d !== day)
        : [...prev.scheduleDays, day].sort();
      return { ...prev, scheduleDays: newDays };
    });
  };

  const handlePeriodCountChange = (value) => {
    if (value === '') {
      setScheduleConfig(prev => ({
        ...prev,
        numberOfPeriods: ''
      }));
      return;
    }

    const numValue = parseInt(value);
    if (isNaN(numValue)) return;

    const maxPeriods = scheduleConfig.durationType === 'weekly' ? 52 : 12;
    const clampedValue = Math.min(Math.max(1, numValue), maxPeriods);
    
    setScheduleConfig(prev => ({
      ...prev,
      numberOfPeriods: clampedValue
    }));

    // Update prompts array length if needed
    if (clampedValue > weeklyPrompts.length) {
      setWeeklyPrompts(prev => [...prev, ...Array(clampedValue - prev.length).fill('')]);
    } else if (clampedValue < weeklyPrompts.length) {
      setWeeklyPrompts(prev => prev.slice(0, clampedValue));
    }
  };

  const handlePromptChange = (index, value) => {
    const newPrompts = [...weeklyPrompts];
    newPrompts[index] = value;
    setWeeklyPrompts(newPrompts);
  };

  const calculateEndDate = (startDate, durationType, periods, scheduleType, scheduleDays, scheduleWeek) => {
    const start = new Date(startDate);
    const end = new Date(start);
    
    if (durationType === 'weekly') {
      end.setDate(start.getDate() + (periods * 7) - 1);
    } else if (durationType === 'monthly') {
      end.setMonth(start.getMonth() + periods - 1);

      if (scheduleType === 'specific_weekday') {
        const weekday = scheduleDays[0];
        const week = scheduleWeek;
        
        end.setDate(1);
        
        if (week === -1) {
          end.setMonth(end.getMonth() + 1);
          end.setDate(0);
          while (end.getDay() !== weekday) {
            end.setDate(end.getDate() - 1);
          }
        } else {
          while (end.getDay() !== weekday) {
            end.setDate(end.getDate() + 1);
          }
          end.setDate(end.getDate() + (week * 7));
        }
      } else if (scheduleType === 'specific_date') {
        const daysInMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0).getDate();
        end.setDate(Math.min(scheduleDays[0], daysInMonth));
      } else {
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
      }
    }
    
    return end.toISOString().split('T')[0];
  };


  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let scheduleData = {
        duration_type: scheduleConfig.durationType,
        duration_periods: scheduleConfig.numberOfPeriods,
        schedule_type: scheduleConfig.scheduleType,
        schedule_days: scheduleConfig.scheduleDays,
        schedule_week: scheduleConfig.scheduleType === 'specific_weekday' ? scheduleConfig.scheduleWeek : null,
        start_date: scheduleConfig.startDate
      };

      const endDate = calculateEndDate(
        scheduleData.start_date,
        scheduleData.duration_type,
        scheduleData.duration_periods,
        scheduleData.schedule_type,
        scheduleData.schedule_days,
        scheduleData.schedule_week
      );

      const challengeData = {
        description: selectedGoal.description,
        category: 'Custom',
        challenge_level: selectedGoal.level || 'Custom',
        weekly_prompts: weeklyPrompts,
        period_prompts: weeklyPrompts,
        duration_type: scheduleData.duration_type,
        duration_periods: scheduleData.duration_periods,
        schedule_type: scheduleData.schedule_type,
        schedule_days: scheduleData.schedule_days,
        schedule_week: scheduleData.schedule_week,
        start_date: scheduleData.start_date,
        end_date: endDate
      };

      console.log('Submitting challenge data:', challengeData);
      
      const endpoint = `${API_ENDPOINTS.SUBMIT_SELECTED_CHALLENGES}/${userId}`;
      const response = await axios.post(
        endpoint,
        {
          selectedChallenges: [challengeData]
        }
      );
      
      if (response.data.success) {
        if (onAssessmentComplete) {
          await onAssessmentComplete(challengeData);
        }
        
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          resetComponent();
        }, 3000);
      } else {
        throw new Error(response.data.error || 'Failed to submit challenge');
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      alert('Failed to submit goal data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetComponent = () => {
    setStep('customGoal');
    setSelectedGoal({
      id: 'custom',
      description: '',
      level: 'Custom',
      prompts: {
        weekly: Array(4).fill(''),
        monthly: Array(4).fill('')
      },
      default_schedule: {
        durationType: 'weekly',
        numberOfPeriods: 4,
        scheduleType: 'weekly',
        scheduleDays: []
      }
    });
    setWeeklyPrompts(['', '', '', '']);
    setApiResponse(null);
    setScheduleConfig({
      durationType: 'weekly',
      numberOfPeriods: 4,
      scheduleType: 'weekly',
      scheduleDays: [],
      scheduleWeek: 0,
      startDate: new Date().toISOString().split('T')[0]
    });
  };

  const renderCustomGoalInput = () => (
    <div className="custom-goal-section">
      <h3>Create Your Personal Goal</h3>
      <p className="section-description">
        Describe a spiritual or personal goal you'd like to work towards. 
        We'll help you create a structured plan with regular check-ins.
      </p>
      
      <div className="custom-goal-form">
        <div className="form-group">
          <label>What would you like to achieve?</label>
          <textarea
            value={selectedGoal.description}
            onChange={(e) => handleCustomGoalChange(e.target.value)}
            placeholder="Example: I want to pray for 10 minutes every morning, or read a chapter of scripture daily..."
            className="custom-input"
            rows={4}
          />
          <p className="input-help">Be specific about what you want to accomplish and how often.</p>
        </div>

        <button
          className="submit-button"
          onClick={() => setStep('schedule')}
          disabled={!selectedGoal.description}
        >
          Continue to Schedule
        </button>
      </div>
    </div>
  );

  const renderScheduleConfiguration = () => (
    <div className="schedule-section">
      <button className="back-button" onClick={() => setStep('customGoal')}>Back</button>
      <h3>Configure Schedule</h3>

      <div className="schedule-configuration">
        <div className="schedule-type-section">
          <div className="form-group">
            <label>Duration Type</label>
            <select 
              value={scheduleConfig.durationType}
              onChange={(e) => handleDurationTypeChange(e.target.value)}
              className="schedule-select"
            >
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>

          <div className="form-group">
            <label>Number of {scheduleConfig.durationType === 'weekly' ? 'Weeks' : 'Months'}</label>
            <input
              type="number"
              min="1"
              max={scheduleConfig.durationType === 'weekly' ? 52 : 12}
              value={scheduleConfig.numberOfPeriods}
              onChange={(e) => handlePeriodCountChange(e.target.value)}
              className="period-input"
              onFocus={(e) => e.target.select()}
            />
          </div>

          <div className="form-group">
            <label>Schedule Pattern</label>
            <select
              value={scheduleConfig.scheduleType}
              onChange={(e) => handleScheduleTypeChange(e.target.value)}
              className="schedule-select"
            >
              {scheduleConfig.durationType === 'weekly' ? (
                <option value="weekly">Every Week</option>
              ) : (
                <>
                  <option value="monthly">Every Month</option>
                  <option value="specific_date">Specific Dates</option>
                  <option value="specific_weekday">Specific Weekday</option>
                </>
              )}
            </select>
          </div>
          
          {scheduleConfig.scheduleType === 'specific_weekday' && (
            <div className="weekday-selection">
              {scheduleConfig.durationType === 'monthly' && (
                <div className="form-group">
                  <label>Week of Month</label>
                  <select
                    value={scheduleConfig.scheduleWeek}
                    onChange={(e) => setScheduleConfig(prev => ({
                      ...prev,
                      scheduleWeek: parseInt(e.target.value)
                    }))}
                    className="week-select"
                  >
                    {weekOptions.map((option, index) => (
                      <option key={index} value={index === 4 ? -1 : index}>{option}</option>
                    ))}
                  </select>
                </div>
              )}
              
              <div className="weekday-selection-container">
                <label>Select Day(s)</label>
                <div className="weekday-buttons">
                  {weekdays.map((day, idx) => (
                    <button
                      key={idx}
                      type="button"
                      className={`weekday-button ${scheduleConfig.scheduleDays.includes(idx) ? 'selected' : ''}`}
                      onClick={() => handleDaySelection(idx)}
                    >
                      {day.substring(0, 3)}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}

          {scheduleConfig.scheduleType === 'specific_date' && 
           scheduleConfig.durationType === 'monthly' && (
            <div className="date-selection">
              <label>Select Date(s) of Month</label>
              <div className="date-grid">
                {Array.from({length: 31}, (_, i) => i + 1).map(date => (
                  <button
                    key={date}
                    type="button"
                    className={`date-button ${scheduleConfig.scheduleDays.includes(date) ? 'selected' : ''}`}
                    onClick={() => handleDaySelection(date)}
                  >
                    {date}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              value={scheduleConfig.startDate}
              onChange={(e) => setScheduleConfig(prev => ({
                ...prev,
                startDate: e.target.value
              }))}
              className="date-input"
              min={new Date().toISOString().split('T')[0]}
            />
          </div>
        </div>
      </div>

      <button 
        className="submit-button"
        onClick={() => setStep('prompts')}
        disabled={scheduleConfig.numberOfPeriods < 1}
      >
        Continue to Set Check-in Prompts
      </button>
    </div>
  );

  const renderPrompts = () => (
    <div className="prompt-section">
      <button className="back-button" onClick={() => setStep('schedule')}>Back</button>
      
      <div className="goal-header">
        <h3>Your Goal</h3>
        <div className="goal-title">{selectedGoal.description}</div>
        
        <div className="schedule-summary-cards">
          <div className="summary-card">
            <div className="summary-icon"><FiClock /></div>
            <div className="summary-content">
              <div className="summary-label">Duration</div>
              <div className="summary-value">
                {scheduleConfig.durationType === 'weekly' 
                  ? `${scheduleConfig.numberOfPeriods} week goal` 
                  : `${scheduleConfig.numberOfPeriods} month goal`}
              </div>
            </div>
          </div>

          <div className="summary-card">
            <div className="summary-icon"><FiCalendar /></div>
            <div className="summary-content">
              <div className="summary-label">Check-ins</div>
              <div className="summary-value">
                {scheduleConfig.scheduleType === 'specific_weekday' 
                  ? `${scheduleConfig.durationType === 'monthly' 
                      ? `${weekOptions[scheduleConfig.scheduleWeek]} ` 
                      : ''}${scheduleConfig.scheduleDays.map(day => weekdays[day]).join(', ')}` 
                  : scheduleConfig.scheduleType === 'specific_date'
                    ? `Monthly on: ${scheduleConfig.scheduleDays.join(', ')}`
                    : `Regular ${scheduleConfig.durationType} check-ins`}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="prompts-container">
        <h3>{scheduleConfig.durationType === 'weekly' ? 'Weekly' : 'Monthly'} Check-in Prompts</h3>
        <p className="prompts-description">
          Customize the prompts that will guide your reflections during each check-in.
        </p>
        
        <div className="prompt-cards">
          {Array.from({ length: scheduleConfig.numberOfPeriods }).map((_, index) => (
            <div key={index} className="prompt-card">
              <div className="prompt-card-header">
                <span className="prompt-number">
                  {scheduleConfig.durationType === 'weekly' ? 'Week' : 'Month'} {index + 1}
                </span>
              </div>
              <textarea
                value={weeklyPrompts[index] || ''}
                onChange={(e) => handlePromptChange(index, e.target.value)}
                placeholder={`What would you like to reflect on during this ${scheduleConfig.durationType === 'weekly' ? 'week' : 'month'}?`}
                className="prompt-textarea"
              />
            </div>
          ))}
        </div>
        
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? 'Saving...' : 'Start Your Goal Journey'}
        </button>
      </div>
    </div>
  );

  const RegistrationModal = () => (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-icon">✝️</div>
        <h3>Register for a Free Account</h3>
        <p>Register for a free account to set your faith journey activities and grow in your spiritual life.</p>
        <div className="popup-actions">
          <Link 
            to="/signup" 
            className="register-button"
            style={{
              background: 'linear-gradient(135deg, #00b250 0%, #4a90e2 100%)',
              color: 'white',
              padding: '14px 32px',
              borderRadius: '30px',
              textDecoration: 'none',
              fontWeight: '600',
              display: 'inline-block',
              transition: 'all 0.3s ease',
              border: 'none',
              marginBottom: '12px',
              width: '80%',
              textAlign: 'center',
              boxShadow: '0 4px 15px rgba(0, 178, 80, 0.2)'
            }}
          >
            Start Your Journey
          </Link>
          <button 
            onClick={() => setShowRegistrationModal(false)}
            className="later-button"
            style={{
              background: 'none',
              border: 'none',
              color: '#666',
              fontSize: '14px',
              cursor: 'pointer',
              padding: '10px',
              transition: 'color 0.2s'
            }}
          >
            Maybe Later
          </button>
        </div>
        <button 
          className="close-button" 
          onClick={() => setShowRegistrationModal(false)}
          style={{
            position: 'absolute',
            top: '15px',
            right: '20px',
            background: 'none',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            color: '#666',
            padding: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            transition: 'all 0.2s ease'
          }}
        >
          <FiX />
        </button>
      </div>
    </div>
  );


  return (
    <div className="simplified-assessment">
      {showSuccess ? (
        <div className="success-message">
          <p>
            <FiCheck className="success-icon" />
            Goal added successfully! Check in {
              scheduleConfig.scheduleType === 'specific_weekday' 
                ? `on ${scheduleConfig.scheduleDays.map(day => weekdays[day]).join(', ')}` 
                : scheduleConfig.scheduleType === 'specific_date'
                  ? `on the ${scheduleConfig.scheduleDays.join(', ')} of each month`
                  : `regularly as scheduled`
            }. Progress will be integrated into your spiritual direction journey.
          </p>
        </div>
      ) : (
        <>
          {showRegistrationModal && <RegistrationModal />}
          {step === 'customGoal' && renderCustomGoalInput()}
          {step === 'schedule' && renderScheduleConfiguration()}
          {step === 'prompts' && renderPrompts()}
        </>
      )}
    </div>
  );

};

export default SimplifiedAssessment;