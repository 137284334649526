import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import { FiArrowLeft } from 'react-icons/fi';
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import StripeCheckout from '../../components/StripeCheckout/StripeCheckout';
import Swal from "sweetalert2";
import { signup } from '../../store/actions/userActions';
import "./SignUp.css";

const PARISH_CODES = ['straymond_mp', 'our_lady_assumption', 'cathedral_sacramento', 'all_souls'];

const PARISH_NAMES = {
  'straymond_mp': 'St. Raymond Church - Menlo Park',
  'our_lady_assumption': 'Our Lady of the Assumption',
  'cathedral_sacramento': 'Cathedral of the Blessed Sacrament'
};

const SignUp = () => {
  const [step, setStep] = useState('registration');
  const [formData, setFormData] = useState(null);
  const [affiliationCode, setAffiliationCode] = useState('');
  const [showAffiliationInput, setShowAffiliationInput] = useState(false);
  const [isProcessingAffiliation, setIsProcessingAffiliation] = useState(false);
  const [parishName, setParishName] = useState('');
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { referralId } = useParams();
  const location = useLocation();
  const fromInvite = location.state?.fromInvite;
  const queryParams = new URLSearchParams(location.search);
  const groupId = queryParams.get('groupId');
  const parishCode = queryParams.get('parish');

  useEffect(() => {
    document.body.classList.add('hide-navbar');
    return () => {
      document.body.classList.remove('hide-navbar');
    };
  }, []);

  useEffect(() => {
    if (parishCode && PARISH_CODES.includes(parishCode.toLowerCase())) {
      const code = parishCode.toLowerCase();
      setAffiliationCode(code);
      setParishName(PARISH_NAMES[code]);
    }
  }, [parishCode]);

  const handleRegistrationComplete = async (data) => {
    console.log('=== handleRegistrationComplete START ===');
    console.log('Received data:', data);
    console.log('Current parishCode from URL:', parishCode);
    
    if (!data || !data.email || !data.password) {
      console.error('Missing required registration data');
      await Swal.fire({
        icon: "error",
        title: "Registration Error",
        text: "Missing required registration information"
      });
      return;
    }

    if (parishCode && PARISH_CODES.includes(parishCode.toLowerCase())) {
      console.log('User came from parish-affiliated URL');
      try {
        const userId = await dispatch(signup({
          ...data,
          subscriptionType: "standard",
          subscription_status: "parish_affiliated"
        }, parishCode.toLowerCase()));

        navigate("/faith-journey", { 
          state: { 
            newUser: true, 
            userId: userId 
          } 
        });
      } catch (error) {
        console.error('Error during parish registration:', error);
        await Swal.fire({
          icon: "error",
          title: "Registration Error",
          text: error.response?.data?.message || "Failed to complete registration"
        });
      }
    } else {
      console.log('Regular signup flow - proceeding to affiliation step');
      setFormData({
        fullName: data.fullName,
        email: data.email,
        phone: data.phone || "333",
        password: data.password,
        initialAssessment: data.initialAssessment || [],
        referralId: referralId,
        groupId: groupId
      });
      setStep('affiliation');
    }
  };


  const handleAffiliationSubmit = async (e) => {
    e.preventDefault();
    setIsProcessingAffiliation(true);

    try {
      if (PARISH_CODES.includes(affiliationCode.toLowerCase())) {
        const userId = await dispatch(signup({
          ...formData,
          subscriptionType: "standard",
          subscription_status: "parish_affiliated"
        }, affiliationCode));

        await Swal.fire({
          icon: 'success',
          title: 'Parish Affiliation Verified!',
          text: 'Your parish affiliation has been confirmed. Redirecting to your faith journey...',
          showConfirmButton: false,
          timer: 2000
        });

        navigate('/faith-journey', { 
          state: { 
            newUser: true, 
            userId: userId 
          } 
        });
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Invalid Parish Code',
          text: 'The parish code you entered is not valid. Please check the code and try again.',
          confirmButtonColor: '#4a90e2'
        });
      }
    } catch (error) {
      console.error('Error during registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: error.response?.data?.message || 'Failed to complete registration. Please try again.'
      });
    } finally {
      setIsProcessingAffiliation(false);
    }
  };

  const handleTrialStart = async () => {
    try {
      const userId = await dispatch(signup({
        ...formData,
        subscriptionType: "trial",
        subscription_status: "trial"
      }));

      await Swal.fire({
        icon: 'success',
        title: 'Welcome!',
        text: 'Your trial has started! You will receive an email before it ends.',
        showConfirmButton: true,
        confirmButtonColor: '#4a90e2'
      });

      navigate('/faith-journey', { 
        state: { 
          newUser: true, 
          userId: userId 
        } 
      });
    } catch (error) {
      console.error('Error during trial registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Failed to start trial. Please try again.'
      });
    }
  };

  const handlePaymentStart = async () => {
    try {
      const userId = await dispatch(signup({
        ...formData,
        subscriptionType: "standard",
        subscription_status: "regular"
      }));
      
      setFormData(prev => ({ ...prev, userId }));
      setStep('payment');
    } catch (error) {
      console.error('Error during registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: error.response?.data?.message || 'Failed to complete registration. Please try again.'
      });
    }
  };

  return (
    <div className="signup-page">
      <nav className="signup-nav">
        <div className="nav-content">
          <Link to="/home" className="nav-logo">
            <img src="/logo.png" alt="CrossValidation.ai" />
            <span>CrossValidation.ai</span>
          </Link>
          <div className="nav-links">
            <Link to="/home" className="nav-link">
              <FiArrowLeft /> Back to Home
            </Link>
            <Link to="/signin" className="nav-link signin-link">
              Already have an account?
            </Link>
          </div>
        </div>
      </nav>

      <div className="signup-container">
        <div className="signup-content">
          {step === 'registration' && (
            <>
              <div className="signup-header">
                {parishName && (
                  <h2 className="parish-welcome">
                    Welcome To Your Spiritual Director from {parishName}
                  </h2>
                )}
                <h1>Create Your Account</h1>
                <p className="lead-text">
                  Start your faith journey today
                </p>

                {(fromInvite || groupId) && (
                  <div className="invite-banner">
                    <div className="invite-icon">🎉</div>
                    <p>
                      You've been invited to join a faith group! 
                      Complete your registration to connect with your community.
                    </p>
                  </div>
                )}

                {referralId && (
                  <div className="referral-banner">
                    <div className="referral-icon">🎁</div>
                    <p>
                      You've been referred by a friend! You'll both receive benefits when you sign up.
                    </p>
                  </div>
                )}
              </div>

              <div className="signup-form-wrapper">
                <SignUpForm 
                  onSuccess={handleRegistrationComplete}
                  referralId={referralId}
                  groupId={groupId}
                  parishCode={parishCode}
                />
              </div>
            </>
          )}

          {step === 'affiliation' && (
            <>
              <div className="signup-header">
                <h1>Complete Your Registration</h1>
                <p className="lead-text">
                  Choose how to continue
                </p>
              </div>

              <div className="signup-form-wrapper">
                <div className="affiliation-options">
                  <button 
                    className="option-btn"
                    onClick={() => setShowAffiliationInput(true)}
                  >
                    I have a parish affiliation code
                  </button>
                  
                  <div className="option-divider">or</div>
                  
                  <button 
                    className="option-btn trial"
                    onClick={handleTrialStart}
                  >
                    <div className="trial-text">
                      <span className="trial-main">Start 30 Day Trial</span>
                      <span className="trial-details">Set up payment after trial period</span>
                    </div>
                  </button>

                  <div className="option-divider">or</div>

                  <button 
                    className="option-btn premium"
                    onClick={handlePaymentStart}
                  >
                    <div className="trial-text">
                      <span className="trial-main">Set Up Payment Now</span>
                      <span className="trial-details">$8/month after trial unless cancelled</span>
                    </div>
                  </button>
                </div>

                {showAffiliationInput && (
                  <form onSubmit={handleAffiliationSubmit} className="affiliation-form">
                    <div className="form-group">
                      <label htmlFor="affiliation-code">Parish Affiliation Code</label>
                      <input
                        type="text"
                        id="affiliation-code"
                        value={affiliationCode}
                        onChange={(e) => setAffiliationCode(e.target.value)}
                        className="form-input"
                        placeholder="Enter your parish code"
                        disabled={isProcessingAffiliation}
                      />
                    </div>
                    <button 
                      type="submit" 
                      className="signup-submit-btn"
                      disabled={isProcessingAffiliation}
                    >
                      {isProcessingAffiliation ? "Verifying..." : "Verify Code"}
                    </button>
                  </form>
                )}
              </div>
            </>
          )}

          {step === 'payment' && (
            <>
              <div className="signup-header">
                <h1>Set Up Your Account</h1>
                <p className="lead-text">
                  Complete your premium subscription
                </p>
              </div>

              <div className="signup-form-wrapper">
                <StripeCheckout />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
