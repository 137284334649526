import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaCompass, FaPray, FaUsers, FaBook } from 'react-icons/fa';
import './Navbar.css';
import SignupPopup from '../SignupPopup/SignupPopup';

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const { userId, tempUserId, subscription } = useSelector(state => state.user);
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleInvestingAgentClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const effectiveUserId = userId || tempUserId;
    if (effectiveUserId && effectiveUserId > 4000) {
      if (subscription !== 'realtime-trading') {
        setShowSignupPopup(true);
      } else {
        navigate('/rewards');
      }
    } else {
      navigate('/rewards');
    }
  };

  return (
    <>
      <button className={`navbar-toggle ${isNavbarOpen ? 'open' : ''}`} onClick={toggleNavbar}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <nav className={`chat-navbar ${isNavbarOpen ? 'open' : ''}`}>
        <Link to="/faith-journey" className="navbar-header">
          <h2>Faith Journey</h2>
        </Link>
        <ul>
          
          <li>
            <Link to="/daily-prayer" onClick={toggleNavbar}>
              <FaCompass />
              <div className="nav-link-content">
                <span className="nav-link-title">Spiritual Direction Chat</span>
                <span className="nav-link-description">Get guidance for your journey</span>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/group-directory" onClick={toggleNavbar}>
              <FaUsers />
              <div className="nav-link-content">
                <span className="nav-link-title">Ministries</span>
                <span className="nav-link-description">Journey alongside fellow believers</span>
              </div>
            </Link>
          </li>
        </ul>
        <div className="navbar-footer">
          <p>Let God's wisdom guide you.</p>
        </div>
      </nav>
      <div className={`navbar-overlay ${isNavbarOpen ? 'active' : ''}`} onClick={toggleNavbar}></div>
      {showSignupPopup && (
        <SignupPopup
          onClose={() => setShowSignupPopup(false)}
          onConfirm={() => {
            setShowSignupPopup(false);
            navigate('/signup');
          }}
        />
      )}
    </>
  );
};

export default Navbar;