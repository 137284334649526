import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import './AssessmentChallengeSelector.css';

export const AssessmentChallengeSelector = ({ groupId, onChallengeSelect }) => {
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINTS.GET_ASSESSMENT_CHALLENGES}/${groupId}`
        );
        setCategories(response.data);
      } catch (err) {
        setError('Failed to load assessment challenges');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchChallenges();
  }, [groupId]);

  if (loading) return <div>Loading assessment challenges...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="assessment-challenge-selector">
      <div className="categories-list">
        {Object.entries(categories).map(([category, challenges]) => (
          <div key={category} className="category-section">
            <h3>{category}</h3>
            <div className="challenges-grid">
              {challenges.map(challenge => (
                <div 
                  key={challenge.id} 
                  className="challenge-card"
                  onClick={() => onChallengeSelect(challenge)}
                >
                  <h4>{challenge.title}</h4>
                  <p>{challenge.description}</p>
                  <div className="challenge-metadata">
                    <span className="duration">
                      {challenge.duration_periods} {challenge.duration_type}
                    </span>
                    <span className="type">
                      {challenge.is_self_guided ? 'Self-guided' : 'Scheduled'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssessmentChallengeSelector;