import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
    BookOpen,     // For Catechism
    Book,        // For New Testament
    Cross,        // For Gospels 
    Scroll,       // For Old Testament
    Pen,          // For Papal Encyclicals
    Star,         // For Works by Saints
    Send,         // Keep existing
    MessageCircle, // Keep existing if needed
    HelpCircle,   // Keep existing if needed
    ChevronDown,  // Keep existing if needed
    Menu,          // Keep existing if needed
    Users,
    Check,
    Globe
} from 'lucide-react';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import AssessmentModal from '../../components/AssessmentModal/AssessmentModal';
import { API_ENDPOINTS } from '../../config/api';
import axios from 'axios';
import './Chat.css';


const SPIRITUAL_AREAS = {
    CATECHISM: {
        icon: <BookOpen className="w-4 h-4" />,
        label: 'Catechism',
        description: 'Understand the core teachings of the Catholic faith',
        suggestedPrompts: [
            "How can I better understand the role of prayer in my daily life through the Catechism?",
            "What does the Catechism teach about finding God's will in our decisions?",
            "How can I grow in virtue according to Church teaching?"
        ],
        subTopics: {
            'faith_and_creed': {
                baseQuestion: "How do you personally connect with the truths in the Apostles' Creed?",
                followUps: [
                    "Which aspect of God - Father, Son, or Holy Spirit - do you find most challenging to relate to and why?",
                    "How does believing in the communion of saints affect your daily life?",
                    "What does it mean to you personally that God is both Creator and Father?"
                ]
            },
            'sacramental_life': {
                baseQuestion: "Which sacrament has had the most impact on your spiritual journey?",
                followUps: [
                    "How has regular reception of the Eucharist changed your relationship with Christ?",
                    "What role does Reconciliation play in your spiritual growth?",
                    "How do you prepare yourself spiritually before receiving the sacraments?"
                ]
            },
            'moral_teaching': {
                baseQuestion: "How do you apply Catholic moral teachings in challenging real-life situations?",
                followUps: [
                    "When have you struggled to follow Church teaching, and how did you work through it?",
                    "How do you discern between genuine conscience formation and rationalization?",
                    "What virtues do you find most challenging to develop?"
                ]
            }
        }
    },
    NEW_TESTAMENT: {
        icon: <Book className="w-4 h-4" />,
        label: 'New Testament',
        description: 'Dive into the writings of the early Church and Apostles',
        suggestedPrompts: [
            "How do the letters of Saint Paul guide us in modern spiritual challenges?",
            "What can we learn from the early Church in Acts about building community?",
            "How does Revelation's message of hope apply to our daily struggles?"
        ],
        subTopics: {
            'pauline_letters': {
                baseQuestion: "Which of Saint Paul's teachings resonates most with your current life situation?",
                followUps: [
                    "How does Paul's teaching on the 'body of Christ' affect how you view Church community?",
                    "What challenges do you face in 'putting on the armor of God' (Ephesians 6)?",
                    "How do you apply Paul's teachings on love (1 Corinthians 13) in difficult relationships?"
                ]
            },
            'acts_apostles': {
                baseQuestion: "What aspects of the early Church community inspire you most?",
                followUps: [
                    "How can we mirror the early Christians' devotion to prayer in modern life?",
                    "What lessons from the apostles' evangelization apply to sharing faith today?",
                    "How does the Holy Spirit's role in Acts guide your understanding of spiritual growth?"
                ]
            }
        }
    },
    GOSPELS: {
        icon: <Cross className="w-4 h-4" />,
        label: 'Gospels',
        description: 'Explore the life and teachings of Jesus Christ',
        suggestedPrompts: [
            "How do the Beatitudes challenge and guide your daily choices?",
            "What does Jesus's prayer life teach us about our own?",
            "How can we live the Sermon on the Mount in today's world?"
        ],
        subTopics: {
            'beatitudes': {
                baseQuestion: "Which Beatitude challenges you most in your current life situation?",
                followUps: [
                    "How do you strive to be a peacemaker in your daily interactions?",
                    "What does being 'poor in spirit' mean in your life?",
                    "How do you maintain meekness while standing firm in faith?"
                ]
            },
            'parables': {
                baseQuestion: "Which parable speaks most directly to your current spiritual challenges?",
                followUps: [
                    "How does the Prodigal Son story reflect your relationship with God?",
                    "What role do you usually see yourself playing in the Good Samaritan parable?",
                    "How does the parable of the talents challenge your use of God's gifts?"
                ]
            },
            'sermon_mount': {
                baseQuestion: "How do you apply the Sermon on the Mount's teachings in daily life?",
                followUps: [
                    "What does 'turn the other cheek' mean in your personal relationships?",
                    "How do you practice Jesus's teachings on prayer in your daily routine?",
                    "What challenges do you face in loving your enemies?"
                ]
            }
        }
    },
    OLD_TESTAMENT: {
        icon: <Scroll className="w-4 h-4" />,
        label: 'Old Testament',
        description: 'Study the foundation of Gods covenant with humanity',
        suggestedPrompts: [
            "How do the Psalms help us express our emotions to God?",
            "What can we learn from the prophets about faithfulness in difficult times?",
            "How does wisdom literature guide our daily decisions?"
        ],
        subTopics: {
            'psalms': {
                baseQuestion: "Which psalm most closely expresses your current relationship with God?",
                followUps: [
                    "How do you use the psalms in your personal prayer?",
                    "When has a psalm particularly helped you through a difficult time?",
                    "How do you relate to David's expressions of both joy and lament?"
                ]
            },
            'wisdom_literature': {
                baseQuestion: "How does the wisdom literature guide your decision-making?",
                followUps: [
                    "What lessons from Job help you during times of suffering?",
                    "How do you apply Proverbs' practical wisdom in daily life?",
                    "What does Ecclesiastes teach you about life's meaning?"
                ]
            }
        }
    },
    PAPAL_ENCYCLICALS: {
        icon: <Pen className="w-4 h-4" />,
        label: 'Papal Encyclicals',
        description: 'Understand the guidance of the Church through papal writings',
        suggestedPrompts: [
            "How does Laudato Si challenge our relationship with creation?",
            "What does Evangelium Vitae teach about respecting life in all situations?",
            "How can Fratelli Tutti guide our approach to building community?"
        ],
        subTopics: {
            'social_doctrine': {
                baseQuestion: "How do you apply Catholic social teaching in your daily choices?",
                followUps: [
                    "How has Laudato Si influenced your lifestyle choices?",
                    "What challenges do you face in promoting human dignity in your work?",
                    "How do you practice solidarity in your community?"
                ]
            },
            'faith_morals': {
                baseQuestion: "How do papal teachings help you navigate moral challenges?",
                followUps: [
                    "How do you apply Humanae Vitae's teachings on human dignity?",
                    "What aspects of Veritatis Splendor challenge your understanding of truth?",
                    "How does Evangelium Vitae guide your respect for life?"
                ]
            }
        }
    },
    WORKS_BY_SAINTS: {
        icon: <Star className="w-4 h-4" />,
        label: 'Works by Saints',
        description: 'Learn from the spiritual wisdom of the saints',
        suggestedPrompts: [
            "How can Saint Augustine's journey guide our own conversion?",
            "What does Saint Thérèse's 'little way' teach us about holiness in ordinary life?",
            "How does Saint Thomas Aquinas help us understand faith and reason?"
        ],
        subTopics: {
            'mystical_writings': {
                baseQuestion: "Which saint's spiritual journey most resembles your own?",
                followUps: [
                    "How does St. John of the Cross's Dark Night concept relate to your spiritual struggles?",
                    "What aspects of St. Teresa of Avila's Interior Castle resonate with your prayer life?",
                    "How do you apply St. Thérèse's 'little way' in daily life?"
                ]
            },
            'theological_works': {
                baseQuestion: "How has St. Thomas Aquinas's thinking influenced your understanding of God?",
                followUps: [
                    "Which of Aquinas's Five Ways to prove God's existence speaks to you most?",
                    "How do you balance faith and reason in your spiritual life?",
                    "What have you learned from St. Augustine's Confessions about your own journey?"
                ]
            }
        }
    }
};


const Chat = () => {
    // Existing state
    const { userId, tempUserId, isAuthorized } = useSelector(state => state.user);
    const effectiveUserId = userId || tempUserId;
    const isUnregisteredUser = effectiveUserId > 4000;
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedArea, setSelectedArea] = useState(null);
    const [showWelcome, setShowWelcome] = useState(true);
    const [spiritualProfile, setSpiritualProfile] = useState(null);
    const [recentTopics, setRecentTopics] = useState([]);
    const [showAssessment, setShowAssessment] = useState(false);
    const [isFirstVisit, setIsFirstVisit] = useState(false);
    const [showTooltip, setShowTooltip] = useState({ id: null, show: false });
    const [topicResponses, setTopicResponses] = useState({});
    const [currentSubTopic, setCurrentSubTopic] = useState(null);

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedGroupName, setSelectedGroupName] = useState('General');

    // New streaming state
    const [streamingResponse, setStreamingResponse] = useState(null);
    const [currentResponse, setCurrentResponse] = useState({
        understanding: "",
        guidance: "",
        scripture: "",
        prayer: "",
        next_steps: [],
        questions: [],
        context_tags: {}
    });
    
    const chatRef = useRef(null);
    const eventSourceRef = useRef(null);

    // Existing assessment check effect
    useEffect(() => {
        const checkAssessmentStatus = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.ASSESSMENT_STATUS);
                const { needsAssessment, isFirst } = response.data;
                setShowAssessment(needsAssessment);
                setIsFirstVisit(isFirst);
            } catch (error) {
                console.error('Error checking assessment status:', error);
            }
        };

        checkAssessmentStatus();
    }, []);

    // Existing user context effect
    useEffect(() => {
        const fetchUserContext = async () => {
            try {
                const [profileRes, topicsRes] = await Promise.all([
                    axios.get(API_ENDPOINTS.SPIRITUAL_PROFILE),
                    axios.get(API_ENDPOINTS.RECENT_DIRECTION_TOPICS)
                ]);
                
                setSpiritualProfile(profileRes.data);
                setRecentTopics(topicsRes.data);
            } catch (error) {
                console.error('Error fetching user context:', error);
            }
        };

        fetchUserContext();
    }, []);

    // Modified scroll effect to handle streaming
    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages, currentResponse]);


    useEffect(() => {
        const fetchUserGroups = async () => {
            if (effectiveUserId) {
                try {
                    const url = `${API_ENDPOINTS.GET_GROUPS}?user_id=${effectiveUserId}`;
                    const response = await axios.get(url);
                    // Access groups and default_group_id from response structure
                    const memberGroups = response.data.groups.filter(group => group.is_member);
                    setUserGroups(memberGroups);
                    
                    // Set default group if exists
                    if (response.data.default_group_id) {
                        setSelectedGroupId(response.data.default_group_id);
                        const defaultGroup = memberGroups.find(g => g.id === response.data.default_group_id);
                        if (defaultGroup) {
                            setSelectedGroupName(defaultGroup.name);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user groups:', error);
                }
            }
        };
        fetchUserGroups();
    }, [effectiveUserId]);



    // Add click outside handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.group-selector')) return;
            if (isSettingsOpen && !event.target.closest('.group-dropdown')) {
                setIsSettingsOpen(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isSettingsOpen]);

    // Add this handler
    const handleGroupChange = (groupId) => {
        if (groupId === '') {
            setSelectedGroupId(null);
            setSelectedGroupName('General');
        } else {
            const selectedGroup = userGroups.find(g => g.id.toString() === groupId);
            if (selectedGroup) {
                setSelectedGroupId(Number(groupId));
                setSelectedGroupName(selectedGroup.name);
            }
        }
        setIsSettingsOpen(false);
    };


    // Cleanup effect for EventSource
    useEffect(() => {
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
        };
    }, []);

    const handleAssessmentComplete = async (assessmentData) => {
        try {
            await axios.post(API_ENDPOINTS.SUBMIT_ASSESSMENT, assessmentData);
            setShowAssessment(false);
            const profileRes = await axios.get(API_ENDPOINTS.SPIRITUAL_PROFILE);
            setSpiritualProfile(profileRes.data);
        } catch (error) {
            console.error('Error submitting assessment:', error);
        }
    };


    // Also add this style for streaming content:
    const streamingStyles = {
        '.structured-guidance.streaming .guidance-section': {
            opacity: 1,
            transform: 'translateY(0)',
            transition: 'all 0.3s ease-in-out'
        },
        '.guidance-section:not(:first-child)': {
            marginTop: '1rem'
        },
        '.guidance-section.understanding.stream-active': {
            backgroundColor: 'var(--background-secondary)',
            padding: '1rem',
            borderRadius: '0.5rem',
            borderLeft: '4px solid var(--primary-color)'
        },
        '.guidance-section.scripture.stream-active': {
            backgroundColor: '#fffbeb',
            padding: '1rem',
            borderRadius: '0.5rem',
            borderLeft: '4px solid var(--warning-color)'
        }
    };


    const handleSubmit = async (e) => {
        // Prevent default form submission
        e.preventDefault();

        // Check if submission is valid
        if (!inputText.trim() || isLoading || !effectiveUserId) return;

        try {
            // Set loading state and add user message
            setIsLoading(true);
            setMessages((prev) => [
                ...prev,
                { type: "user", content: inputText },
            ]);

            // Store message and clear input
            const userMessage = inputText;
            setInputText("");
            setCurrentResponse("");

            // Construct request body
            const requestBody = {
                user_id: effectiveUserId,
                message: userMessage,
                category: selectedArea,
                context: {
                    recentTopics,
                    spiritualProfile,
                    topic_context: topicResponses[selectedArea] || {},
                    current_topic: selectedArea,
                    current_sub_topic: currentSubTopic,
                },
            };

            // Conditionally add group_id
            if (selectedGroupId) {
                requestBody.group_id = selectedGroupId;
            }

            // Make API request
            const response = await fetch(API_ENDPOINTS.SCRIPTURE_CHAT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            // Set up stream reading
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedText = "";
            let contextTags = "";

            // Process the stream
            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split("\n").filter((line) => line.trim());

                for (const line of lines) {
                    try {
                        // Skip non-data lines
                        if (!line.startsWith("data: ")) continue;

                        // Parse the JSON data
                        const data = JSON.parse(line.slice(6));
                        console.log("Received stream data:", data);

                        // Handle errors
                        if (data.error) {
                            setMessages((prev) => [
                                ...prev,
                                { type: "error", content: data.error },
                            ]);
                            setIsLoading(false);
                            return;
                        }

                        if (data.type === "content") {
                            // Handle content with optional context tags
                            if (data.content.includes("<context_tags>") || contextTags) {
                                contextTags += data.content;
                                if (data.content.includes("</context_tags>")) {
                                    contextTags = "";
                                }
                            } else {
                                const chunk = data.content.trim();

                                if (chunk.startsWith("# ")) {
                                    // Title handling
                                    accumulatedText += (accumulatedText ? "\n\n" : "") + chunk + "\n";
                                } else if (chunk.startsWith("* ")) {
                                    // Bullet points
                                    accumulatedText += (accumulatedText ? "\n" : "") + chunk;
                                } else if (chunk.startsWith("> ")) {
                                    // Quote handling
                                    accumulatedText += "\n" + chunk + "\n";
                                } else if (chunk.startsWith("<?>")) {
                                    // Question handling
                                    accumulatedText += "\n\n" + chunk.replace(/<\?>/g, "").trim();
                                } else {
                                    // Regular text
                                    accumulatedText += (accumulatedText.endsWith("\n") ? "" : " ") + chunk;
                                }

                                setCurrentResponse(accumulatedText);
                            }
                        } else if (data.type === "complete") {
                            // Handle completion
                            setMessages((messages) => [
                                ...messages,
                                { type: "director", content: accumulatedText },
                            ]);

                            // Process context tags if present
                            if (data.content?.context_tags) {
                                setTopicResponses((topicPrev) => ({
                                    ...topicPrev,
                                    [selectedArea]: {
                                        ...topicPrev[selectedArea],
                                        [data.content.context_tags.sub_topic]: {
                                            response: data.content.context_tags.content,
                                            timestamp: new Date().toISOString(),
                                            isFollowup: data.content.context_tags.first_or_followup === "FOLLOWUP",
                                        },
                                    },
                                }));
                            }

                            // Reset states
                            setIsLoading(false);
                            setCurrentResponse("");
                            return;
                        }
                    } catch (e) {
                        console.error("Error processing stream data:", e);
                    }
                }
            }
        } catch (error) {
            // Handle any errors
            console.error("Error:", error);
            setMessages((prev) => [
                ...prev,
                { type: "error", content: "An error occurred. Please try again." },
            ]);
            setIsLoading(false);
        }
    };




    // Also add this helper function:
    const parseStreamContent = (content) => {
        if (typeof content !== 'string') return content;
        
        try {
            return JSON.parse(content);
        } catch (e) {
            return content;
        }
    };


    const renderGuidanceContent = (content) => {
        if (!content) return null;
        
        // Split by double newlines to separate major sections
        const sections = content.split(/\n\n+/).filter(section => section.trim());
        const formatted = [];
        let currentList = [];
        
        sections.forEach((section, index) => {
            const trimmedSection = section.trim();
            const lines = trimmedSection.split('\n');
            
            lines.forEach((line, lineIndex) => {
                const trimmedLine = line.trim();
                
                if (trimmedLine.startsWith('# ')) {
                    // Handle main titles - always as separate elements
                    if (currentList.length > 0) {
                        formatted.push(
                            <ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>
                        );
                        currentList = [];
                    }
                    formatted.push(
                        <h2 key={`title-${index}-${lineIndex}`}>
                            {trimmedLine.replace('# ', '')}
                        </h2>
                    );
                } else if (trimmedLine.startsWith('* ')) {
                    // Add to current list
                    currentList.push(
                        <li key={`item-${index}-${lineIndex}`}>
                            {trimmedLine.replace('* ', '')}
                        </li>
                    );
                } else if (trimmedLine.startsWith('> ')) {
                    // Handle quotes
                    if (currentList.length > 0) {
                        formatted.push(
                            <ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>
                        );
                        currentList = [];
                    }
                    formatted.push(
                        <blockquote key={`quote-${index}-${lineIndex}`}>
                            {trimmedLine.replace('> ', '')}
                        </blockquote>
                    );
                } else if (trimmedLine && !trimmedLine.includes('<context_tags>')) {
                    // Handle regular text
                    if (currentList.length > 0) {
                        formatted.push(
                            <ul key={`list-${index}-${lineIndex}`}>{currentList}</ul>
                        );
                        currentList = [];
                    }
                    formatted.push(
                        <p key={`text-${index}-${lineIndex}`}>{trimmedLine}</p>
                    );
                }
            });
        });

        // Add any remaining list items
        if (currentList.length > 0) {
            formatted.push(<ul key="final-list">{currentList}</ul>);
        }

        return <div className="guidance-content">{formatted}</div>;
    };


    const renderMessage = (message) => {
        switch (message.type) {
            case 'welcome':
                return (
                    <div className="welcome-message">
                        <h3>Catholic Scripture Guidance</h3>
                        <p>
                            Share your thoughts, questions, or reflections about any Scripture. 
                            Think of this as a discussion with a spiritual director helping you understand the meaning and relevance of Catholic teachings.
                        </p>
                    </div>
                );

            case 'user':
                return (
                    <div className="message-bubble user">
                        {message.content}
                    </div>
                );

            case 'director':
                return (
                    <div className="structured-guidance">
                        {renderGuidanceContent(message.content)}
                    </div>
                );

            case 'error':
                return (
                    <div className="error-message">
                        {message.content}
                    </div>
                );

            default:
                return null;
        }
    };



    const handleAreaSelect = (area) => {
        setSelectedArea(area);
        if (SPIRITUAL_AREAS[area]?.suggestedPrompts?.length > 0) {
            const randomPrompt = SPIRITUAL_AREAS[area].suggestedPrompts[
                Math.floor(Math.random() * SPIRITUAL_AREAS[area].suggestedPrompts.length)
            ];
            setInputText(randomPrompt);
        }
    };


    return (
        <div className="page-container">
            <Header />
            <Navbar />
            
            <div className="main-content">
                <div className="direction-content">
                    <div className="direction-scrollable">
                        <div className="direction-chat">
                            {/* Group Selector - New Addition */}
                            <div className="group-navigation">
                                <button 
                                    className="group-selector"
                                    onClick={() => setIsSettingsOpen(!isSettingsOpen)}
                                    aria-label="Select chat group"
                                >
                                    <Users size={16} className="group-icon" />
                                    <span className="group-name">{selectedGroupName}</span>
                                    <ChevronDown 
                                        size={12} 
                                        className={`chevron ${isSettingsOpen ? 'rotated' : ''}`}
                                    />
                                </button>
                                
                                {isSettingsOpen && (
                                    <div className="group-dropdown">
                                        <div className="dropdown-header">
                                            Select Chat Group
                                        </div>
                                        <div 
                                            className={`group-option ${!selectedGroupId ? 'selected' : ''}`}
                                            onClick={() => handleGroupChange('')}
                                        >
                                            <Globe size={14} />
                                            <span>General Chat</span>
                                            {!selectedGroupId && <Check className="check-icon" size={12} />}
                                        </div>
                                        {userGroups.map(group => (
                                            <div 
                                                key={group.id}
                                                className={`group-option ${selectedGroupId === group.id ? 'selected' : ''}`}
                                                onClick={() => handleGroupChange(group.id.toString())}
                                            >
                                                <Users size={14} />
                                                <span>{group.name}</span>
                                                {selectedGroupId === group.id && <Check className="check-icon" size={12} />}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {/* Topic Selection Header */}
                            <div className="direction-header">
                                <div className="spiritual-areas">
                                    {Object.entries(SPIRITUAL_AREAS).map(([key, area]) => (
                                        <div 
                                            key={key} 
                                            className="area-button-container"
                                            onMouseEnter={() => setShowTooltip({ id: key, show: true })}
                                            onMouseLeave={() => setShowTooltip({ id: null, show: false })}
                                        >
                                            <button
                                                className={`area-button ${selectedArea === key ? 'selected' : ''}`}
                                                onClick={() => handleAreaSelect(key)}
                                                aria-label={area.label}
                                                title={area.description}
                                            >
                                                {area.icon}
                                                <span>{area.label}</span>
                                            </button>
                                            
                                            {showTooltip.id === key && showTooltip.show && (
                                                <div className="area-tooltip" role="tooltip">
                                                    {area.description}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Chat Messages Area */}
                            <div 
                                className="direction-messages" 
                                ref={chatRef}
                                role="log"
                                aria-live="polite"
                            >
                                {/* Welcome Message */}
                                {showWelcome && renderMessage({ type: 'welcome' })}
                                
                                {/* Existing Messages */}
                                {messages.map((message, index) => (
                                    <div 
                                        key={index} 
                                        className={`message ${message.type}`}
                                        role={message.type === 'user' ? 'note' : 'article'}
                                    >
                                        {renderMessage(message)}
                                    </div>
                                ))}
                                
                                {/* Updated Streaming Response */}
                                {isLoading && currentResponse && (
                                    <div className="message director" role="article">
                                        <div className="structured-guidance streaming">
                                            {renderGuidanceContent(currentResponse)}
                                            <div className="typing-indicator" aria-label="Director is typing">
                                                <div className="dots">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                                <span className="sr-only">
                                                    Spiritual director is composing a response
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {/* Updated Loading Indicator */}
                                {isLoading && !currentResponse && (
                                    <div className="loading-indicator" role="status" aria-label="Loading response">
                                        <div className="loading-dots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <span>Reflecting on your message...</span>
                                    </div>
                                )}
                            </div>

                            {/* Input Form */}
                            <form 
                                onSubmit={handleSubmit} 
                                className="direction-input"
                                role="form"
                            >
                                <div className="input-wrapper">
                                    <textarea
                                        value={inputText}
                                        onChange={(e) => setInputText(e.target.value)}
                                        placeholder="Share your thoughts, questions, or concerns..."
                                        rows="3"
                                        className="input-textarea"
                                        disabled={isLoading}
                                        aria-label="Message input"
                                        role="textbox"
                                        aria-multiline="true"
                                    />
                                    <button
                                        type="submit"
                                        disabled={isLoading || !inputText.trim()}
                                        className="send-button"
                                        aria-label="Send message"
                                    >
                                        <Send className="send-icon" aria-hidden="true" />
                                        <span>Send</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Assessment Modal */}
            {showAssessment && (
                <AssessmentModal
                    onSubmit={handleAssessmentComplete}
                    onClose={() => setShowAssessment(false)}
                    isFirstVisit={isFirstVisit}
                />
            )}
        </div>
    );

};

export default Chat;