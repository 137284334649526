import React, { useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import './AssessmentChallengeCustomizer.css';

export const AssessmentChallengeCustomizer = ({ 
  challenge, 
  groupId, 
  onSubmit,
  onCancel 
}) => {
  const [customizations, setCustomizations] = useState({
    title: challenge.title,
    description: challenge.description,
    weekly_prompts: challenge.weekly_prompts,
    duration_type: challenge.duration_type,
    duration_periods: challenge.duration_periods,
    schedule_type: challenge.schedule_type,
    schedule_days: challenge.schedule_days,
    is_self_guided: challenge.is_self_guided,
    start_date: new Date().toISOString().split('T')[0]
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_ENDPOINTS.ADD_ASSESSMENT_CHALLENGE}/${groupId}`,
        {
          challenge_id: challenge.id,
          customizations
        }
      );
      onSubmit(response.data);
    } catch (err) {
      console.error('Error adding challenge:', err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="challenge-customizer">
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          value={customizations.title}
          onChange={e => setCustomizations({
            ...customizations,
            title: e.target.value
          })}
        />
      </div>

      <div className="form-group">
        <label>Description</label>
        <textarea
          value={customizations.description}
          onChange={e => setCustomizations({
            ...customizations,
            description: e.target.value
          })}
        />
      </div>

      <div className="form-group">
        <label>Weekly Prompts</label>
        {customizations.weekly_prompts.map((prompt, index) => (
          <textarea
            key={index}
            value={prompt}
            onChange={e => {
              const newPrompts = [...customizations.weekly_prompts];
              newPrompts[index] = e.target.value;
              setCustomizations({
                ...customizations,
                weekly_prompts: newPrompts
              });
            }}
          />
        ))}
      </div>

      <div className="form-group">
        <label>Start Date</label>
        <input
          type="date"
          value={customizations.start_date}
          onChange={e => setCustomizations({
            ...customizations,
            start_date: e.target.value
          })}
        />
      </div>

      <div className="form-group">
        <label>
          <input
            type="checkbox"
            checked={customizations.is_self_guided}
            onChange={e => setCustomizations({
              ...customizations,
              is_self_guided: e.target.checked
            })}
          />
          Self-guided Challenge
        </label>
      </div>

      <div className="form-actions">
        <button type="submit" className="primary-button">
          Add Challenge to Group
        </button>
        <button type="button" onClick={onCancel} className="secondary-button">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AssessmentChallengeCustomizer;