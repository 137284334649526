import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import { 
  FaCog, 
  FaTrophy, 
  FaComments, 
  FaUsers, 
  FaCoins, 
  FaLock, 
  FaGlobe, 
  FaUserPlus, 
  FaUserMinus, 
  FaLightbulb,
  FaChevronRight,
  FaEdit,
  FaCalendar,
  FaGraduationCap
} from 'react-icons/fa';
import { API_ENDPOINTS } from '../../config/api';
import './AdminPanel.css';
import ChallengeEditor from '../../components/ChallengeEditor/ChallengeEditor';
import AssessmentChallengeSelector from '../../components/AssessmentChallenge/AssessmentChallengeSelector';
import AssessmentChallengeCustomizer from '../../components/AssessmentChallenge/AssessmentChallengeCustomizer';

const AdminPanel = ({ groupId }) => {
  // Existing state
  const userId = useSelector(state => state.user.userId);

  const [chatOverride, setChatOverride] = useState('');
  const [currentChatOverride, setCurrentChatOverride] = useState('');

  const [memberEmail, setMemberEmail] = useState('');
  const [leaderPoints, setLeaderPoints] = useState(0);
  const [activeTab, setActiveTab] = useState('general');
  const [isPublic, setIsPublic] = useState(true);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [customChallengeTitle, setCustomChallengeTitle] = useState('');
  const [weeklyPrompts, setWeeklyPrompts] = useState(['', '', '', '']);
  const [existingChallenges, setExistingChallenges] = useState([]);
  const [samplePrompts, setSamplePrompts] = useState([
    { title: '', description: '' },
    { title: '', description: '' },
    { title: '', description: '' }
  ]);

  // New state for assessment challenges
  const [selectedAssessmentChallenge, setSelectedAssessmentChallenge] = useState(null);

  const [scheduleConfig, setScheduleConfig] = useState({
    durationType: 'weekly',
    numberOfPeriods: 4,
    scheduleType: 'weekly',
    scheduleDays: [],
    scheduleWeek: 0,
    startDate: new Date().toISOString().split('T')[0],
    isSelfGuided: false
  });

  const [chatInstructions, setChatInstructions] = useState({
    overview: '',
    openingInstructions: '',
    readingConnectionInstructions: '',
    spiritualGuidanceInstructions: '',
    challengeProgressInstructions: '',
    personalGrowthInstructions: '',
    questionInstructions: '',
    actionStepInstructions: '',
    recommendedPromptsInstructions: '',
  });

  useEffect(() => {
    fetchGroupSettings();
    fetchPendingRequests();
    fetchCurrentChatOverride();
    fetchCurrentSamplePrompts();
    fetchExistingChallenges();
  }, [groupId, userId]);

  useEffect(() => {
    console.log(`Initial window width: ${window.innerWidth}px`);
    console.log(`Initial window height: ${window.innerHeight}px`);

    const handleResize = () => {
      console.log(`Window width: ${window.innerWidth}px`);
      console.log(`Window height: ${window.innerHeight}px`);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const fetchCurrentSamplePrompts = async () => {
    try {
      const url = API_ENDPOINTS.GET_GROUP_SAMPLE_PROMPTS.replace(':groupId', groupId);
      console.log('Fetching sample prompts from:', url);
      const response = await axios.get(url);
      const fetchedPrompts = response.data.prompts;
      
      const filledPrompts = [
        ...fetchedPrompts,
        ...Array(3 - fetchedPrompts.length).fill({ title: '', description: '' })
      ].slice(0, 3);
      
      setSamplePrompts(filledPrompts);
    } catch (error) {
      console.error('Error fetching sample prompts:', error);
    }
  };


  // In handleDurationTypeChange
  const handleDurationTypeChange = (type) => {
      console.log('\n=== Duration Type Change ===');
      console.log('New Type:', type);
      console.log('Current Config:', scheduleConfig);
      
      const lowerType = type.toLowerCase();
      console.log('Normalized type:', lowerType);
      
      setScheduleConfig(prev => {
          const updated = {
              ...prev,
              durationType: lowerType,
              numberOfPeriods: lowerType === 'weekly' ? 4 : 3,
              scheduleType: lowerType,
              scheduleDays: []
          };
          console.log('Updated schedule config:', updated);
          return updated;
      });
      
      const newPeriodsCount = lowerType === 'weekly' ? 4 : 3;
      console.log('New periods count:', newPeriodsCount);
      
      setWeeklyPrompts(prev => {
          const newPrompts = Array(newPeriodsCount).fill('');
          const updated = prev.slice(0, newPeriodsCount).concat(newPrompts.slice(prev.length));
          console.log('Updated prompts:', updated);
          return updated;
      });
  };



  const handlePeriodCountChange = (count) => {
      console.log('\n=== Period Count Change ===');
      console.log('New count:', count);
      console.log('Current config:', scheduleConfig);

      // Allow empty string for clearing input
      if (count === '') {
          console.log('Clearing period count');
          setScheduleConfig(prev => ({
              ...prev,
              numberOfPeriods: ''
          }));
          return;
      }

      // Convert to number and validate
      const numCount = parseInt(count);
      if (isNaN(numCount)) {
          console.log('Invalid number:', count);
          return;
      }

      // Validate against max value
      const validCount = Math.min(Math.max(1, numCount), 24);
      console.log('Validated count:', validCount);
      
      setScheduleConfig(prev => {
          const updated = {
              ...prev,
              numberOfPeriods: validCount
          };
          console.log('Updated config:', updated);
          return updated;
      });

      // Update prompts array
      setWeeklyPrompts(prev => {
          const newPrompts = Array(validCount).fill('');
          for (let i = 0; i < Math.min(prev.length, validCount); i++) {
              newPrompts[i] = prev[i];
          }
          console.log('Updated prompts:', newPrompts);
          return newPrompts;
      });
  };


  const handleScheduleTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      scheduleType: type,
      scheduleDays: [],
      scheduleWeek: type === 'specific_weekday' ? 0 : null
    }));
  };

  const handleDaySelection = (day) => {
    setScheduleConfig(prev => {
      const newDays = prev.scheduleDays.includes(day)
        ? prev.scheduleDays.filter(d => d !== day)
        : [...prev.scheduleDays, day].sort();
      return { ...prev, scheduleDays: newDays };
    });
  };


  const fetchCurrentChatOverride = async () => {
    try {
      const url = API_ENDPOINTS.GET_CHAT_OVERRIDE.replace(':groupId', groupId);
      const response = await axios.get(url);
      setChatOverride(response.data.chat_override || '');
      setCurrentChatOverride(response.data.chat_override || '');
    } catch (error) {
      console.error('Error fetching chat instructions:', error);
    }
  };


  const fetchGroupSettings = async () => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_GROUP_SETTINGS.replace(':groupId', groupId)
      );
      setIsPublic(response.data.isPublic);
    } catch (error) {
      console.error('Error fetching group settings:', error);
    }
  };

  // Submit handler
  const handleChatOverrideSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        API_ENDPOINTS.UPDATE_GROUP_CHAT_OVERRIDE.replace(':groupId', groupId), 
        { chat_override: chatOverride.trim() }
      );
      
      alert('Chat instructions updated successfully');
      setCurrentChatOverride(chatOverride.trim());
    } catch (error) {
      console.error('Error updating chat instructions:', error);
      alert('Failed to update chat instructions');
    }
  };


  // New handler for assessment challenge selection
  const handleAssessmentChallengeSelect = (challenge) => {
    setSelectedAssessmentChallenge(challenge);
  };


  // New handler for assessment challenge submission
  const handleAssessmentChallengeSubmit = async (customizations) => {
    try {
      const response = await axios.post(
        `${API_ENDPOINTS.ADD_ASSESSMENT_CHALLENGE}/${groupId}`, 
        {
          challenge_id: selectedAssessmentChallenge.id,
          customizations
        }
      );

      alert('Assessment challenge added successfully');
      setSelectedAssessmentChallenge(null);
      fetchExistingChallenges();

      // Award points if configured
      const pointsResponse = await axios.post(
        API_ENDPOINTS.AWARD_LEADER_POINTS.replace(':userId', userId),
        { action: 'add_assessment_challenge' }
      );
      setLeaderPoints(pointsResponse.data.totalPoints);
    } catch (error) {
      console.error('Error adding assessment challenge:', error);
      alert('Failed to add assessment challenge');
    }
  };



  const handleSamplePromptChange = (index, field, value) => {
    const newPrompts = [...samplePrompts];
    newPrompts[index] = {
      ...newPrompts[index],
      [field]: value
    };
    setSamplePrompts(newPrompts);
  };

  const handleSamplePromptsSubmit = async (e) => {
    e.preventDefault();
    try {
      const validPrompts = samplePrompts.filter(p => p.title && p.description);
      const url = API_ENDPOINTS.UPDATE_GROUP_SAMPLE_PROMPTS.replace(':groupId', groupId);
      
      await axios.post(url, {
        prompts: validPrompts,
        user_id: userId
      });
      
      alert('Sample prompts updated successfully');
      fetchCurrentSamplePrompts();
      
      const pointsResponse = await axios.post(
        API_ENDPOINTS.AWARD_LEADER_POINTS.replace(':userId', userId), 
        { action: 'update_sample_prompts' }
      );
      setLeaderPoints(pointsResponse.data.totalPoints);
    } catch (error) {
      console.error('Error updating sample prompts:', error);
      alert('Failed to update sample prompts');
    }
  };

  const fetchPendingRequests = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.GROUPS}/${groupId}/pending-requests`);
      setPendingRequests(response.data);
    } catch (error) {
      console.error('Error fetching pending requests:', error);
    }
  };

  // In fetchExistingChallenges
  const fetchExistingChallenges = async () => {
      try {
          console.log('\n=== Fetching Existing Challenges ===');
          const url = API_ENDPOINTS.GROUP_CHALLENGES_LIST.replace(':groupId', groupId);
          console.log('Fetching from:', url);

          const response = await axios.get(url);
          console.log('Raw response data:', response.data);

          response.data.forEach(challenge => {
              console.log('\nChallenge details:', {
                  id: challenge.id,
                  title: challenge.title,
                  duration_type: challenge.duration_type,
                  schedule_type: challenge.schedule_type,
                  schedule_days: challenge.schedule_days,
                  schedule_week: challenge.schedule_week,
                  number_of_periods: challenge.duration_periods,
                  prompts_count: challenge.weekly_prompts?.length,
                  is_self_guided: challenge.is_self_guided,
                  start_date: challenge.start_date
              });
          });

          setExistingChallenges(response.data);
          console.log('Challenges updated in state');
      } catch (error) {
          console.error('Error fetching challenges:', error);
          console.error('Error details:', {
              message: error.message,
              response: error.response?.data
          });
      }
  };


  // Add logging to schedule configuration validation
  const validateScheduleConfig = (config) => {
      console.log('\n=== Validating Schedule Config ===');
      console.log('Config to validate:', config);

      if (!['weekly', 'monthly'].includes(config.durationType.toLowerCase())) {
          console.error('Invalid duration type:', config.durationType);
          throw new Error('Invalid duration type - must be weekly or monthly');
      }

      const maxPeriods = config.durationType === 'weekly' ? 52 : 12;
      console.log('Max periods allowed:', maxPeriods);
      console.log('Actual periods:', config.numberOfPeriods);

      if (config.numberOfPeriods < 1 || config.numberOfPeriods > maxPeriods) {
          console.error('Invalid number of periods:', config.numberOfPeriods);
          throw new Error(`Number of periods must be between 1 and ${maxPeriods} for ${config.durationType} challenges`);
      }

      if (!config.isSelfGuided && !config.startDate) {
          console.error('Missing start date for fixed schedule');
          throw new Error('Start date is required for fixed schedule challenges');
      }

      console.log('Schedule config validation passed');
      return true;
  };


  // In handleCustomChallengeSubmit
  const handleCustomChallengeSubmit = async (e) => {
      e.preventDefault();
      console.log('\n=== Submitting Custom Challenge ===');
      console.log('Current Schedule Config:', scheduleConfig);
      
      try {
          // Validate title
          if (!customChallengeTitle.trim()) {
              throw new Error('Challenge title is required');
          }
          console.log('Title validated:', customChallengeTitle);

          // Validate prompts
          const filteredPrompts = weeklyPrompts.filter(prompt => prompt.trim());
          console.log('Filtered prompts:', filteredPrompts);
          
          if (filteredPrompts.length === 0) {
              throw new Error('At least one prompt is required');
          }

          if (filteredPrompts.length !== scheduleConfig.numberOfPeriods) {
              throw new Error(`Please provide prompts for all ${scheduleConfig.numberOfPeriods} periods`);
          }
          console.log('Prompts validated:', {
              expected: scheduleConfig.numberOfPeriods,
              received: filteredPrompts.length
          });

          // Validate schedule configuration
          validateScheduleConfig(scheduleConfig);
          console.log('Schedule config validated');

          // Specific weekday validation
          if (!scheduleConfig.isSelfGuided && 
              scheduleConfig.scheduleType === 'specific_weekday' && 
              scheduleConfig.scheduleDays.length === 0) {
              throw new Error('Please select at least one day of the week');
          }

          // Prepare challenge data
          const challengeData = {
              title: customChallengeTitle,
              weeklyPrompts: filteredPrompts,
              user_id: userId,
              duration_type: scheduleConfig.durationType.toLowerCase(),
              duration_periods: scheduleConfig.numberOfPeriods,
              schedule_type: scheduleConfig.isSelfGuided ? 'self_guided' : scheduleConfig.scheduleType.toLowerCase(),
              schedule_days: scheduleConfig.scheduleDays,
              schedule_week: scheduleConfig.scheduleType === 'specific_weekday' ? scheduleConfig.scheduleWeek : null,
              start_date: scheduleConfig.startDate,
              is_self_guided: scheduleConfig.isSelfGuided
          };
          
          console.log('Challenge data being sent:', challengeData);

          // Submit the challenge
          const response = await axios.post(
              API_ENDPOINTS.CREATE_CUSTOM_GROUP_CHALLENGE.replace(':groupId', groupId),
              challengeData
          );
          
          console.log('API Response:', response.data);
          
          // Reset form
          setCustomChallengeTitle('');
          setWeeklyPrompts(Array(scheduleConfig.numberOfPeriods).fill(''));
          setScheduleConfig({
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: [],
              scheduleWeek: 0,
              startDate: new Date().toISOString().split('T')[0],
              isSelfGuided: false
          });
          
          console.log('Form reset complete');
          
          // Show success message and refresh challenges
          alert('Challenge created successfully');
          await fetchExistingChallenges();

          // Award points if configured
          if (response.data.points_earned) {
              const pointsResponse = await axios.post(
                  API_ENDPOINTS.AWARD_LEADER_POINTS.replace(':userId', userId),
                  { action: 'create_challenge' }
              );
              console.log('Points awarded:', pointsResponse.data);
          }
          
      } catch (error) {
          console.error('Error creating challenge:', error);
          console.error('Error details:', {
              message: error.message,
              response: error.response?.data,
              config: scheduleConfig
          });
          
          const errorMessage = error.response?.data?.error || 
                             error.message || 
                             'Failed to create challenge. Please try again.';
          
          alert(errorMessage);
      }
  };


  const handleInviteMember = async (e) => {
    e.preventDefault();
    try {
      const inviteEndpoint = API_ENDPOINTS.INVITE_TO_GROUP.replace(':groupId', groupId);
      const inviteLink = `${window.location.origin}/signup?groupId=${groupId}`;
      
      const response = await axios.post(inviteEndpoint, {
        email: memberEmail,
        user_id: userId,
        baseUrl: inviteLink
      });

      alert('Invitation sent successfully');
      setMemberEmail('');

      const pointsResponse = await axios.post(
        API_ENDPOINTS.AWARD_LEADER_POINTS.replace(':userId', userId),
        { action: 'invite_member' }
      );
      setLeaderPoints(pointsResponse.data.totalPoints);
    } catch (error) {
      console.error('Error inviting member:', error);
      alert(error.response?.data?.error || 'Failed to send invitation');
    }
  };

  const handlePrivacyToggle = async () => {
    try {
      await axios.put(`${API_ENDPOINTS.GROUPS}/${groupId}/privacy`, { isPublic: !isPublic });
      setIsPublic(!isPublic);
      alert('Group privacy setting updated successfully');
    } catch (error) {
      console.error('Error updating group privacy:', error);
    }
  };

  const handleMemberRequest = async (requestId, approve) => {
    try {
      await axios.post(`${API_ENDPOINTS.GROUPS}/${groupId}/member-request`, { requestId, approve });
      fetchPendingRequests();
      alert(`Member request ${approve ? 'approved' : 'rejected'} successfully`);
    } catch (error) {
      console.error('Error handling member request:', error);
    }
  };

  const handleWeeklyPromptChange = (index, value) => {
    const formattedValue = value.split('.').join('.\n\n').trim();
    const newPrompts = [...weeklyPrompts];
    newPrompts[index] = formattedValue;
    setWeeklyPrompts(newPrompts);
  };

  const renderScheduleConfiguration = () => (
    <div className="schedule-configuration">
      <div className="form-group">
        <label>Challenge Timing</label>
        <select
          value={scheduleConfig.isSelfGuided}
          onChange={(e) => setScheduleConfig(prev => ({
            ...prev,
            isSelfGuided: e.target.value === 'true'
          }))}
          className="schedule-select timing-select"
        >
          <option value="false">Fixed Schedule (Same dates for all participants)</option>
          <option value="true">Self-Guided (Starts when participant joins)</option>
        </select>
        {scheduleConfig.isSelfGuided && (
          <p className="timing-help-text">
            Each participant will receive prompts based on their join date
          </p>
        )}
      </div>

      <div className="form-group">
          <label>Duration Type</label>
          <select 
              value={scheduleConfig.durationType}
              onChange={(e) => handleDurationTypeChange(e.target.value)}
              className="schedule-select"
              disabled={scheduleConfig.isSelfGuided}
          >
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
          </select>
      </div>

      <div className="form-group">

        <label>Number of {scheduleConfig.durationType === 'weekly' ? 'Weeks' : 'Months'}</label>
        <input
          type="number"
          min="1"
          max="24"
          value={scheduleConfig.numberOfPeriods}
          onChange={(e) => handlePeriodCountChange(e.target.value)}
          className="schedule-input"
          required
        />
      </div>

      {!scheduleConfig.isSelfGuided && (
        <>
          <div className="form-group">
            <label>Schedule Type</label>
            <select
              value={scheduleConfig.scheduleType}
              onChange={(e) => handleScheduleTypeChange(e.target.value)}
              className="schedule-select"
            >
              {scheduleConfig.durationType === 'weekly' ? (
                <>
                  <option value="weekly">Regular Weekly</option>
                  <option value="specific_weekday">Specific Days</option>
                </>
              ) : (
                <>
                  <option value="monthly">Regular Monthly</option>
                  <option value="specific_date">Specific Date</option>
                  <option value="specific_weekday">Specific Weekday</option>
                </>
              )}
            </select>
          </div>

          {scheduleConfig.scheduleType === 'specific_weekday' && (
            <div className="weekday-selection">
              {scheduleConfig.durationType === 'monthly' && (
                <div className="form-group">
                  <label>Week of Month</label>
                  <select
                    value={scheduleConfig.scheduleWeek}
                    onChange={(e) => setScheduleConfig(prev => ({
                      ...prev,
                      scheduleWeek: parseInt(e.target.value)
                    }))}
                    className="schedule-select"
                  >
                    <option value="0">First</option>
                    <option value="1">Second</option>
                    <option value="2">Third</option>
                    <option value="3">Fourth</option>
                    <option value="-1">Last</option>
                  </select>
                </div>
              )}
              <div className="weekday-buttons">
                {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, idx) => (
                  <button
                    key={idx}
                    type="button"
                    className={`weekday-button ${scheduleConfig.scheduleDays.includes(idx) ? 'selected' : ''}`}
                    onClick={() => handleDaySelection(idx)}
                  >
                    {day}
                  </button>
                ))}
              </div>
            </div>
          )}

          {scheduleConfig.scheduleType === 'specific_date' && (
            <div className="date-selection">
              <label>Select Date(s) of Month</label>
              <div className="date-grid">
                {Array.from({length: 31}, (_, i) => i + 1).map(date => (
                  <button
                    key={date}
                    type="button"
                    className={`date-button ${scheduleConfig.scheduleDays.includes(date) ? 'selected' : ''}`}
                    onClick={() => handleDaySelection(date)}
                  >
                    {date}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              value={scheduleConfig.startDate}
              onChange={(e) => setScheduleConfig(prev => ({
                ...prev,
                startDate: e.target.value
              }))}
              min={new Date().toISOString().split('T')[0]}
              className="schedule-input"
            />
          </div>
        </>
      )}

      {scheduleConfig.isSelfGuided && (
        <div className="form-group">
          <label>Challenge Start Date</label>
          <input
            type="date"
            value={scheduleConfig.startDate}
            onChange={(e) => setScheduleConfig(prev => ({
              ...prev,
              startDate: e.target.value
            }))}
            min={new Date().toISOString().split('T')[0]}
            className="schedule-input"
          />
          <p className="schedule-help-text">
            This is when the challenge becomes available for participants to join
          </p>
        </div>
      )}
    </div>
  );

  const tabs = [
    { id: 'general', icon: FaCog, label: 'Customize AI Responses' },
    { id: 'challenges', icon: FaTrophy, label: 'Challenges' },
    { id: 'assessment-library', icon: FaGraduationCap, label: 'Assessment Library' },
    { id: 'members', icon: FaUsers, label: 'Members Management' }
  ];

  return (
    <div className="admin-panel-container">
      <Header />
      <div className="admin-panel-content">
        <Navbar />
        <div className="admin-panel-main">
          <div className="admin-header">
            <h2><FaCog /> Admin Panel</h2>
            <div className="admin-breadcrumb">
              <span>Dashboard</span>
              <FaChevronRight />
              <span>{tabs.find(tab => tab.id === activeTab)?.label}</span>
            </div>
          </div>

          <div className="admin-layout">
            <div className="admin-sidebar">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`admin-nav-button ${activeTab === tab.id ? 'active' : ''}`}
                >
                  <tab.icon />
                  <span>{tab.label}</span>
                </button>
              ))}
            </div>

            <div className="admin-main-content">
              {activeTab === 'general' && (
                <div className="admin-section">                
                  <div className="admin-card instruction-card">
                    <h3><FaComments /> Instructions For Spiritual Direction</h3>
                    <form onSubmit={handleChatOverrideSubmit} className="chat-override-form">
                      <div className="instruction-section">
                        <h4>Chat Instructions</h4>
                        <p className="instruction-description">
                          Specify instructions for how the AI should respond to users.
                        </p>
                        <textarea
                          value={chatOverride}
                          onChange={(e) => setChatOverride(e.target.value)}
                          placeholder="Enter chat instructions..."
                          className="instruction-input"
                          rows={10}
                        />
                      </div>

                      <button type="submit" className="primary-button">
                        Update Chat Instructions
                      </button>
                    </form>

                    {currentChatOverride && (
                      <div className="current-override">
                        <h4>Current Instructions</h4>
                        <div className="override-display">
                          {currentChatOverride}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="admin-card">
                    <h3><FaLightbulb /> Sample Prompts</h3>
                    <form onSubmit={handleSamplePromptsSubmit} className="sample-prompts-form">
                      <div className="prompt-description">
                        Configure up to 3 sample prompts that group members can see in their chat
                      </div>
                      {samplePrompts.map((prompt, index) => (
                        <div key={index} className="sample-prompt-input">
                          <div className="prompt-header">
                            <div className="prompt-number">Prompt {index + 1}</div>
                          </div>
                          <input
                            type="text"
                            value={prompt.title}
                            onChange={(e) => handleSamplePromptChange(index, 'title', e.target.value)}
                            placeholder={`Enter prompt ${index + 1} title`}
                            className="sample-prompt-title"
                          />
                          <textarea
                            value={prompt.description}
                            onChange={(e) => handleSamplePromptChange(index, 'description', e.target.value)}
                            placeholder={`Enter detailed description for prompt ${index + 1}`}
                            className="sample-prompt-description"
                          />
                        </div>
                      ))}
                      <button type="submit" className="primary-button">
                        <FaLightbulb /> Update Sample Prompts
                      </button>
                    </form>
                  </div>
                </div>
              )}

              {activeTab === 'challenges' && (
                <div className="admin-section">
                  <div className="admin-card">
                    <h3><FaTrophy /> Create New Challenge</h3>
                    <form onSubmit={handleCustomChallengeSubmit} className="challenge-form">
                      <div className="form-group">
                        <label>Challenge Title</label>
                        <input
                          type="text"
                          value={customChallengeTitle}
                          onChange={(e) => setCustomChallengeTitle(e.target.value)}
                          placeholder="Enter challenge title"
                          required
                          className="challenge-title-input"
                        />
                      </div>
                      
                      {renderScheduleConfiguration()}
                      
                      <div className="weekly-prompts-container">
                        {Array.from({ length: scheduleConfig.numberOfPeriods }).map((_, index) => (
                          <div key={index} className="form-group">
                            <label>
                              {scheduleConfig.durationType === 'weekly' ? 'Week' : 'Month'} {index + 1} Prompt
                            </label>
                            <textarea
                              value={weeklyPrompts[index] || ''}
                              onChange={(e) => handleWeeklyPromptChange(index, e.target.value)}
                              placeholder={`Enter prompt for ${scheduleConfig.durationType === 'weekly' ? 'week' : 'month'} ${index + 1}`}
                              required
                              className="weekly-prompt-input"
                            />
                          </div>
                        ))}
                      </div>
                      
                      <button type="submit" className="primary-button">
                        <FaTrophy /> Create Challenge
                      </button>
                    </form>
                  </div>

                  <div className="admin-card">
                    <h3><FaEdit /> Edit Existing Challenges</h3>
                    <div className="existing-challenges">
                      {existingChallenges.map(challenge => (
                        <div key={challenge.id} className="challenge-card">
                          <div className="challenge-header">
                            <div className="challenge-title-row">
                              <h4>{challenge.title}</h4>
                            </div>
                            <div className="challenge-timing">
                              <FaCalendar className="timing-icon" />
                              <div className="timing-details">
                                <span className="timing-type">
                                  {challenge.is_self_guided ? (
                                    'Self-Guided Challenge'
                                  ) : (
                                    `Fixed Schedule - ${challenge.duration_type === 'weekly' ? 'Weekly' : 'Monthly'}`
                                  )}
                                </span>
                                {!challenge.is_self_guided && challenge.schedule_type && (
                                  <span className="schedule-details">
                                    {challenge.schedule_type === 'specific_weekday' ? (
                                      <>
                                        {challenge.schedule_week !== undefined && (
                                          `${challenge.schedule_week === -1 ? 'Last' : ['First', 'Second', 'Third', 'Fourth'][challenge.schedule_week]} `
                                        )}
                                        {Array.isArray(challenge.schedule_days) && challenge.schedule_days.length > 0 ? (
                                          challenge.schedule_days.map(day => 
                                            ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day]
                                          ).join(', ')
                                        ) : (
                                          'No days selected'
                                        )}
                                      </>
                                    ) : challenge.schedule_type === 'specific_date' ? (
                                      `Day${challenge.schedule_days?.length > 1 ? 's' : ''} ${challenge.schedule_days?.join(', ') || 'Not set'} of each month`
                                    ) : (
                                      `Every ${challenge.duration_type}`
                                    )}
                                    {challenge.start_date && (
                                      <span className="schedule-start-date">
                                        {' '}(Starting {new Date(challenge.start_date).toLocaleDateString()})
                                      </span>
                                    )}
                                  </span>
                                )}
                                {challenge.is_self_guided && (
                                  <span className="self-guided-note">
                                    Prompts start when participant joins
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <ChallengeEditor 
                            key={challenge.id}
                            groupId={groupId}
                            challenge={challenge}
                            onUpdate={fetchExistingChallenges}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* New Assessment Library Tab */}
              {activeTab === 'assessment-library' && (
                <div className="admin-section">
                  <div className="admin-card">
                    <h3>
                      <FaGraduationCap /> Assessment Challenge Library
                    </h3>
                    <p className="section-description">
                      Browse and add pre-designed spiritual growth challenges from our assessment library.
                    </p>

                    {selectedAssessmentChallenge ? (
                      <div className="challenge-customization">
                        <div className="selected-challenge-header">
                          <h4>Customize Challenge</h4>
                          <button
                            className="back-button"
                            onClick={() => setSelectedAssessmentChallenge(null)}
                          >
                            Back to Library
                          </button>
                        </div>
                        
                        <AssessmentChallengeCustomizer
                          challenge={selectedAssessmentChallenge}
                          groupId={groupId}
                          onSubmit={handleAssessmentChallengeSubmit}
                          onCancel={() => setSelectedAssessmentChallenge(null)}
                        />
                      </div>
                    ) : (
                      <AssessmentChallengeSelector
                        groupId={groupId}
                        onChallengeSelect={handleAssessmentChallengeSelect}
                      />
                    )}
                  </div>

                  {existingChallenges.filter(c => c.source_type === 'assessment_based').length > 0 && (
                    <div className="admin-card mt-6">
                      <h3>
                        <FaEdit /> Assessment-Based Challenges
                      </h3>
                      <div className="existing-challenges">
                        {existingChallenges
                          .filter(c => c.source_type === 'assessment_based')
                          .map(challenge => (
                            <div key={challenge.id} className="challenge-card">
                              <div className="challenge-header">
                                <div className="challenge-title-row">
                                  <h4>{challenge.title}</h4>
                                  <span className="assessment-badge">
                                    From Assessment Library
                                  </span>
                                </div>
                                <div className="challenge-timing">
                                  <FaCalendar className="timing-icon" />
                                  <div className="timing-details">
                                    <span className="timing-type">
                                      {challenge.is_self_guided ? (
                                        'Self-Guided Challenge'
                                      ) : (
                                        `Fixed Schedule - ${challenge.duration_type === 'weekly' ? 'Weekly' : 'Monthly'}`
                                      )}
                                    </span>
                                    {!challenge.is_self_guided && challenge.schedule_type && (
                                      <span className="schedule-details">
                                        {challenge.schedule_type === 'specific_weekday' ? (
                                          <>
                                            {challenge.schedule_week !== undefined && (
                                              `${challenge.schedule_week === -1 ? 'Last' : ['First', 'Second', 'Third', 'Fourth'][challenge.schedule_week]} `
                                            )}
                                            {Array.isArray(challenge.schedule_days) && challenge.schedule_days.length > 0 ? (
                                              challenge.schedule_days.map(day => 
                                                ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day]
                                              ).join(', ')
                                            ) : (
                                              'No days selected'
                                            )}
                                          </>
                                        ) : challenge.schedule_type === 'specific_date' ? (
                                          `Day${challenge.schedule_days?.length > 1 ? 's' : ''} ${challenge.schedule_days?.join(', ') || 'Not set'} of each month`
                                        ) : (
                                          `Every ${challenge.duration_type}`
                                        )}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <ChallengeEditor 
                                key={challenge.id}
                                groupId={groupId}
                                challenge={challenge}
                                onUpdate={fetchExistingChallenges}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {activeTab === 'members' && (
                <div className="admin-section">
                  <div className="admin-card">
                    <h3><FaUserPlus /> Invite New Member</h3>
                    <form onSubmit={handleInviteMember} className="invite-form">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          value={memberEmail}
                          onChange={(e) => setMemberEmail(e.target.value)}
                          placeholder="Enter email address"
                          required
                          className="email-input"
                        />
                        <p className="help-text">
                          An invitation email will be sent with a link to join the group.
                        </p>
                      </div>
                      <button type="submit" className="primary-button">
                        <FaUserPlus /> Send Invitation
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;