import React, { useState, useEffect } from "react";
import {
  FiX,
  FiArrowRight,
  FiCheck,
  FiLoader,
  FiHeart,
  FiAlertCircle,
} from "react-icons/fi";
import axios from "axios";
import { API_ENDPOINTS } from "../../config/api";
import "./AssessmentModal.css";

const assessmentSteps = [
  {
    id: "welcome",
    type: "welcome",
    title: "Welcome to Your Faith Journey",
    content:
      "Answer five assessment questions about your current situation. Each of the inputs needs to be at least 50 characters.",
    helper:
      "The AI responses will use your inputs to personalize it's spiritual guidance.",
  },
  {
    id: "life-situation",
    type: "combined",
    title: "Your Current Season of Life",
    question: "Tell us about what's happening in your life right now",
    helper:
      "You can start with a quick option below and then add more detail about your specific situation. The more context you provide, the better we can tailor our spiritual guidance to your daily life.",
    quickOptions: [
      { label: "Student", value: "I'm a student, " },
      { label: "Working Professional", value: "I'm a working professional, " },
      { label: "Parent", value: "I'm a parent, " },
      { label: "Other", value: "", isCustom: true },
    ],
    placeholder:
      "Share about your current life situation, responsibilities, and daily experiences...",
    minLength: 50,
  },
  {
    id: "faith-connection",
    type: "reflection",
    title: "Your Connection with God",
    question:
      "When do you feel closest to God? What brings you peace in your faith?",
    helper:
      "Your response helps us understand what spiritual practices resonate most with you, allowing us to provide more meaningful daily guidance.",
    placeholder: "I feel closest to God when...",
    minLength: 50,
    suggestedPrompts: [
      "During prayer or Mass...",
      "When I'm serving others...",
      "In moments of quiet...",
      "Through music or nature...",
    ],
  },
  {
    id: "faith-challenges",
    type: "reflection",
    title: "Your Faith Challenges",
    question: "What aspects of your faith journey do you find challenging?",
    helper:
      "Understanding your struggles helps us provide more relevant and supportive guidance in difficult moments.",
    placeholder: "I struggle with my faith when...",
    minLength: 50,
    suggestedPrompts: [
      "Prayer feels difficult when...",
      "I feel distant from God when...",
      "I find it challenging to...",
    ],
  },
  {
    id: "spiritual-goals",
    type: "reflection",
    title: "Your Spiritual Desires",
    question: "What would you like to develop in your spiritual life?",
    helper:
      "Your aspirations help us guide you toward meaningful growth in your faith journey.",
    placeholder: "I hope to grow in...",
    minLength: 50,
    suggestedPrompts: [
      "I want to deepen my prayer life by...",
      "I hope to better understand...",
      "I want to develop the habit of...",
    ],
  },
];

const AssessmentModal = ({ onSubmit, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [responses, setResponses] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputHeight, setInputHeight] = useState(120);

  const currentQuestion = assessmentSteps[currentStep];

  useEffect(() => {
    setInputHeight(120);
  }, [currentStep]);

  const handleTextInput = (value) => {
    setResponses((prev) => ({
      ...prev,
      [currentQuestion.id]: value,
    }));
  };

  const handleQuickOption = (option) => {
    setSelectedOption(option);
    const baseText = option.value;
    const currentText = responses[currentQuestion.id] || "";

    if (!currentText.startsWith(baseText)) {
      handleTextInput(baseText);
    }
  };

  const isCurrentStepValid = () => {
    const response = responses[currentQuestion.id];
    if (currentQuestion.type === "welcome") return true;
    if (!response) return false;
    if (currentQuestion.minLength) {
      return response.length >= currentQuestion.minLength;
    }
    return true;
  };

  const getCharacterMessage = () => {
    const response = responses[currentQuestion.id] || "";
    const remaining = currentQuestion.minLength - response.length;
    if (remaining > 0) {
      return `${remaining} more characters needed for a detailed response`;
    }
    return "Minimum length reached - feel free to share more!";
  };

  const handleNext = () => {
    if (currentStep < assessmentSteps.length - 1) {
      setCurrentStep((prev) => prev + 1);
      setSelectedOption(null);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      setSelectedOption(null);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const formattedResponses = Object.entries(responses).map(
        ([id, response]) => ({
          questionId: id,
          response: response.trim(),
          timestamp: new Date().toISOString(),
        })
      );

      console.log("AssessmentModal - formatted responses:", formattedResponses);
      localStorage.setItem(
        "initialAssessment",
        JSON.stringify(formattedResponses)
      );
      console.log(
        "AssessmentModal - saved to localStorage:",
        localStorage.getItem("initialAssessment")
      );

      onSubmit(formattedResponses);
    } catch (error) {
      console.error("Error formatting assessment:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="assessment-modal">
      <div className="assessment-modal-content">
        <button className="close-button" onClick={onClose}>
          <FiX />
        </button>

        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{
              width: `${(currentStep / (assessmentSteps.length - 1)) * 100}%`,
            }}
          />
        </div>

        {currentQuestion.type === "welcome" ? (
          <div className="welcome-screen">
            <FiHeart className="welcome-icon" />
            <h2>{currentQuestion.title}</h2>
            <p>{currentQuestion.content}</p>
            <p className="helper-text">{currentQuestion.helper}</p>
            <p className="emphasis-text">{currentQuestion.emphasis}</p>
            <div className="welcome-actions">
              <button 
                className="skip-assessment-button" 
                onClick={() => {
                  window.location.href = '/signup';
                }}
              >
                Skip assessment and proceed with general registration
              </button>
              <button className="next-button" onClick={handleNext}>
                Begin Assessment <FiArrowRight />
              </button>
            </div>
          </div>
        ) : (
          <div className="question-screen">
            <div className="question-content">
              <h3>{currentQuestion.title}</h3>
              <p className="question-text">{currentQuestion.question}</p>
              <p className="helper-text">{currentQuestion.helper}</p>

              <textarea
                value={responses[currentQuestion.id] || ""}
                onChange={(e) => handleTextInput(e.target.value)}
                placeholder={currentQuestion.placeholder}
                style={{ height: `${inputHeight}px` }}
                onInput={(e) => {
                  setInputHeight(Math.max(120, e.target.scrollHeight));
                }}
              />

              {currentQuestion.type === "combined" && (
                <div className="quick-options">
                  {currentQuestion.quickOptions.map((option, index) => (
                    <button
                      key={index}
                      className={`quick-option ${
                        selectedOption === option ? "selected" : ""
                      }`}
                      onClick={() => handleQuickOption(option)}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}

              <div className="input-container">
                {currentQuestion.suggestedPrompts && (
                  <div className="suggested-prompts">
                    {currentQuestion.suggestedPrompts.map((prompt, index) => (
                      <button
                        key={index}
                        className="prompt-pill"
                        onClick={() => handleTextInput(prompt)}
                      >
                        {prompt}
                      </button>
                    ))}
                  </div>
                )}

                {currentQuestion.minLength && (
                  <div
                    className={`input-progress ${
                      responses[currentQuestion.id]?.length >=
                      currentQuestion.minLength
                        ? "complete"
                        : ""
                    }`}
                  >
                    <FiAlertCircle className="progress-icon" />
                    <div className="progress-text">{getCharacterMessage()}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="navigation-buttons">
              <button
                className="back-button"
                onClick={handlePrevious}
                disabled={currentStep === 0}
              >
                Back
              </button>

              {currentStep < assessmentSteps.length - 1 ? (
                <button
                  className="next-button"
                  disabled={!isCurrentStepValid()}
                  onClick={handleNext}
                >
                  Continue <FiArrowRight />
                </button>
              ) : (
                <button
                  className="submit-button"
                  disabled={!isCurrentStepValid() || isLoading}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <>
                      <FiLoader className="spinner" /> Processing...
                    </>
                  ) : (
                    <>
                      <FiCheck /> Complete & Continue to Signup
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentModal;