import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { signUpSchema } from '../../schemas/userSchemas';
import { signup } from '../../store/actions/userActions';
import Swal from 'sweetalert2';
import { FaUser, FaEnvelope, FaEye, FaEyeSlash, FaLock, FaPhone } from 'react-icons/fa';
import BtnLoader from '../../components/BtnLoader/BtnLoader';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';

const SignUpForm = ({ onSuccess, referralId, groupId, parishCode }) => {  // Add parishCode here
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSignUp = async (values) => {
    setLoading(true);
    try {
      const initialAssessment = JSON.parse(localStorage.getItem('initialAssessment') || '[]');
      
      const data = {
        fullName: values.fullName,
        email: values.email,
        phone: values.phone, // Use the phone from form values
        password: values.password,
        subscriptionType: "standard",
        referralId: referralId,
        initialAssessment
      };

      // Pass parishCode as second argument to signup
      const userId = await dispatch(signup(data, parishCode));

      if (groupId) {
        try {
          const joinEndpoint = API_ENDPOINTS.JOIN_GROUP.replace(':groupId', groupId);
          await axios.post(joinEndpoint, { user_id: userId });
        } catch (joinError) {
          console.error('Error joining group:', joinError);
        }
      }
      
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Welcome!',
        text: groupId ? 'Redirecting you to your group...' : 'Success! Your account is almost ready...',
        timer: 2000,
        showConfirmButton: false
      }).then(() => {
        onSuccess(data, userId);
      });
    } catch (error) {
      console.error('handleSignUp - error:', error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Signup Failed",
        text: `${error}`,
      });
    }
  };



  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: localStorage.getItem('userPhone') || "", // Add this line
      password: "",
      cPassword: "",
      subscriptionType: "standard",
    },
    onSubmit: handleSignUp,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  return (
    <div className="signup-form-container">
      <div className="signup-fields">
        <div className="form-field">
          <label htmlFor="user-fullname">
            Full Name <span className="required">*</span>
          </label>
          <input
            type="text"
            id="user-fullname"
            {...formik.getFieldProps('fullName')}
            placeholder="Enter your full name"
            className={`input-wrapper ${formik.touched.fullName && formik.errors.fullName ? 'error' : ''}`}
          />
          {formik.touched.fullName && formik.errors.fullName && (
            <div className="error-message">{formik.errors.fullName}</div>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="user-email">
            Email <span className="required">*</span>
          </label>
          <div className={`input-wrapper ${formik.touched.email && formik.errors.email ? 'error' : ''}`}>
            <FaEnvelope className="input-icon" />
            <input
              type="email"
              id="user-email"
              {...formik.getFieldProps('email')}
              placeholder="Enter your email address"
            />
          </div>
          {formik.touched.email && formik.errors.email && (
            <div className="error-message">{formik.errors.email}</div>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="user-phone">
            Phone Number <span className="required">*</span>
          </label>
          <div className={`input-wrapper ${formik.touched.phone && formik.errors.phone ? 'error' : ''}`}>
            <FaPhone className="input-icon" />
            <input
              type="tel"
              id="user-phone"
              {...formik.getFieldProps('phone')}
              placeholder="Enter your phone number"
            />
          </div>
          {formik.touched.phone && formik.errors.phone && (
            <div className="error-message">{formik.errors.phone}</div>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="user-password">
            Password <span className="required">*</span>
          </label>
          <div className={`input-wrapper ${formik.touched.password && formik.errors.password ? 'error' : ''}`}>
            <FaLock className="input-icon" />
            <input
              type={showPassword ? 'text' : 'password'}
              id="user-password"
              {...formik.getFieldProps('password')}
              placeholder="Create a password"
            />
            <button 
              type="button"
              className="toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="error-message">{formik.errors.password}</div>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="cnfrm-pass">
            Confirm Password <span className="required">*</span>
          </label>
          <div className={`input-wrapper ${formik.touched.cPassword && formik.errors.cPassword ? 'error' : ''}`}>
            <FaLock className="input-icon" />
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="cnfrm-pass"
              {...formik.getFieldProps('cPassword')}
              placeholder="Confirm your password"
            />
            <button 
              type="button"
              className="toggle-password"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {formik.touched.cPassword && formik.errors.cPassword && (
            <div className="error-message">{formik.errors.cPassword}</div>
          )}
        </div>
      </div>

      <button 
        type="button"
        className="submit-button"
        onClick={formik.handleSubmit}
        disabled={loading}
      >
        {loading ? <BtnLoader /> : "Create Account"}
      </button>
    </div>
  );
};

export default SignUpForm;