import { useEffect, useState } from "react";
import { FiChevronDown, FiStar, FiTarget } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import AssessmentModal from "../AssessmentModal/AssessmentModal";

const aboutLinks = [
  {
    title: "About",
    description: "Our AI & spiritual direction journey",
    icon: <FiStar className="dropdown-icon-main" />,
    path: "/about",
    color: "purple",
  },
  {
    title: "Pricing",
    description: "Individual and parish solutions",
    icon: <FiTarget className="dropdown-icon-main" />,
    path: "/pricing",
    color: "green",
  },
];

const PublicLayout = ({ render, className }) => {
  const navigate = useNavigate();
  const [showProductsMenu, setShowProductsMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    document.body.classList.add("hide-navbar");

    return () => {
      document.body.classList.remove("hide-navbar");
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(".products-dropdown");
      if (dropdown && !dropdown.contains(event.target) && showProductsMenu) {
        setShowProductsMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [showProductsMenu]);

  const toggleProductsMenu = () => {
    setShowProductsMenu(!showProductsMenu);
  };

  return (
    <>
      <nav className={`home-nav ${isScrolled ? "scrolled" : ""}`}>
        <div className="nav-content">
          <div className="nav-left">
            <Link to="/" className="nav-logo">
              <img src="/logo.png" alt="CrossValidation.ai" />
              <span className="company-name">CrossValidation.ai</span>
            </Link>

            <div className="nav-links">
              <div
                className={`products-dropdown ${
                  showProductsMenu ? "active" : ""
                }`}
              >
                <button
                  className="dropdown-trigger"
                  onClick={toggleProductsMenu}
                  aria-expanded={showProductsMenu}
                >
                  Learn More{" "}
                  <FiChevronDown
                    className={`trigger-icon ${
                      showProductsMenu ? "rotated" : ""
                    }`}
                  />
                </button>
                <div className="dropdown-menu">
                  {aboutLinks.map((item, index) => (
                    <Link
                      key={index}
                      to={item.path}
                      className={`dropdown-item ${item.color}`}
                    >
                      <span className="dropdown-icon">{item.icon}</span>
                      <div className="dropdown-content">
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="nav-buttons">
            <Link to="/signin" className="nav-button sign-in">
              Sign In
            </Link>

            <Link to="/signup" className="nav-button sign-up">
              Start Your Free Month
            </Link>
          </div>
        </div>
      </nav>
      <div className={className}>{render({ setShowAssessment: () => navigate('/signup') })}</div>
    </>
  );
};

export default PublicLayout;