import React, { useState, useEffect } from 'react';
import { FiBell, FiCheck, FiTrash2, FiSettings } from 'react-icons/fi';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import './NotificationManager.css';

const NotificationManager = ({ userId, onNotificationClick }) => {
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userId) {
      checkSubscriptionStatus();
      const interval = setInterval(fetchNotifications, 300000); // Refresh every 5 minutes
      fetchNotifications();
      return () => clearInterval(interval);
    }
  }, [userId]);

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_ENDPOINTS.GET_NOTIFICATIONS}/${userId}`);
      
      if (response.data.notifications) {
        setNotifications(response.data.notifications);
        setUnreadCount(response.data.notifications.filter(n => !n.read).length);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching notifications:', err);
      setError('Failed to load notifications');
    } finally {
      setLoading(false);
    }
  };

  const checkSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.NOTIFICATION_STATUS}/${userId}`);
      setIsSubscribed(response.data.status === 'notify');
    } catch (err) {
      console.error('Error checking subscription status:', err);
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      if (!notification.read) {
        await axios.post(`${API_ENDPOINTS.MARK_NOTIFICATION_READ}/${notification.id}`);
        setNotifications(notifications.map(n => 
          n.id === notification.id ? { ...n, read: true } : n
        ));
        setUnreadCount(prev => Math.max(0, prev - 1));
      }
      onNotificationClick(notification);
      setIsOpen(false);
    } catch (err) {
      console.error('Error marking notification as read:', err);
    }
  };

  const toggleSubscription = async () => {
    try {
      const newStatus = !isSubscribed;
      await axios.post(`${API_ENDPOINTS.UPDATE_NOTIFICATION_STATUS}/${userId}`, {
        status: newStatus ? 'notify' : 'none'
      });
      
      setIsSubscribed(newStatus);
      
      if (newStatus) {
        await generateWeeklyReflection();
      }
    } catch (err) {
      console.error('Error updating subscription:', err);
    }
  };

  const generateWeeklyReflection = async () => {
    try {
      setLoading(true);
      const response = await axios.post(API_ENDPOINTS.WEEKLY_REFLECTION, {
        user_id: userId
      });
      
      if (response.data.reflection) {
        await fetchNotifications();
      }
    } catch (err) {
      console.error('Error generating reflection:', err);
    } finally {
      setLoading(false);
    }
  };

  const clearAllNotifications = async () => {
    try {
      await axios.post(`${API_ENDPOINTS.CLEAR_NOTIFICATIONS}/${userId}`);
      setNotifications([]);
      setUnreadCount(0);
      setIsOpen(false);
    } catch (err) {
      console.error('Error clearing notifications:', err);
    }
  };

  const formatNotificationTime = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = (now - date) / 1000;

    if (diff < 60) return 'Just now';
    if (diff < 3600) return `${Math.floor(diff / 60)}m ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)}h ago`;
    if (diff < 604800) return `${Math.floor(diff / 86400)}d ago`;
    return date.toLocaleDateString();
  };

  return (
    <div className="notification-manager">
      <button 
        className="notification-toggle"
        onClick={() => setIsOpen(!isOpen)}
        aria-label={`Notifications ${unreadCount > 0 ? `(${unreadCount} unread)` : ''}`}
      >
        <FiBell />
        {unreadCount > 0 && (
          <span className="notification-badge" aria-label="Unread notifications">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="notification-panel" role="dialog" aria-label="Notifications panel">
          <div className="notification-header">
            <h3>Notifications</h3>
            <div className="notification-actions">
              <button
                className="subscription-toggle"
                onClick={toggleSubscription}
                aria-label={`${isSubscribed ? 'Disable' : 'Enable'} weekly reflections`}
              >
                <FiSettings />
                {isSubscribed ? 'Subscribed' : 'Subscribe'}
              </button>
              <button 
                className="clear-all-button"
                onClick={clearAllNotifications}
                disabled={notifications.length === 0}
                aria-label="Clear all notifications"
              >
                <FiTrash2 />
                Clear All
              </button>
            </div>
          </div>

          <div className="notifications-list" role="list">
            {loading && (
              <div className="notification-loading">
                <div className="spinner"></div>
                <span>Loading notifications...</span>
              </div>
            )}

            {error && (
              <div className="notification-error">
                <span>{error}</span>
                <button onClick={fetchNotifications}>Retry</button>
              </div>
            )}

            {!loading && !error && notifications.length === 0 && (
              <div className="no-notifications" role="listitem">
                <p>No notifications</p>
                {!isSubscribed && (
                  <button onClick={toggleSubscription} className="subscribe-prompt">
                    Subscribe to Weekly Reflections
                  </button>
                )}
              </div>
            )}

            {notifications.map(notification => (
              <div 
                key={notification.id}
                className={`notification-item ${!notification.read ? 'unread' : ''}`}
                onClick={() => handleNotificationClick(notification)}
                role="listitem"
                aria-label={`${notification.read ? 'Read' : 'Unread'} notification: ${notification.title}`}
              >
                <div className="notification-content">
                  <div className="notification-title">
                    <h4>{notification.title}</h4>
                    {!notification.read && (
                      <span className="unread-marker" aria-hidden="true">
                        <FiCheck />
                      </span>
                    )}
                  </div>
                  <p>{notification.message.slice(0, 100)}...</p>
                  <span className="notification-time">
                    {formatNotificationTime(notification.timestamp)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationManager;