import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Send } from 'lucide-react';
import { API_ENDPOINTS } from '../../config/api';
import axios from 'axios';
import './SimplifiedDirectionChat.css';

const SimplifiedDirectionChat = () => {
    const { userId, tempUserId } = useSelector(state => state.user);
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [wsConnection, setWsConnection] = useState(null);
    const [streamingResponse, setStreamingResponse] = useState(null);
    const [currentResponse, setCurrentResponse] = useState('');

    const chatRef = useRef(null);
    const shouldScrollRef = useRef(true);
    const eventSourceRef = useRef(null);
    const mountedRef = useRef(true);

    const effectiveUserId = userId || tempUserId;

    // Initialize WebSocket connection
    const initializeWebSocket = useCallback(async () => {
        try {
            const queryParams = new URLSearchParams();
            if (effectiveUserId) queryParams.append('user_id', effectiveUserId);
            
            const wsUrl = `${API_ENDPOINTS.TEXT_SOCKET}${
                queryParams.toString() ? `?${queryParams.toString()}` : ''
            }`;

            const ws = new WebSocket(wsUrl);

            ws.onmessage = handleWebSocketMessage;
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                setIsLoading(false);
            };

            ws.onclose = () => {
                console.log('WebSocket connection closed');
                setWsConnection(null);
            };

            ws.onopen = () => {
                console.log('WebSocket connected successfully');
                setWsConnection(ws);
                setIsLoading(false);

                ws.send(JSON.stringify({
                    type: 'session.initialize',
                    data: {
                        userId: effectiveUserId,
                        timestamp: new Date().toISOString()
                    }
                }));
            };

            const connectionTimeout = setTimeout(() => {
                if (ws.readyState !== WebSocket.OPEN) {
                    ws.close();
                    setIsLoading(false);
                }
            }, 10000);

            ws.addEventListener('open', () => {
                clearTimeout(connectionTimeout);
            });

            return ws;
        } catch (err) {
            console.error('WebSocket initialization error:', err);
            setIsLoading(false);
            throw err;
        }
    }, [effectiveUserId]);

    const handleWebSocketMessage = useCallback((event) => {
        try {
            const data = JSON.parse(event.data);
            console.log('WebSocket message received:', data);

            switch (data.type) {
                case 'session.created':
                case 'session.updated':
                    console.log('Session status:', data.type);
                    break;

                case 'response.text.delta':
                    setCurrentResponse(prev => prev + data.delta);
                    if (shouldScrollRef.current && chatRef.current) {
                        chatRef.current.scrollTop = chatRef.current.scrollHeight;
                    }
                    break;

                case 'conversation.item.created':
                    const item = data.item;
                    if (item.role === 'assistant' && item.type === 'message') {
                        const textContent = item.content.find(c => c.type === 'text');
                        if (textContent) {
                            setMessages(prev => {
                                const exists = prev.some(msg => msg.id === item.id);
                                if (exists) return prev;
                                
                                return [...prev, {
                                    id: item.id,
                                    type: 'director',
                                    content: textContent.text,
                                    timestamp: new Date().toISOString()
                                }];
                            });
                        }
                    }
                    break;

                case 'response.text.done':
                    if (data.text) {
                        setMessages(prev => {
                            const exists = prev.some(msg => msg.content === data.text);
                            if (exists) return prev;

                            return [...prev, {
                                id: `response-${Date.now()}`,
                                type: 'director',
                                content: data.text,
                                timestamp: new Date().toISOString()
                            }];
                        });
                        setCurrentResponse("");
                        setIsLoading(false);
                    }
                    break;

                case 'response.output_item.done':
                    const outputItem = data.item;
                    if (outputItem?.content?.[0]?.text) {
                        setMessages(prev => {
                            const exists = prev.some(msg => msg.content === outputItem.content[0].text);
                            if (exists) return prev;

                            return [...prev, {
                                id: outputItem.id || `response-${Date.now()}`,
                                type: 'director',
                                content: outputItem.content[0].text,
                                timestamp: new Date().toISOString()
                            }];
                        });
                    }
                    setCurrentResponse("");
                    setIsLoading(false);
                    break;

                case 'response.done':
                    setIsLoading(false);
                    break;

                case 'error':
                    console.error('API error:', data.error);
                    setMessages(prev => [...prev, {
                        type: 'error',
                        content: data.error?.message || 'An error occurred'
                    }]);
                    setIsLoading(false);
                    break;

                default:
                    console.log('Other message type:', data.type);
                    break;
            }
        } catch (err) {
            console.error('Error handling WebSocket message:', err);
            setIsLoading(false);
        }
    }, []);


    useEffect(() => {
        const chatContainer = chatRef.current;
        if (!chatContainer) return;

        const handleScroll = () => {
            const isNearBottom = 
                chatContainer.scrollHeight - 
                chatContainer.scrollTop - 
                chatContainer.clientHeight < 100;
            shouldScrollRef.current = isNearBottom;
        };

        chatContainer.addEventListener("scroll", handleScroll);
        return () => chatContainer.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (isLoading && !currentResponse) {
            requestAnimationFrame(() => {
                if (chatRef.current) {
                    chatRef.current.scrollTop = chatRef.current.scrollHeight;
                }
            });
        }
    }, [isLoading, currentResponse]);

    // WebSocket initialization effect
    useEffect(() => {
        const initWs = async () => {
            if (!wsConnection) {
                try {
                    const ws = await initializeWebSocket();
                    if (mountedRef.current) {
                        setWsConnection(ws);
                        setIsLoading(false);
                    }
                } catch (err) {
                    console.error('Failed to initialize WebSocket:', err);
                    if (mountedRef.current) {
                        setIsLoading(false);
                    }
                }
            }
        };

        initWs();

        return () => {
            mountedRef.current = false;
            if (wsConnection) {
                wsConnection.close();
                setWsConnection(null);
                setIsLoading(false);
            }
        };
    }, [wsConnection, initializeWebSocket]);

    // Message submission handler
    const handleSubmit = async (e) => {
        e?.preventDefault();
        if (!inputText.trim() || isLoading) return;

        try {
            setIsLoading(true);
            shouldScrollRef.current = true;
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }

            setMessages(prev => [...prev, { type: 'user', content: inputText }]);
            const userMessage = inputText;
            setInputText('');
            setCurrentResponse('');

            if (wsConnection?.readyState === WebSocket.OPEN) {
                setIsLoading(true);
                wsConnection.send(JSON.stringify({
                    type: "input_text",
                    content: userMessage
                }));
            } else {
                setMessages(prev => [...prev, {
                    type: 'error',
                    content: 'Connection lost. Please try again.'
                }]);
                setIsLoading(false);
                const newWs = await initializeWebSocket();
                setWsConnection(newWs);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => [...prev, {
                type: 'error',
                content: 'Failed to send message'
            }]);
            setIsLoading(false);
        }
    };

    const renderMessage = (message) => {
        switch (message.type) {
            case 'user':
                return (
                    <div className="user-message">
                        <p>{message.content}</p>
                    </div>
                );
            case 'director':
                return (
                    <div className="assistant-message">
                        <div className="message-content">
                            {message.content.split('\n').map((line, i) => {
                                if (line.trim().startsWith('•')) {
                                    return <div key={i} className="bullet-point">{line}</div>;
                                } else if (line.trim().startsWith('>')) {
                                    return <blockquote key={i} className="scripture-quote">{line.replace('>', '')}</blockquote>;
                                } else if (line.trim().startsWith('#')) {
                                    const headingText = line.replace(/^#+\s/, '');
                                    return <h4 key={i} className="message-heading">{headingText}</h4>;
                                }
                                return line.trim() && <p key={i}>{line}</p>;
                            })}
                        </div>
                    </div>
                );
            case 'error':
                return <div className="error-message">{message.content}</div>;
            default:
                return null;
        }
    };



    return (
        <div className="simple-chat-main">
            <div className="simple-chat-content">
                <div className="simple-chat-scroll">
                    <div className="simple-chat-area">
                        <div 
                            className={`simple-chat-messages ${messages.length > 0 ? 'has-content' : ''}`}
                            ref={chatRef}
                            role="log"
                            aria-live="polite"
                        >
                            {messages.map((message, index) => (
                                <div 
                                    key={index} 
                                    className={`simple-msg ${message.type}`}
                                    role={message.type === 'user' ? 'note' : 'article'}
                                >
                                    {renderMessage(message)}
                                </div>
                            ))}
                            
                            {isLoading && currentResponse && (
                                <div className="simple-msg director" role="article">
                                    <div className="simple-guidance streaming">
                                        {renderMessage({
                                            type: 'director',
                                            content: currentResponse
                                        })}
                                        <div className="simple-typing" aria-label="Director is typing">
                                            <div className="simple-dots">
                                                <div></div><div></div><div></div>
                                            </div>
                                            <span className="sr-only">Composing response...</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            
                            {isLoading && !currentResponse && (
                                <div className="simple-loading" role="status">
                                    <div className="simple-dots">
                                        <div></div><div></div><div></div>
                                    </div>
                                    <span>Reflecting...</span>
                                </div>
                            )}
                        </div>

                        <form 
                            onSubmit={handleSubmit} 
                            className="simple-input-form"
                            role="form"
                        >
                            <div className="simple-input-wrap">
                                <textarea
                                    value={inputText}
                                    onChange={(e) => setInputText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }
                                    }}
                                    placeholder="Enter your message..."
                                    rows="3"
                                    className="simple-textarea"
                                    disabled={isLoading}
                                    aria-label="Message input"
                                    role="textbox"
                                    aria-multiline="true"
                                />
                                <button
                                    type="submit"
                                    disabled={isLoading || !inputText.trim()}
                                    className="simple-send"
                                    aria-label="Send message"
                                >
                                    <Send className="simple-send-icon" aria-hidden="true" />
                                    <span className="sr-only">Send</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SimplifiedDirectionChat;