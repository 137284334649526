import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Chat from './pages/Chat/Chat';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';
import About from './pages/About/About';
import Authenticate from './pages/Authenticate/Authenticate';
import ObjectivesChat from './pages/ObjectivesChat/ObjectivesChat';
import Profile from './pages/Profile/Profile';
import Trade from './pages/Trade/Trade';
import BrokerageAccount from './pages/BrokerageAccount/BrokerageAccount';
import SchwabCallback from './components/SchwabCallback';
import FaithJourney from './pages/FaithJourney/FaithJourney';
import DailyPrayer from './pages/DailyPrayer/DailyPrayer';
import DirectionChat from './pages/DirectionChat/DirectionChat';
import Rewards from './pages/Rewards/Rewards';
import Refer from './pages/Refer/Refer';
import Build from './pages/Build/Build';
import CatholicAppBuilder from './pages/CatholicAppBuilder/CatholicAppBuilder';
import GroupInvite from './pages/GroupInvite/GroupInvite';
import GroupDirectory from './pages/GroupDirectory/GroupDirectory';
import GroupPage from './pages/GroupPage/GroupPage';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import UserGuide from './pages/UserGuide/UserGuide';
import CallScheduled from './pages/CallScheduled/CallScheduled';
import DatabentoChat from './pages/DatabentoChat/DatabentoChat';
import CaseStudyPage from './pages/CaseStudyPage/CaseStudyPage';
import DemoPage from './pages/DemoPage/DemoPage';
import Pricing from './pages/Pricing/Pricing';
import PublicInvest from './pages/PublicInvest/PublicInvest';
import { setUserId } from './store/actions/userActions';
import GoogleAnalytics from './GoogleAnalytics';
import './App.css';

const AppContent = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isAuthorized = useSelector(state => state.user.isAuthorized);
  const userId = useSelector(state => state.user.userId);
  const isRehydrated = useSelector(state => state.user._persist?.rehydrated);
  const location = useLocation();

  const noNavbarPaths = [
    '/home',
    '/signup',
    '/signin',
    '/about', 
    '/case-study',
    '/demo',
    '/pricing'
  ];

  // Early check for userId and immediate redirect
  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      dispatch(setUserId(storedUserId));
      // If we're at the root path and have a userId, navigate to faith-journey
      if (location.pathname === '/') {
        window.location.replace('/faith-journey');
      }
    } else if (location.pathname === '/') {
      // If no userId, always redirect to home
      window.location.replace('/home');
    }
  }, [dispatch, location.pathname]);

  // Don't render anything until rehydration is complete
  if (!isRehydrated) {
    return null;
  }

  // Early return for root path without userId
  if (location.pathname === '/' && !userId) {
    return null;
  }

  const showNavbar = !noNavbarPaths.includes(location.pathname) && 
    !location.pathname.startsWith('/signup/') && 
    isRehydrated;

  return (
    <div className="app">
      {showNavbar && <Navbar />}
      <GoogleAnalytics />
      <Routes>
        {/* Root path handler - only shown after userId check */}
        <Route path="/" element={
          <Navigate to={userId ? "/faith-journey" : "/home"} replace />
        } />

        {/* Public routes */}
        <Route path="/home" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup/:referralId" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/about" element={<About />} />
        <Route path="/case-study" element={<CaseStudyPage />} />
        <Route path="/demo" element={<DemoPage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/spiritual-call" element={<CallScheduled />} />

        {/* Protected routes */}
        <Route path="/chat" element={
          userId ? <Chat /> : <Navigate to="/signin" replace />
        } />
        <Route path="/authenticate" element={
          userId ? <Authenticate /> : <Navigate to="/signin" replace />
        } />
        <Route path="/faith-journey" element={
          userId ? <FaithJourney /> : <Navigate to="/signin" replace />
        } />
        <Route path="/rewards" element={
          userId ? <Rewards /> : <Navigate to="/signin" replace />
        } />
        <Route path="/refer" element={
          userId ? <Refer /> : <Navigate to="/signin" replace />
        } />
        <Route path="/build" element={
          userId ? <Build /> : <Navigate to="/signin" replace />
        } />
        <Route path="/public-invest" element={
          userId ? <PublicInvest /> : <Navigate to="/signin" replace />
        } />
        <Route path="/builder" element={
          userId ? <CatholicAppBuilder /> : <Navigate to="/signin" replace />
        } />
        <Route path="/group-directory" element={
          userId ? <GroupDirectory /> : <Navigate to="/signin" replace />
        } />
        <Route path="/user-guide" element={
          userId ? <UserGuide /> : <Navigate to="/signin" replace />
        } />
        <Route path="/bento" element={
          userId ? <DatabentoChat /> : <Navigate to="/signin" replace />
        } />
        <Route path="/daily-prayer" element={
          userId ? <DailyPrayer /> : <Navigate to="/signin" replace />
        } />
        <Route path="/direction-chat" element={
          userId ? <DirectionChat /> : <Navigate to="/signin" replace />
        } />

        {/* Auth-required routes */}
        <Route path="/objectives-chat" element={
          isAuthenticated ? <ObjectivesChat /> : <Navigate to="/signin" replace />
        } />
        <Route path="/profile" element={
          isAuthorized ? <Profile /> : <Navigate to="/signin" replace />
        } />
        <Route path="/trade" element={
          isAuthenticated ? <Trade /> : <Navigate to="/signin" replace />
        } />
        <Route path="/brokerage-account" element={
          isAuthenticated ? <BrokerageAccount /> : <Navigate to="/signin" replace />
        } />

        {/* Callback routes */}
        <Route path="/schwab-callback" element={<SchwabCallback />} />
        <Route path="/callbacks" element={<SchwabCallback />} />

        {/* Group routes */}
        <Route path="/group-invite/:groupId" element={
          userId ? <GroupInvite /> : <Navigate to="/signin" replace />
        } />
        <Route path="/group/:groupId/admin" element={
          userId ? <AdminPanel /> : <Navigate to="/signin" replace />
        } />
        <Route path="/group/:groupId" element={
          userId ? <GroupPage /> : <Navigate to="/signin" replace />
        } />

        {/* Catch all route - redirect to home */}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </div>
  );
};

function App() {
  // Run version check before any other code
  useEffect(() => {
    const checkVersion = async () => {
      try {
        // Force no-cache on the request
        const response = await fetch('/version.json', {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          },
          cache: 'no-store'
        });
        
        if (!response.ok) {
          console.log('Version check failed - bad response:', response.status);
          return;
        }

        const data = await response.json();
        const currentVersion = localStorage.getItem('appVersion');
        
        console.log('Current version:', currentVersion);
        console.log('New version:', data.version);
        
        if (!currentVersion || currentVersion !== data.version) {
          console.log('Version mismatch, clearing cache and reloading...');
          // Clear all caches before reloading
          if ('caches' in window) {
            caches.keys().then(names => {
              names.forEach(name => {
                caches.delete(name);
              });
            });
          }
          localStorage.setItem('appVersion', data.version);
          window.location.replace(window.location.href);
        }
      } catch (err) {
        console.log('Version check failed:', err);
      }
    };

    // Run immediately
    checkVersion();
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;