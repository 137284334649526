import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiPhone, FiMessageSquare, FiX } from 'react-icons/fi';
import AssessmentModal from '../AssessmentModal/AssessmentModal';
import './AssessmentBanner.css';

const AssessmentBanner = ({ userId, userPhone }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkAssessmentStatus();
  }, [userId]);

  const checkAssessmentStatus = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`http://localhost:5068/api/assessment/status/${userId}`);
      
      if (response.data.success) {
        setShowBanner(!response.data.has_assessment);
      }
    } catch (err) {
      setError('Failed to check assessment status');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallRequest = async () => {
    try {
      const response = await axios.post('http://localhost:5068/api/assessment/call', {
        user_id: userId,
        phone_number: userPhone
      });

      if (response.data.success) {
        alert('You will receive a call shortly for your assessment.');
        setShowBanner(false);
      }
    } catch (err) {
      setError('Failed to schedule call');
      console.error(err);
    }
  };

  const handleTextAssessment = () => {
    setShowAssessmentModal(true);
  };

  if (isLoading || !showBanner) return null;

  return (
    <>
      <div className="assessment-banner">
        <div className="banner-content">
          <p className="banner-message">
            Complete a quick assessment to personalize your AI spiritual direction experience
          </p>
          <div className="banner-actions">
            <button 
              className="banner-button call-button"
              onClick={handleCallRequest}
            >
              <FiPhone className="button-icon" />
              Complete by Phone
            </button>
            <button 
              className="banner-button text-button"
              onClick={handleTextAssessment}
            >
              <FiMessageSquare className="button-icon" />
              Complete by Text
            </button>
          </div>
        </div>
        <button 
          className="close-banner"
          onClick={() => setShowBanner(false)}
        >
          <FiX />
        </button>
      </div>

      {showAssessmentModal && (
        <AssessmentModal
          onClose={() => setShowAssessmentModal(false)}
          onComplete={() => {
            setShowAssessmentModal(false);
            setShowBanner(false);
          }}
          userId={userId}
        />
      )}
    </>
  );
};

export default AssessmentBanner;