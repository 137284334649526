import React, { useState } from "react";
import axios from 'axios'; // Add this import
import { Link, useNavigate } from "react-router-dom";
import {
  FiMessageCircle,
  FiSun,
  FiUsers,
  FiArrowRight,
  FiCheck,
  FiMail,
  FiPhone,
  FiMapPin,
  FiInstagram,
  FiLinkedin,
  FiFacebook,
  FiTarget,
} from "react-icons/fi";
import AssessmentModal from "../../components/AssessmentModal/AssessmentModal";
import "./Home.css";
import SimplifiedDirectionChat from "../../components/SimplifiedDirectionChat/SimplifiedDirectionChat";
import PublicLayout from "../../components/PublicLayout/PublicLayout";
import { API_ENDPOINTS } from "../../config/api";

const Home = () => {
  const navigate = useNavigate();
  const [showAssessment, setShowAssessment] = useState(false);
  const [activeFeature, setActiveFeature] = useState(null);
  const [effectiveUserId, setEffectiveUserId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);



  const features = [
    {
      icon: <FiMessageCircle size={24} />,
      title: "Set Your Weekly & Monthly Goals",
      description:
        "This can be anything from work deadlines, committment to the sacraments, or personal and community wide activities.",
      benefits: [
        "The spiritual guide will provide reflections each time you check-in on progress.",
        "Grounded in Catholic teachings. ",
        "24/7 availability for spiritual conversations",
      ],
      color: "blue",
    },
    {
      icon: <FiSun size={24} />,
      title: "Daily Morning, Midday, and Evening Check-in",
      description:
        "Quick prompt to ensure you are keeping Christ at the center of your day.",
      benefits: [
        "Personalized discussions",
        "Follows Ignatian Spirituality",
        "Scripture integration",
      ],
      color: "yellow",
    },
    {
      icon: <FiTarget size={24} />,
      title: "Scripture Education-Based Reflections",
      description:
        "Reflect on the scripture passages most relevant to your personal situation.",
      benefits: [
        "Relevant biblical verses",
        "Analysis personalized to you",
        "Follow up questions to understand you better",
      ],
      color: "green",
    },
  ];


  // Add phone validation function
  const validatePhone = (phone) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegex.test(phone);
  };


  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    
    setPhoneError('');

    if (!validatePhone(phoneNumber)) {
      setPhoneError('Please enter a valid phone number');
      return;
    }

    setIsSubmitting(true);
    try {
      const cleanedPhone = phoneNumber.replace(/\D/g, '');
      localStorage.setItem('userPhone', cleanedPhone);

      await axios.post(API_ENDPOINTS.MAKE_CALL, 
        { 
          phone_number: cleanedPhone 
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      // Show success modal
      setShowSuccessModal(true);

      // Navigate after delay with cleanup
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
        navigate('/spiritual-call', { 
          state: { 
            phoneNumber: cleanedPhone
          } 
        });
      }, 9000);

      // Cleanup timeout if component unmounts
      return () => clearTimeout(timer);

    } catch (error) {
      console.error('Error scheduling call:', error);
      setPhoneError('Unable to schedule call. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };





  const handleFeatureHover = (index) => {
    setActiveFeature(index);
  };

  return (
    <PublicLayout
      className="home-container"
      render={({ setShowAssessment: layoutSetShowAssessment }) => (
        <div className="home-container">

          <header className="hero-section">
              {/* Call Section */}
              <div className="hero-call-section">
                <div className="hero-content">
                  <h1 className="hero-title">Catholic AI Spiritual Direction</h1>
                  <p className="hero-subtitle">
                    Personalized spiritual guidance for your everyday activities. Professional, faith-related and personal.
                  </p>

                  <div className="hero-call-container">
                    <div className="call-benefits">
                      <div className="call-benefit">
                        <FiSun /> <span>Pray Together</span>
                      </div>
                      <div className="call-benefit">
                        <FiTarget /> <span>Help Guide You</span>
                      </div>
                      <div className="call-benefit">
                        <FiMessageCircle /> <span>Answer Questions</span>
                      </div>
                    </div>

                    <h3 className="call-form-title">Have the AI Spiritual Director Call You</h3>
                    
                    <form onSubmit={handlePhoneSubmit} className="phone-input-form">
                      <div className="input-container">
                        <input
                          type="tel"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="Enter your phone number"
                          className={`phone-input ${phoneError ? 'error' : ''}`}
                          disabled={isSubmitting}
                        />
                        {phoneError && <div className="error-message">{phoneError}</div>}
                      </div>
                      <button 
                        type="submit" 
                        className="submit-button"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Scheduling...' : 'Get a Call'} 
                        <FiArrowRight className="button-icon" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>

              {/* Chat Section */}
              
              <div className="hero-chat-section">
                  <div className="chat-section-inner">
                      <h2 className="chat-section-title">Prefer Discussing Via Chat? Message Your AI Spiritual Guide</h2>
                      <p className="chat-section-subtitle">
                          Experience personalized guidance through a conversation with our AI spiritual director.
                      </p>
                      <div className="chat-interface">
                          <SimplifiedDirectionChat />
                      </div>
                  </div>
              </div>

          </header>

          <section className="features-section">
            <div className="section-header">
              <h2>Reflect on Everyday Activities with Your Spiritual Director</h2>
            </div>
            <div className="features-grid">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className={`feature-card ${feature.color} ${
                    activeFeature === index ? "active" : ""
                  }`}
                  onMouseEnter={() => handleFeatureHover(index)}
                  onMouseLeave={() => handleFeatureHover(null)}
                >
                  <div className={`feature-icon ${feature.color}`}>{feature.icon}</div>
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                  <ul className="feature-benefits">
                    {feature.benefits.map((benefit, i) => (
                      <li key={i}>
                        <FiCheck className="benefit-check" />
                        <span>{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          <section className="admin-showcase">
            <div className="admin-header">
              <h2>Parish-Wide Personalized AI Guidances</h2>
              <p>Priests and ministry leaders create custom spiritual growth journeies.</p>
            </div>
            <div className="admin-cards">
              <div className="admin-card">
                <div className="admin-image-wrap">
                  <img
                    src="/admin-panel.png"
                    alt="AI Response Customization"
                    className="admin-image"
                  />
                </div>
                <div className="card-info">
                  <h3>Customize AI Responses</h3>
                  <p>
                    Tailor AI spiritual guidance and create custom activities
                    aligned with your parish's pastoral approach and community
                    needs.
                  </p>
                </div>
              </div>
              <div className="admin-card">
                <div className="admin-image-wrap">
                  <img
                    src="/challenge-dashboard.png"
                    alt="Activity Dashboard"
                    className="admin-image"
                  />
                </div>
                <div className="card-info">
                  <h3>Parishoner Progress Check-ins</h3>
                  <p>
                    Set and monitor spiritual activities with AI-guided check-ins
                    and progress tracking for your community.
                  </p>
                </div>
              </div>
              <div className="admin-card">
                <div className="admin-image-wrap">
                  <img
                    src="/group-directory3.png"
                    alt="Ministry Programs"
                    className="admin-image"
                  />
                </div>
                <div className="card-info">
                  <h3>Ministry Programs</h3>
                  <p>
                    Create targeted programs for youth, young adults, marriage prep,
                    and family ministries with customized spiritual content.
                  </p>
                </div>
              </div>
            </div>
            <div className="demo-button-container">
              <button className="demo-button" onClick={() => navigate("/demo")}>
                Schedule a Demo <FiArrowRight className="demo-button-icon" />
              </button>
            </div>
          </section>

          <section className="final-cta">
            <div className="cta-content">
              <h2>Ready to Continue Your Spiritual Growth?</h2>
              <p>
                Experience personalized spiritual direction to help you grow closer
                to God.
              </p>
              <div className="cta-buttons">
                <button
                  onClick={() => navigate('/signup')}
                  className="primary-button"
                  aria-label="Start Free Month"
                >
                  Sign Up For All Features and Personalized Guidance{" "}
                  <FiArrowRight className="button-icon" />
                </button>
              </div>
            </div>
          </section>

          <footer className="home-footer">
            <div className="footer-content">
              <div className="footer-section brand">
                <Link to="/" className="footer-logo">
                  <img src="/logo.png" alt="CrossValidation.ai" />
                  <span>CrossValidation.ai</span>
                </Link>
                <p>
                  Combining Catholic wisdom with AI technology to provide
                  personalized spiritual guidance for modern Catholics.
                </p>
              </div>

              <div className="footer-section links">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <a
                      href="https://docs.google.com/document/d/1lbq3nwlzCUtoYLNQM94qaiYvVOD1EXS_mNYbp3LQN0o/edit?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                  </li>
                </ul>
              </div>

              <div className="footer-section contact">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <FiMapPin className="contact-icon" />
                    <span>500 Fremont Street, Menlo Park, CA 94025</span>
                  </li>
                  <li>
                    <FiPhone className="contact-icon" />
                    <a href="tel:+16506902002">(650) 690-2002</a>
                  </li>
                  <li>
                    <FiMail className="contact-icon" />
                    <a href="mailto:Adrian@CrossValidation.ai">
                      Adrian@CrossValidation.ai
                    </a>
                  </li>
                </ul>
                <div className="social-links">
                  <a
                    href="https://www.facebook.com/people/CrossValidationai/61567003652786/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <FiFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/crossvalidationai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <FiInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/trycrossvalidation/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <FiLinkedin />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <p>
                &copy; {new Date().getFullYear()} CrossValidation.ai. All rights
                reserved.
              </p>
            </div>
          </footer>

          {showSuccessModal && (
            <div className="call-success-modal-overlay">
              <div className="call-success-modal">
                <div className="success-icon">
                  <FiPhone />
                </div>
                <h3>AI Spiritual Director is calling you now</h3>
                <p>Feel free to ask it any information and enjoy the conversation!</p>
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default Home;