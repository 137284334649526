import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import { logInSchema } from "../../schemas/userSchemas";
import { signIn, clearSignUpErrors } from "../../store/actions/userActions";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import PublicLayout from "../../components/PublicLayout/PublicLayout";
import Swal from "sweetalert2";
import BtnLoader from "../../components/BtnLoader/BtnLoader";
import "./SignIn.css";
import { FiArrowLeft } from "react-icons/fi";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { error, loading, userId } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogIn = async (values) => {
    try {
      const data = {
        email: values.email,
        password: values.password,
      };

      const result = await dispatch(signIn(data));

      if (result?.payload?.userId) {
        navigate("/chat", {
          state: {
            newUser: false,
            userId: result.payload.userId,
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
    }
  };

  const { handleChange, handleBlur, values, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: handleLogIn,
      validationSchema: logInSchema,
      validateOnChange: false,
      validateOnBlur: true,
    });

  useEffect(() => {
    if (userId) {
      navigate("/chat", { state: { newUser: false, userId: userId } });
    }
  }, [userId, navigate]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
      dispatch(clearSignUpErrors());
    }
  }, [dispatch, error]);

  return (
    <div className="signin-page">
      <nav className="signin-nav">
        <div className="nav-content">
          <Link to="/home" className="nav-logo">
            <img src="/logo.png" alt="CrossValidation.ai" />
            <span>CrossValidation.ai</span>
          </Link>
          <Link to="/home" className="nav-link">
            <FiArrowLeft /> Back to Home
          </Link>
        </div>
      </nav>

      <div className="signin-container">
        <div className="signin-content">
          <div className="signin-header">
            <h1>Welcome Back</h1>
            <p className="lead-text">Sign in to continue your faith journey</p>
          </div>
          <div className="signin-form-wrapper">
            <form className="signin-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="user-email">
                  Email <span className="required-field">*</span>
                </label>
                <div
                  className={`input-container ${errors.email ? "error" : ""}`}
                >
                  <FaEnvelope className="input-icon" />
                  <input
                    type="email"
                    name="email"
                    id="user-email"
                    placeholder="Enter your email address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-input"
                  />
                </div>
                {errors.email && (
                  <span className="error-msg">{errors.email}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="user-password">
                  Password <span className="required-field">*</span>
                </label>
                <div
                  className={`input-container ${
                    touched.password && errors.password ? "error" : ""
                  }`}
                >
                  <FaEye className="input-icon" />
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="user-password"
                    placeholder="Enter your password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-input"
                  />
                  <div
                    className="toggle-password"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
                {touched.password && errors.password && (
                  <span className="error-msg">{errors.password}</span>
                )}
              </div>
              <button
                type="submit"
                className="signin-submit-btn"
                disabled={loading}
              >
                {loading ? <BtnLoader /> : "Sign In"}
              </button>
            </form>
            <div className="form-footer">
              <p className="signup-prompt">
                Don't have an account? <Link to="/signup">Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;