// DemoPage.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  FiMessageCircle,
  FiUsers,
  FiArrowRight,
  FiCheck,
  FiMail,
  FiPhone,
  FiMapPin,
  FiInstagram,
  FiLinkedin,
  FiFacebook,
  FiBook,
  FiStar,
  FiLoader,
  FiX,
} from "react-icons/fi";
import { API_ENDPOINTS } from "../../config/api";
import "./DemoPage.css";
import PublicLayout from "../../components/PublicLayout/PublicLayout";

const DemoPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    parishName: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      await axios.post(API_ENDPOINTS.SUBMIT_DEMO_REQUEST, formData);
      setSubmitStatus("success");
      setFormData({
        name: "",
        email: "",
        phone: "",
        parishName: "",
        message: "",
      });
    } catch (error) {
      setSubmitStatus("error");
      console.error("Error submitting demo request:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const features = [
    {
      icon: <FiMessageCircle size={24} />,
      title: "24/7 AI Spiritual Direction",
      description:
        "Personalized spiritual guidance powered by advanced AI, available any time.",
    },
    {
      icon: <FiUsers size={24} />,
      title: "Custom Group Management",
      description:
        "Create and manage multiple groups for different ministries and programs.",
    },
    {
      icon: <FiStar size={24} />,
      title: "Customizable AI Responses",
      description:
        "Tailor AI responses to align with your parish's specific teachings and focus.",
    },
    {
      icon: <FiBook size={24} />,
      title: "Content Integration",
      description:
        "Seamlessly integrate with your existing religious education and formation programs.",
    },
  ];

  return (
    <PublicLayout
      className="home-container"
      render={() => (
        <>
          <header className="hero-demo-section">
            <div className="hero-content">
              <h1 className="hero-title">
                Start Your AI Spiritual Direction Ministry
              </h1>
            </div>
          </header>

          <section className="form-section">
            <div className="section-content">
              <div className="form-container">
                <div className="form-header">
                  <h2>Schedule a Demo</h2>
                  <p>
                    Complete the form below to schedule a personalized
                    demonstration
                  </p>
                </div>

                <form onSubmit={handleSubmit} className="demo-form">
                  {/* Form content stays exactly the same */}
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="name">Full Name *</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter your full name"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Email Address *</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="phone">Phone Number</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="Enter your phone number"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="parishName">
                        Parish/Organization Name *
                      </label>
                      <input
                        type="text"
                        id="parishName"
                        name="parishName"
                        value={formData.parishName}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter your parish or organization name"
                      />
                    </div>
                  </div>

                  <div className="form-group full-width">
                    <label htmlFor="message">Additional Information</label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      placeholder="Share any specific requirements or questions"
                      rows="4"
                    />
                  </div>

                  <button
                    type="submit"
                    className="submit-button"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <FiLoader className="spinner" />
                        Submitting...
                      </>
                    ) : (
                      <>
                        Request Demo
                        <FiArrowRight className="button-icon" />
                      </>
                    )}
                  </button>

                  {submitStatus === "success" && (
                    <div className="submit-message success">
                      <FiCheck className="message-icon" />
                      Thank you! We'll be in touch shortly to schedule your
                      demo.
                    </div>
                  )}

                  {submitStatus === "error" && (
                    <div className="submit-message error">
                      <FiX className="message-icon" />
                      An error occurred. Please try again or contact us
                      directly.
                    </div>
                  )}
                </form>
              </div>
            </div>
          </section>

          <section className="features-section">
            <div className="section-content">
              <div className="section-header">
                <h2>Platform Features</h2>
                <p>
                  Discover how CrossValidation.ai can enhance your parish's
                  spiritual formation
                </p>
              </div>
              <div className="features-grid">
                {features.map((feature, index) => (
                  <div key={index} className="feature-card">
                    <div className="feature-icon">{feature.icon}</div>
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <footer className="home-footer">
            <div className="footer-content">
              <div className="footer-section brand">
                <Link to="/" className="footer-logo">
                  <img src="/logo.png" alt="CrossValidation.ai" />
                  <span>CrossValidation.ai</span>
                </Link>
                <p>
                  Combining Catholic wisdom with AI technology to provide
                  personalized spiritual guidance for modern Catholics.
                </p>
              </div>

              <div className="footer-section contact">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <FiMapPin className="contact-icon" />
                    <span>500 Fremont Street, Menlo Park, CA 94025</span>
                  </li>
                  <li>
                    <FiPhone className="contact-icon" />
                    <a href="tel:+16506902002">(650) 690-2002</a>
                  </li>
                  <li>
                    <FiMail className="contact-icon" />
                    <a href="mailto:Adrian@CrossValidation.ai">
                      Adrian@CrossValidation.ai
                    </a>
                  </li>
                </ul>
              </div>

              <div className="footer-section social">
                <h4>Connect With Us</h4>
                <div className="social-links">
                  <a
                    href="https://www.facebook.com/people/CrossValidationai/61567003652786/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <FiFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/crossvalidationai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <FiInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/trycrossvalidation/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <FiLinkedin />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <p>
                &copy; {new Date().getFullYear()} CrossValidation.ai. All rights
                reserved.
              </p>
            </div>
          </footer>
        </>
      )}
    />
  );
};

export default DemoPage;