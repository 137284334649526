//CURRENT CODE
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { FaChartLine, FaTasks, FaCalendarCheck, FaLock, FaSpinner, FaUsers, FaPlusCircle, FaList, FaCalendar } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../config/api';
import SpiritualFeedbackModal from '../SpiritualFeedbackModal/SpiritualFeedbackModal';
import './ChallengesDashboard.css';
// Add this import at the top with your other imports
import { FiX } from 'react-icons/fi';

const ChallengesDashboard = ({ 
  challenges, 
  personalGoals,
  userLevels, 
  onChallengeUpdate, 
  onPersonalGoalUpdate,
  onInitiateNextChallenges,
  userId,
  updateChallenges,
  loadChallengesData
}) => {
  const [expandedChallenge, setExpandedChallenge] = useState(null);
  const [reflections, setReflections] = useState({});
  const [loading, setLoading] = useState({});
  const [localReflections, setLocalReflections] = useState({});
  const [groupNames, setGroupNames] = useState({});
  const [groupChallengesLoaded, setGroupChallengesLoaded] = useState(false);
  const [weeklyReflections, setWeeklyReflections] = useState({});
  const [showAllReflections, setShowAllReflections] = useState({});
  const [shareAsComment, setShareAsComment] = useState({});
  const [showChallengeCreation, setShowChallengeCreation] = useState(false);
  const [sectionBuffer, setSectionBuffer] = useState({});

  const [scheduleConfig, setScheduleConfig] = useState({
    durationType: 'weekly',
    numberOfPeriods: 4,
    scheduleType: 'weekly',
    scheduleDays: [],
    scheduleWeek: 0,
    startDate: new Date().toISOString().split('T')[0]
  });

  useEffect(() => {
    console.log('=== State and Props Check ===', {
      expandedChallenge,
      userId,
      challengeData: challenges[0],
      hasReflections: Boolean(challenges[0]?.period_reflections),
      weeklyReflectionsState: weeklyReflections
    });
  }, [expandedChallenge, userId, challenges, weeklyReflections]);

  // Mount effect
  useEffect(() => {
    console.log('=== ChallengesDashboard Mount ===');
    console.log('Initial props:', {
      challenges,
      personalGoals,
      userLevels,
      userId,
      groupChallengesLoaded
    });
  }, []);

  // Data state effect
  useEffect(() => {
    console.log('=== ChallengesDashboard Data State ===', {
      challenges: challenges[0],
      weeklyReflections,
      expandedChallenge
    });
  }, [challenges, weeklyReflections, expandedChallenge]);



  const sampleChallenge = {
    id: 'sample-challenge',
    description: 'Attend digital gospel event November 3rd. Pray about the discussion then add a reflection.',
    category: 'Custom Goals.',
    period_prompts: [
      'Prompt CrossValidation.ai Spiritual Director for gospel verses related to a challenge faced today.',
      'Read the November 3rd Sunday gospel to reflect on the repetence of Zacchaeus, his obstacles, and the message from Jesus. How does this relate to your life?',
      'Set a weekly scripture reading and reflection routine. Tie is into your daily activities.',
      'Attend a gospel reflection and share your thoughts with a friend. Invite them to discuss a Sunday gospel.'
    ],
    period_progress: ['not_started', 'not_started', 'not_started', 'not_started'],
    start_date: moment().format('YYYY-MM-DD')
  };

  // Initial state with the correct mappings
  const [feedbackModal, setFeedbackModal] = useState({
      isOpen: false,
      sections: {
          Understanding: { content: '', icon: '🌱', complete: false },
          Scripture: { content: '', icon: '📖', complete: false },
          Spiritual: { content: '', icon: '✝️', complete: false },
          Prayer: { content: '', icon: '🙏', complete: false },
          Growth: { content: '', icon: '🌟', complete: false }
      },
      currentSection: '',
      isComplete: false,
      nextCheckIn: null
  });


  // Add this helper function for monthly weekday date calculations
  const getMonthlyWeekdayDate = (year, month, weekNum, weekday) => {
    const date = moment().year(year).month(month).startOf('month');
    if (weekNum === -1) {
      return date.endOf('month').day(weekday - 7);
    }
    return date.day(weekNum * 7 + weekday);
  };


  const isValidCheckInDate = (challenge, currentDate = moment()) => {
    return true;
  };

  useEffect(() => {
    console.log('==================== Initial Challenge Data ====================');
    console.log('Raw challenges:', challenges);
    console.log('Raw personal goals:', personalGoals);
    console.log('User levels:', userLevels);
    const groupChallenges = challenges.filter(c => c.group_id);
    console.log('Group challenges found:', groupChallenges);
    console.log('Group names:', groupNames);
  }, [challenges, personalGoals, userLevels, groupNames]);
    

  useEffect(() => {
    console.log('=== ChallengesDashboard Data Check ===', {
      receivedChallenge: challenges[0],
      reflectionsData: challenges[0]?.period_reflections,
      weeklyReflections
    });
  }, [challenges, weeklyReflections]);



  useEffect(() => {
    console.log('=== ChallengesDashboard Debug ===');
    console.log('Props received:', {
      challenges,
      personalGoals,
      userLevels,
      userId
    });
    console.log('State:', {
      groupChallengesLoaded,
      groupNames,
      expandedChallenge
    });

    // Log any challenges that might be group challenges
    const potentialGroupChallenges = challenges?.filter(c => c.group_id);
    console.log('Potential group challenges:', potentialGroupChallenges);

    // Check if fetchGroupChallenges should run
    console.log('Should fetch group challenges?', {
      hasUserId: Boolean(userId),
      notYetLoaded: !groupChallengesLoaded,
      willFetch: Boolean(userId && !groupChallengesLoaded)
    });
  }, [challenges, userId, groupChallengesLoaded]);


  // In fetchGroupChallenges useEffect:
  useEffect(() => {
    const fetchGroupChallenges = async () => {
      if (groupChallengesLoaded) {
        console.log('Group challenges already loaded, skipping fetch');
        return;
      }

      try {
        console.log('=== Starting Group Challenges Fetch ===');
        console.log('User ID:', userId);
        
        const groupsResponse = await axios.get(`${API_ENDPOINTS.GET_GROUPS}?user_id=${userId}`);
        console.log('Groups API Response:', groupsResponse.data);
        
        const userGroups = groupsResponse.data.filter(group => group.is_member);
        console.log('User member groups:', userGroups);
        
        const groupChallenges = await Promise.all(
          userGroups.map(async (group) => {
            try {
              const endpoint = `${API_ENDPOINTS.GROUP_CHALLENGES_LIST.replace(':groupId', group.id)}?userId=${userId}`;
              console.log(`Fetching challenges for group ${group.id} from:`, endpoint);
              
              const response = await axios.get(endpoint);
              console.log(`Raw group ${group.id} challenges:`, response.data);
              
              const participatingChallenges = response.data.filter(challenge => {
                console.log(`Challenge ${challenge.id} participation status:`, challenge.is_participating);
                return challenge.is_participating;
              });
              console.log(`Participating challenges for group ${group.id}:`, participatingChallenges);
              
              return participatingChallenges.map(challenge => {
                const enrichedChallenge = {
                  ...challenge,
                  group_id: group.id,
                  group_name: group.name,
                  isGroupChallenge: true,
                  category: 'Group Challenges',
                  period_progress: challenge.period_progress || Array(4).fill('not_started'),
                  period_reflections: challenge.period_reflections || {},
                  period_prompts: challenge.weekly_prompts || challenge.period_prompts || []
                };
                console.log(`Enriched challenge ${challenge.id}:`, enrichedChallenge);
                return enrichedChallenge;
              });
            } catch (error) {
              console.error(`Error fetching challenges for group ${group.id}:`, error.response || error);
              return [];
            }
          })
        );

        const allGroupChallenges = groupChallenges.flat();
        console.log('All processed group challenges:', allGroupChallenges);
        
        if (allGroupChallenges.length > 0) {
          onChallengeUpdate(prevChallenges => {
            console.log('Previous challenges before update:', prevChallenges);
            const nonGroupChallenges = Array.isArray(prevChallenges) 
              ? prevChallenges.filter(c => !c.group_id && !c.isGroupChallenge)
              : [];
            console.log('Filtered non-group challenges:', nonGroupChallenges);
            
            const updatedChallenges = [...nonGroupChallenges, ...allGroupChallenges];
            console.log('Final merged challenges:', updatedChallenges);
            
            return updatedChallenges;
          });
        } else {
          console.log('No group challenges found to update');
        }
        setGroupChallengesLoaded(true);
      } catch (error) {
        console.error('Error in fetchGroupChallenges:', error.response || error);
        setGroupChallengesLoaded(true);
      }
    };

    if (userId && !groupChallengesLoaded) {
      console.log('Initiating group challenges fetch');
      fetchGroupChallenges();
    } else {
      console.log('Skipping group challenges fetch:', { userId, groupChallengesLoaded });
    }
  }, [userId, groupChallengesLoaded, onChallengeUpdate]);


  useEffect(() => {
    const fetchGroupNames = async () => {
      try {
        const groupIds = [...new Set(challenges.filter(c => c.group_id).map(c => c.group_id))];
        if (groupIds.length > 0) {
          const response = await axios.get(`${API_ENDPOINTS.GET_GROUPS}?user_id=${userId}`);
          const groupsMap = {};
          response.data.forEach(group => {
            if (groupIds.includes(group.id)) {
              groupsMap[group.id] = group.name;
            }
          });
          setGroupNames(groupsMap);
        } else {
          setGroupNames({});
        }
      } catch (error) {
        console.error('Error processing group IDs:', error);
        setGroupNames({});
      }
    };

    fetchGroupNames();
  }, [challenges, userId]);

  const getNextCheckInDate = (challenge) => {
    const today = moment();
    
    if (challenge.schedule_type === 'specific_weekday') {
      if (challenge.duration_type === 'monthly') {
        const targetWeek = challenge.schedule_week === -1 ? 'last' : challenge.schedule_week + 1;
        const targetDay = challenge.schedule_days[0];
        return moment().startOf('month')
          .day(targetWeek === 'last' ? -1 : 0)
          .day(targetDay);
      } else {
        const nextDays = challenge.schedule_days
          .map(day => {
            const next = moment().day(day);
            if (next.isBefore(today)) {
              next.add(1, 'week');
            }
            return next;
          })
          .sort((a, b) => a - b);
        return nextDays[0];
      }
    } else if (challenge.schedule_type === 'specific_date' && challenge.duration_type === 'monthly') {
      const targetDate = challenge.schedule_days[0];
      let nextDate = moment().date(targetDate);
      if (nextDate.isBefore(today)) {
        nextDate = nextDate.add(1, 'month');
      }
      return nextDate;
    } else {
      const periodLength = challenge.duration_type === 'weekly' ? 7 : 30;
      return moment(challenge.start_date).add(periodLength * challenge.current_period, 'days');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const getPeriodDates = (challenge, periodIndex) => {
    if (!challenge.start_date) return '';
    
    const start = moment(challenge.start_date);
    const periodLength = challenge.duration_type === 'weekly' ? 'week' : 'month';
    
    let periodStart = start.clone().add(periodIndex, periodLength);
    let periodEnd;

    if (challenge.schedule_type === 'specific_weekday') {
      if (challenge.duration_type === 'monthly') {
        const weekNum = challenge.schedule_week === -1 ? 'last' : challenge.schedule_week + 1;
        periodEnd = periodStart.clone().endOf('month');
        const weekday = challenge.schedule_days[0];
        if (weekNum === 'last') {
          periodStart = periodEnd.clone().day(weekday - 7);
        } else {
          periodStart = periodStart.clone().startOf('month').day(weekNum * 7 + weekday);
        }
      } else {
        periodEnd = periodStart.clone().add(6, 'days');
      }
    } else if (challenge.schedule_type === 'specific_date') {
      periodStart = periodStart.date(challenge.schedule_days[0]);
      periodEnd = periodStart.clone();
    } else {
      periodEnd = periodStart.clone().add(1, periodLength).subtract(1, 'day');
    }

    return `${periodStart.format('MMM D')} - ${periodEnd.format('MMM D')}`;
  };


  const getScheduleDescription = (challenge) => {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const weekOptions = ['First', 'Second', 'Third', 'Fourth', 'Last'];
    const durationType = challenge.duration_type || 'weekly';

    if (challenge.schedule_type === 'specific_weekday') {
      const weekDescription = durationType === 'monthly' 
        ? `${weekOptions[challenge.schedule_week === -1 ? 4 : challenge.schedule_week]} ` 
        : '';
      const days = (challenge.schedule_days || []).map(day => weekdays[day]).join(', ');
      return `Check-ins on ${weekDescription}${days}`;
    } else if (challenge.schedule_type === 'specific_date') {
      return `Monthly check-ins on date(s): ${challenge.schedule_days?.join(', ') || 'scheduled dates'}`;
    }
    return `Regular ${durationType} check-ins`;
  };


  const getProgressPercentage = (challenge) => {
    const progress = challenge.period_progress || challenge.weekly_progress || [];
    if (progress.length === 0) return 0;
    const completedPeriods = progress.filter(period => period === 'completed').length;
    return Math.round((completedPeriods / progress.length) * 100); // Round to nearest whole number
  };



  const handleReflectionChange = (challengeId, periodIndex, text) => {
    setReflections(prev => ({
      ...prev,
      [challengeId]: {
        ...prev[challengeId],
        [periodIndex]: text
      }
    }));
  };


  const toggleExpandChallenge = (challengeId) => {
    console.log('=== Toggle Expand Challenge ===');
    console.log('Current expanded challenge:', expandedChallenge);
    console.log('Toggling challenge:', challengeId);
    console.log('Challenge data:', challenges.find(c => c.id === challengeId));
    console.log('Current weekly reflections:', weeklyReflections[challengeId]);
    
    const newExpandedState = expandedChallenge === challengeId ? null : challengeId;
    console.log('New expanded state will be:', newExpandedState);
    
    setExpandedChallenge(newExpandedState);
  };


  useEffect(() => {
    console.log('=== Expanded Challenge Changed ===', {
      previousValue: expandedChallenge,
      challenges: challenges?.map(c => c.id)
    });

    const fetchWeeklyReflections = async (challengeId) => {
      if (!challengeId || !userId) {
        console.log('Early return - missing data:', { challengeId, userId });
        return;
      }
      
      const challenge = challenges.find(c => c.id === challengeId);
      if (!challenge) {
        console.error('=== Challenge Not Found ===', {
          challengeId,
          availableChallenges: challenges.map(c => c.id)
        });
        return;
      }

      const endpoint = challenge.group_id 
        ? API_ENDPOINTS.GROUP_CHALLENGE_REFLECTIONS
            .replace(':groupId', challenge.group_id)
            .replace(':challengeId', challengeId) 
        : `${API_ENDPOINTS.CHALLENGES}/${userId}/reflections/${challengeId}`;

      console.log('=== Reflection Fetch Attempt ===', {
        endpoint,
        challenge: {
          id: challenge.id,
          group_id: challenge.group_id,
          reflections: challenge.period_reflections
        }
      });

      try {
        console.log('Making API request to:', endpoint);
        const response = await axios.get(endpoint);

        console.log('API Response:', response.data);
        
        if (response.data?.reflections) {
          console.log('Processing reflections:', response.data.reflections);
          setWeeklyReflections(prev => {
            const updated = {
              ...prev,
              [challengeId]: response.data.reflections
            };
            console.log('Updated reflections state:', updated);
            return updated;
          });
        } else {
          console.warn('No reflections data in response:', response.data);
        }
      } catch (error) {
        console.error('Reflection fetch error:', {
          error,
          message: error.message,
          status: error.response?.status,
          data: error.response?.data
        });
      }
    };

    if (expandedChallenge) {
      console.log('Challenge expanded, initiating fetch:', expandedChallenge);
      fetchWeeklyReflections(expandedChallenge);
    } else {
      console.log('No expanded challenge, skipping fetch');
    }

  }, [expandedChallenge, userId, challenges]);





  const toggleShowAllReflections = (challengeId, periodIndex) => {
    setShowAllReflections(prev => ({
      ...prev,
      [challengeId]: {
        ...(prev[challengeId] || {}),
        [periodIndex]: !(prev[challengeId]?.[periodIndex])
      }
    }));
  };



  const handlePeriodUpdate = async (challengeId, periodIndex, reflection, completed, isAdditionalReflection = false) => {
      console.log('=============== handlePeriodUpdate Started ===============');
      console.log('Parameters:', { challengeId, periodIndex, reflection, completed, isAdditionalReflection });
      
      if (loading[challengeId]) {
          console.log('Loading in progress for challenge, returning early');
          return;
      }

      try {
          setLoading(prev => ({ ...prev, [challengeId]: true }));
          
          const reflectionText = reflection || reflections[challengeId]?.[periodIndex] || '';
          if (!reflectionText.trim()) {
              alert("Please provide a reflection before checking in.");
              return;
          }

          const challenge = challenges.find(c => c.id === challengeId);
          if (!challenge) return;

          const isGroupChallenge = Boolean(challenge.group_id);
          console.log('Is group challenge:', isGroupChallenge);

          // Handle group challenges differently
          if (isGroupChallenge) {
              try {
                  const groupResponse = await axios.post(
                      API_ENDPOINTS.GROUP_CHALLENGE_CHECKIN
                          .replace(':groupId', challenge.group_id)
                          .replace(':challengeId', challengeId),
                      {
                          user_id: userId,
                          period_index: periodIndex,
                          reflection: reflectionText.trim(),
                          completed: completed,
                          is_additional: isAdditionalReflection,
                          timestamp: new Date().toISOString()
                      }
                  );

                  setWeeklyReflections(prev => ({
                      ...prev,
                      [challengeId]: {
                          ...(prev[challengeId] || {}),
                          [periodIndex]: groupResponse.data.period_reflections
                      }
                  }));

                  // Update challenges
                  const updatedChallenges = challenges.map(c => {
                      if (c.id === challengeId) {
                          return {
                              ...c,
                              period_progress: groupResponse.data.period_progress,
                              period_reflections: {
                                  ...c.period_reflections,
                                  [periodIndex]: groupResponse.data.period_reflections
                              }
                          };
                      }
                      return c;
                  });

                  await onChallengeUpdate(updatedChallenges);

                  // Clear reflection input
                  setReflections(prev => ({
                      ...prev,
                      [challengeId]: { ...prev[challengeId], [periodIndex]: '' }
                  }));

                  if (shareAsComment[`${challengeId}-${periodIndex}`]) {
                      await axios.post(
                          API_ENDPOINTS.CREATE_CHALLENGE_COMMENT
                              .replace(':groupId', challenge.group_id)
                              .replace(':challengeId', challengeId),
                          {
                              content: reflectionText,
                              period_index: periodIndex,
                              completed: completed,
                              check_in_type: isAdditionalReflection ? 'additional' : 'scheduled',
                              period_type: challenge.duration_type,
                              check_in_date: moment().format('YYYY-MM-DD')
                          }
                      );
                      
                      setShareAsComment(prev => ({
                          ...prev,
                          [`${challengeId}-${periodIndex}`]: false
                      }));
                  }

                  alert('Check-in recorded successfully!');
                  return;
              } catch (error) {
                  console.error('Error in group challenge check-in:', error);
                  throw new Error('Failed to submit group challenge reflection');
              }
          }

          // Regular challenge handling with streaming
          const sectionMap = {
              'Personal Response': 'Understanding',
              'Today\'s Light': 'Scripture',
              'Spiritual Direction': 'Spiritual',
              'Brief Prayer': 'Prayer',
              'Reflection Question': 'Growth'
          };

          setSectionBuffer({});
          setFeedbackModal({
              isOpen: true,
              sections: {
                  Understanding: { content: '', icon: '🌱', complete: false },
                  Scripture: { content: '', icon: '📖', complete: false },
                  Spiritual: { content: '', icon: '✝️', complete: false },
                  Growth: { content: '', icon: '🌟', complete: false },
                  Prayer: { content: '', icon: '🙏', complete: false }
              },
              currentSection: '',
              isComplete: false,
              nextCheckIn: null
          });

          console.log('Sending reflection payload:', {
              user_id: userId,
              period_index: periodIndex,
              reflection: reflectionText.trim(),
              completed: completed,
              is_additional: isAdditionalReflection
          });

          const response = await fetch(
              API_ENDPOINTS.CHALLENGE_REFLECTION.replace(':challengeId', challengeId),
              {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                      user_id: userId,
                      period_index: periodIndex,
                      reflection: reflectionText.trim(),
                      completed: completed,
                      is_additional: isAdditionalReflection
                  })
              }
          );

          let hasCompletedData = false;
          const reader = response.body.getReader();
          let currentSection = '';

          while (true) {
              const { done, value } = await reader.read();
              
              if (done) {
                  console.log('Stream completed, hasCompletedData:', hasCompletedData);
                  break;
              }

              const chunk = new TextDecoder().decode(value);
              console.log('Received chunk:', chunk);
              
              const lines = chunk.split('\n').filter(line => line.trim());

              for (const line of lines) {
                  if (!line.startsWith('data: ')) continue;
                  
                  const data = JSON.parse(line.slice(6));
                  console.log('Processing stream data:', data.type, data);

                  if (data.type === 'stream_end') {
                      console.log('Stream ended successfully');
                      if (!hasCompletedData) {
                          throw new Error('Stream ended without completion data');
                      }
                      break;
                  }
                  else if (data.type === 'content') {
                      const content = data.content;
                      
                      if (content.startsWith('# ')) {
                          const sectionTitle = content.replace('# ', '').split(' ')[0];
                          const mappedSection = sectionMap[sectionTitle] || sectionTitle;
                          
                          console.log('Starting new section:', {
                              original: sectionTitle,
                              mapped: mappedSection,
                              previousContent: sectionBuffer
                          });

                          if (currentSection) {
                              setFeedbackModal(prev => ({
                                  ...prev,
                                  sections: {
                                      ...prev.sections,
                                      [currentSection]: {
                                          ...prev.sections[currentSection],
                                          complete: true,
                                          content: sectionBuffer[currentSection] || prev.sections[currentSection]?.content || ''
                                      }
                                  }
                              }));
                          }

                          currentSection = mappedSection;
                          setSectionBuffer(prev => ({
                              ...prev,
                              [mappedSection]: ''
                          }));

                          setFeedbackModal(prev => ({
                              ...prev,
                              currentSection: mappedSection,
                              sections: {
                                  ...prev.sections,
                                  [mappedSection]: {
                                      ...prev.sections[mappedSection],
                                      content: '',
                                      complete: false
                                  }
                              }
                          }));
                      } else if (currentSection) {
                          setSectionBuffer(prev => {
                              const updatedContent = (prev[currentSection] || '') + content;
                              return {
                                  ...prev,
                                  [currentSection]: updatedContent
                              };
                          });

                          setFeedbackModal(prev => ({
                              ...prev,
                              sections: {
                                  ...prev.sections,
                                  [currentSection]: {
                                      ...prev.sections[currentSection],
                                      content: (prev.sections[currentSection]?.content || '') + content,
                                      complete: false
                                  }
                              }
                          }));
                      }
                  }
                  else if (data.type === 'complete') {
                      hasCompletedData = true;
                      console.log('Processing completion data:', data.data);

                      setWeeklyReflections(prev => ({
                          ...prev,
                          [challengeId]: {
                              ...(prev[challengeId] || {}),
                              [periodIndex]: Array.isArray(data.data.period_reflections) 
                                  ? data.data.period_reflections 
                                  : Object.values(data.data.period_reflections || {})
                          }
                      }));

                      setReflections(prev => ({
                          ...prev,
                          [challengeId]: { ...prev[challengeId], [periodIndex]: '' }
                      }));

                      const updatedChallenges = challenges.map(c => {
                          if (c.id === challengeId) {
                              return {
                                  ...c,
                                  period_progress: data.data.period_progress,
                                  period_reflections: {
                                      ...c.period_reflections,
                                      [periodIndex]: Array.isArray(data.data.period_reflections)
                                          ? data.data.period_reflections
                                          : Object.values(data.data.period_reflections || {})
                                  },
                                  current_period: data.data.current_period,
                                  next_check_in: data.data.next_check_in
                              };
                          }
                          return c;
                      });

                      console.log('Updating challenges:', updatedChallenges);
                      await onChallengeUpdate(updatedChallenges);

                      if (data.data.challengeCompleted) {
                          alert('Challenge completed! Please review and select your next challenge.');
                          onInitiateNextChallenges?.();
                      }

                      setFeedbackModal(prev => {
                          const updatedSections = {};
                          Object.entries(prev.sections).forEach(([key, section]) => {
                              updatedSections[key] = {
                                  ...section,
                                  content: sectionBuffer[key] || section.content || '',
                                  complete: true
                              };
                          });

                          return {
                              ...prev,
                              isComplete: true,
                              nextCheckIn: data.data.next_check_in ? 
                                  moment(data.data.next_check_in).format('MMM D, YYYY') : 
                                  null,
                              sections: updatedSections
                          };
                      });
                  }
              }
          }

          if (!hasCompletedData) {
              throw new Error('Did not receive completion data from server');
          }

      } catch (error) {
          console.error('Error in handlePeriodUpdate:', error);
          alert('Failed to check in: ' + (error.message || 'Unknown error'));
          setFeedbackModal(prev => ({ ...prev, isOpen: false }));
      } finally {
          setLoading(prev => ({ ...prev, [challengeId]: false }));
      }
  };





  // Make sure to add this line at component level to track renders
  console.log('=============== ChallengesDashboard Render ===============');
    

  const renderWeeklyReflections = (challenge, index) => {
      console.log('=== Rendering Weekly Reflections ===', {
          challengeId: challenge.id,
          index,
          reflections: challenge.period_reflections?.[index]
      });

      // Get reflections from the period_reflections object using the index as key
      const periodReflections = challenge.period_reflections?.[index] || [];
      
      // Ensure we're working with an array
      const reflectionsArray = Array.isArray(periodReflections) ? 
          periodReflections : 
          [periodReflections];

      if (reflectionsArray.length === 0) {
          return null;
      }

      const showAll = showAllReflections[challenge.id]?.[index];
      const reflectionsToShow = showAll ? reflectionsArray : [reflectionsArray[reflectionsArray.length - 1]];

      return (
          <div className="weekly-reflections">
              <h6 className="reflections-header">
                  <FaList /> Reflections this {
                      challenge.duration_type?.toLowerCase() === 'weekly' 
                          ? 'week' 
                          : challenge.duration_type?.toLowerCase() === 'monthly'
                              ? 'month'
                              : challenge.duration_type
                  }
                  {reflectionsArray.length > 1 && (
                      <button 
                          onClick={() => toggleShowAllReflections(challenge.id, index)}
                          className="toggle-reflections-button"
                      >
                          {showAll ? 'Show Less' : `Show All (${reflectionsArray.length})`}
                      </button>
                  )}
              </h6>

              <div className="reflections-list">
                  {reflectionsToShow.map((reflection, idx) => {
                      // Each reflection should have text, timestamp, and is_additional properties
                      if (!reflection || !reflection.text) {
                          return null;
                      }

                      return (
                          <div 
                              key={`${challenge.id}-${index}-${reflection.timestamp || idx}`} 
                              className={`reflection-entry ${reflection.is_additional ? 'additional' : ''}`}
                          >
                              {reflection.timestamp && (
                                  <small className="reflection-date">
                                      {moment(reflection.timestamp).format('MMM D, YYYY h:mm A')}
                                  </small>
                              )}
                              <div className="reflection-text">
                                  {reflection.text}
                              </div>
                              {reflection.is_additional && (
                                  <span className="additional-reflection-badge">
                                  </span>
                              )}
                          </div>
                      );
                  })}
              </div>
          </div>
      );
  };



  const processAndGroupChallenges = () => {
    try {
      const groupChallenges = challenges.filter(c => c.group_id);
      const enrichedGroupChallenges = groupChallenges.map(challenge => ({
        ...challenge,
        isGroupChallenge: true,
        category: 'Group Challenges',
        title: challenge.title || challenge.description,
        group_name: groupNames[challenge.group_id] || 'Group Challenge'
      }));

      const regularChallenges = challenges.filter(c => !c.group_id);
      const filteredRegularChallenges = regularChallenges.filter(challenge => 
        challenge.category !== 'Custom' || !personalGoals.some(goal => goal.id === challenge.id)
      );

      const allChallenges = [...enrichedGroupChallenges, ...filteredRegularChallenges, ...personalGoals];
      return allChallenges.reduce((acc, challenge) => {
        const category = challenge.isGroupChallenge ? 'Group Challenges' : challenge.category;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(challenge);
        return acc;
      }, {});
    } catch (error) {
      console.error('Error processing challenges:', error);
      return { 'General Challenges': challenges };
    }
  };


  const formatPromptText = (text) => {
      if (!text) return null;
      
      // Handle array input
      if (Array.isArray(text)) {
          text = text.join('\n\n');
      }

      // Handle object input
      if (typeof text === 'object' && text !== null) {
          text = text.text || text.content || text.toString();
      }

      const sections = text.split('\n\n');
      
      return sections.map((section, idx) => {
          if (section.includes('Reflection Points:')) {
              const [header, ...points] = section.split('-');
              return (
                  <div key={idx} className="dashboard-reflection-points">
                      <p className="dashboard-reflection-header">
                          <span className="dashboard-header-icon">🤔</span>
                          {header.trim()}
                      </p>
                      <ul className="dashboard-points-list">
                          {points.map((point, pointIdx) => {
                              // Ensure point is a string
                              const pointText = typeof point === 'object' 
                                  ? (point.text || point.content || '') 
                                  : String(point);
                              return (
                                  <li key={pointIdx}>{pointText.trim()}</li>
                              );
                          })}
                      </ul>
                  </div>
              );
          }
          
          // Ensure section is a string
          const sectionText = typeof section === 'object' 
              ? (section.text || section.content || '') 
              : String(section);
              
          return (
              <div key={idx} className="dashboard-prompt-text">
                  {sectionText.trim()}
              </div>
          );
      });
  };



  const renderChallenge = (challenge) => {
    console.log('=== Rendering Challenge ===', {
      challengeId: challenge.id,
      isExpanded: expandedChallenge === challenge.id,
      currentReflections: weeklyReflections[challenge.id],
      challengeData: challenge
    });
    
    const isCustomGoal = challenge.goalType === 'personal-goal';
    const isGroupChallenge = Boolean(challenge.group_id) || challenge.isGroupChallenge;
    const totalPeriods = challenge.duration_periods || 4;
    const periodType = challenge.duration_type === 'weekly' ? 'Week' : 'Month';
    
    let prompts = [];
    const periodLabel = challenge.duration_type === 'weekly' ? 'week' : 'month';
    if (challenge.period_prompts) {
      prompts = typeof challenge.period_prompts === 'string' 
        ? JSON.parse(challenge.period_prompts) 
        : challenge.period_prompts;
    } else if (challenge.weekly_prompts) {
      prompts = challenge.weekly_prompts;
    } else if (challenge.id === 'sample-challenge') {
      prompts = challenge.period_prompts;
    } else {
      prompts = Array(totalPeriods).fill(`Reflect on your progress this ${periodLabel}`);
    }

    const progress = challenge.period_progress || challenge.weekly_progress || Array(totalPeriods).fill('not_started');

    return (
      <div className="dashboard-challenge-card">
        <div className="dashboard-card-header" 
          onClick={() => {
            console.log('=== Challenge Header Clicked ===', {
              challengeId: challenge.id,
              currentExpandedState: expandedChallenge,
              hasReflections: Boolean(weeklyReflections[challenge.id])
            });
            setExpandedChallenge(expandedChallenge === challenge.id ? null : challenge.id);
          }}>
          {isGroupChallenge && (
            <div className="dashboard-group-badge">
              <FaUsers /> {challenge.group_name || groupNames[challenge.group_id] || 'Group Challenge'}
            </div>
          )}
          <h4 className="dashboard-title">
            {challenge.title || challenge.description}
          </h4>
          <div className="dashboard-header-meta">
            <div className="dashboard-progress-wrapper">
              <div className="dashboard-progress">
                <div 
                  className="dashboard-progress-bar" 
                  style={{ width: `${getProgressPercentage(challenge)}%` }} 
                />
              </div>
              <span className="dashboard-progress-text">
                {getProgressPercentage(challenge)}% Complete
              </span>
            </div>
            <div className="dashboard-challenge-type">
              {challenge.duration_type === 'weekly' ? 'Weekly' : 'Monthly'}
            </div>
          </div>
        </div>

        <div className="challenge-details">
          {Array.from({ length: totalPeriods }).map((_, index) => {
            const prompt = prompts[index] || `Reflect on your progress this ${periodType}`;
            const isCompleted = progress[index] === 'completed';
            console.log(`=== Rendering Period ${index} ===`, {
              challengeId: challenge.id,
              periodReflections: weeklyReflections[challenge.id]?.[index],
              isCompleted
            });

            return (
              <div key={`${challenge.id}-period-${index}`} className="period-prompt">
                <h5>
                  {challenge.duration_type === 'weekly' ? 'Week' : 'Month'} {index + 1}
                  {challenge.period_dates?.[index] && 
                    ` (${formatDate(challenge.period_dates[index].start)} - ${formatDate(challenge.period_dates[index].end)})`
                  }
                </h5>
                <div className="prompt-content">
                  {formatPromptText(prompt)}
                </div>
                {renderWeeklyReflections(challenge, index)}

                {(isCompleted || index <= progress.findIndex(p => p !== 'completed')) && (
                  <div className="new-reflection-section">
                    <textarea 
                      placeholder="Share your reflection..."
                      value={reflections[challenge.id]?.[index] || ''}
                      onChange={(e) => handleReflectionChange(challenge.id, index, e.target.value)}
                      className="reflection-input"
                    />

                    {isGroupChallenge && (
                      <div className="share-options">
                        <label className="share-checkbox-label">
                          <input
                            type="checkbox"
                            checked={shareAsComment[`${challenge.id}-${index}`] || false}
                            onChange={(e) => setShareAsComment(prev => ({
                              ...prev,
                              [`${challenge.id}-${index}`]: e.target.checked
                            }))}
                            className="share-checkbox"
                          />
                          Share this reflection in group comments
                        </label>
                      </div>
                    )}

                    <button 
                      onClick={() => {
                        console.log('=== Handling Period Update ===', {
                          challengeId: challenge.id,
                          periodIndex: index,
                          hasReflection: Boolean(reflections[challenge.id]?.[index]),
                          isCompleted
                        });
                        handlePeriodUpdate(
                          challenge.id,
                          index,
                          reflections[challenge.id]?.[index] || '',
                          !isCompleted,
                          isCompleted
                        );
                      }}
                      className={`check-in-button ${isCompleted ? 'additional-reflection' : ''}`}
                      disabled={loading[challenge.id]}
                    >
                      {loading[challenge.id] ? (
                        <>
                          <FaSpinner className="fa-spin" /> Loading...
                        </>
                      ) : (
                        <>
                          {isCompleted ? (
                            <>
                              <FaPlusCircle /> Add Another Reflection
                            </>
                          ) : (
                            <>
                              <FaCalendarCheck /> Check-in
                            </>
                          )}
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const groupedChallenges = processAndGroupChallenges();

  return (
    <div className="dashboard-challenges">
      <div className="dashboard-main-header">
        <div className="dashboard-title-section">
          <FaChartLine className="dashboard-section-icon" />
          <h2>Your Faith Journey Activities</h2>
        </div>
        {(!challenges?.length && !personalGoals?.length) && (
          <div className="dashboard-empty-message">
            <p>Select your first faith activity below to begin your journey.</p>
            <div className="dashboard-divider"></div>
          </div>
        )}
      </div>

      {(challenges?.length > 0 || personalGoals?.length > 0) && (
        <div className="dashboard-categories">
          {groupedChallenges['Group Challenges']?.length > 0 && (
            <div key="Group Challenges" className="dashboard-category">
              <h3><FaUsers /> Group Activities</h3>
              <div className="dashboard-items-list">
                {groupedChallenges['Group Challenges'].map(challenge => renderChallenge(challenge))}
              </div>
            </div>
          )}
          
          {Object.entries(groupedChallenges)
            .filter(([category]) => category !== 'Group Challenges')
            .map(([category, categoryChallenges]) => (
              <div key={category} className="dashboard-category">
                <h3>
                  <FaTasks /> {category}
                  {category !== 'General Challenges' && (
                    <p className="dashboard-level">Level: {userLevels[category] || 'Custom Goal'}</p>
                  )}
                </h3>
                <div className="dashboard-items-list">
                  {categoryChallenges.map(challenge => renderChallenge(challenge))}
                </div>
              </div>
            ))}
        </div>
      )}
      <SpiritualFeedbackModal
          isOpen={feedbackModal.isOpen}
          sections={feedbackModal.sections}
          currentSection={feedbackModal.currentSection}
          isComplete={feedbackModal.isComplete}
          nextCheckIn={feedbackModal.nextCheckIn}
          onClose={() => setFeedbackModal(prev => ({ ...prev, isOpen: false }))}
      />

    </div>
  );

};

export default ChallengesDashboard;

