import React, { useState, useRef, useEffect } from 'react';
import { Mic, Square } from 'lucide-react';
import { API_ENDPOINTS } from '../../config/api';





const RealtimeVoiceControls = ({ effectiveUserId, context }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(null);
  const webSocket = useRef(null);
  const audioContext = useRef(null);
  const streamRef = useRef(null);
  const processorNode = useRef(null);
  const sourceNode = useRef(null);

  const cleanupResources = () => {
    console.log('Cleaning up resources...');
    if (audioContext.current) {
      console.log('Closing audio context');
      audioContext.current.close().catch(console.error);
      audioContext.current = null;
    }

    if (processorNode.current) {
      console.log('Disconnecting processor node');
      processorNode.current.disconnect();
      processorNode.current = null;
    }

    if (sourceNode.current) {
      console.log('Disconnecting source node');
      sourceNode.current.disconnect();
      sourceNode.current = null;
    }

    if (streamRef.current) {
      console.log('Stopping media stream tracks');
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }

    if (webSocket.current?.readyState === WebSocket.OPEN) {
      try {
        console.log('Sending end_stream message');
        webSocket.current.send(JSON.stringify({ 
          type: 'end_stream',
          timestamp: Date.now()
        }));
        console.log('Closing WebSocket connection');
        webSocket.current.close();
      } catch (error) {
        console.error('Error in cleanup:', error);
      }
    }
    webSocket.current = null;
    setIsRecording(false);
    console.log('Cleanup complete');
  };

  useEffect(() => {
    return () => {
      console.log('Component unmounting, cleaning up...');
      cleanupResources();
    };
  }, []);


  const initializeAudio = async () => {
      try {
          // First check for microphone access and availability
          console.log('Testing microphone access...');
          const devices = await navigator.mediaDevices.enumerateDevices();
          const audioDevices = devices.filter(device => device.kind === 'audioinput');
          console.log('Available audio input devices:', audioDevices);

          if (audioDevices.length === 0) {
              throw new Error('No microphone found. Please connect a microphone and try again.');
          }

          console.log('Initializing audio system...');
          const stream = await navigator.mediaDevices.getUserMedia({
              audio: {
                  echoCancellation: true,
                  noiseSuppression: true,
                  autoGainControl: true,
                  channelCount: 1,
                  sampleRate: 24000,  // Add this to match OpenAI's requirements
                  sampleSize: 16      // Add this for 16-bit PCM
              }
          });

          // Verify microphone is actually capturing audio
          const audioTracks = stream.getAudioTracks();
          if (audioTracks.length === 0) {
              throw new Error('No audio track available in the stream');
          }

          const track = audioTracks[0];
          if (!track.enabled || track.muted) {
              throw new Error('Microphone is disabled or muted');
          }

          // Log detailed track information for debugging
          console.log('Audio tracks:', audioTracks);
          console.log('Audio track settings:', track.getSettings());
          console.log('Audio track constraints:', track.getConstraints());
          console.log('Audio track enabled:', track.enabled);
          console.log('Audio track muted:', track.muted);
          
          streamRef.current = stream;
          audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
          await audioContext.current.resume();
          console.log('Audio context created and resumed:', audioContext.current.state);

          sourceNode.current = audioContext.current.createMediaStreamSource(stream);
          console.log('Source node created');

          processorNode.current = audioContext.current.createScriptProcessor(4096, 1, 1);
          console.log('Processor node created with buffer size 4096');

          sourceNode.current.connect(processorNode.current);
          processorNode.current.connect(audioContext.current.destination);
          console.log('Audio nodes connected');

          // Add quick audio level check to verify microphone is picking up sound
          const analyzerNode = audioContext.current.createAnalyser();
          sourceNode.current.connect(analyzerNode);
          analyzerNode.fftSize = 2048;
          
          const dataArray = new Float32Array(analyzerNode.frequencyBinCount);
          analyzerNode.getFloatTimeDomainData(dataArray);
          
          // Check if we're getting any audio signal
          const audioLevel = dataArray.reduce((sum, value) => sum + Math.abs(value), 0) / dataArray.length;
          console.log('Initial audio level:', audioLevel);

          if (audioLevel < 0.0001) {
              console.warn('Warning: Very low audio levels detected. Microphone might not be capturing properly.');
          }

          return true;
      } catch (error) {
          console.error('Audio initialization error:', error);
          console.error('Error details:', {
              name: error.name,
              message: error.message,
              stack: error.stack
          });
          throw new Error(`Failed to initialize audio system: ${error.message}`);
      }
  };




  const startRecording = async () => {
    try {
      console.log('Starting recording...');
      cleanupResources();
      await initializeAudio();

      console.log('Creating WebSocket connection to:', API_ENDPOINTS.SPIRITUAL_DIRECTION_VOICE);
      webSocket.current = new WebSocket(API_ENDPOINTS.SPIRITUAL_DIRECTION_VOICE);

      webSocket.current.onmessage = async (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received WebSocket message:', data.type);
          
          switch (data.type) {
            case 'audio_delta':
              console.log('Received audio delta, length:', data.delta.audio.length);
              if (audioContext.current?.state === 'running') {
                await playAudioResponse(data.delta.audio);
              }
              break;
            case 'text_delta':
              console.log('Transcript:', data.delta.text);
              break;
            case 'error':
              console.error('Received error from server:', data.error);
              setError(data.error);
              cleanupResources();
              break;
            case 'input_audio_buffer.speech_started':
              console.log('User started speaking');
              break;
            case 'input_audio_buffer.speech_stopped':
              console.log('User stopped speaking');
              break;
          }
        } catch (error) {
          console.error('Error processing message:', error);
          setError('Error processing response');
        }
      };

      webSocket.current.onopen = async () => {
        try {
          console.log('WebSocket connection opened');
          const initialConfig = {
            user_id: effectiveUserId,
            category: context?.current_topic || null,
            context: context,
            input_audio_format: "pcm16"  // Add this

          };
          console.log('Sending initial configuration:', initialConfig);
          await webSocket.current.send(JSON.stringify(initialConfig));

          processorNode.current.onaudioprocess = (e) => {
            if (webSocket.current?.readyState === WebSocket.OPEN) {
              const inputData = e.inputBuffer.getChannelData(0);
              
              // Add audio level monitoring
              const audioLevel = inputData.reduce((acc, val) => acc + Math.abs(val), 0) / inputData.length;
              console.log(`Audio level: ${audioLevel.toFixed(4)}`);
              
              if (audioLevel > 0.01) {
                console.log('Audio input detected above threshold');
              }
              
              const audioBuffer = convertFloat32ToInt16(inputData);
              const base64Audio = btoa(String.fromCharCode(...new Uint8Array(audioBuffer)));
              
              console.log(`Audio buffer metrics - Raw samples: ${inputData.length}, Converted size: ${audioBuffer.byteLength} bytes`);
              
              webSocket.current.send(JSON.stringify({
                type: 'input_audio_buffer.append',
                audio: base64Audio
              }));
            }
          };
          
          setIsRecording(true);
          setError(null);
          console.log('Recording started successfully');
        } catch (error) {
          console.error('Error setting up audio:', error);
          setError('Error setting up audio capture');
          cleanupResources();
        }
      };

      webSocket.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setError('Connection error occurred');
        cleanupResources();
      };

      webSocket.current.onclose = () => {
        console.log('WebSocket connection closed');
        cleanupResources();
      };

    } catch (error) {
      console.error('Setup error:', error);
      setError(error.message);
      cleanupResources();
    }
  };

  const stopRecording = () => {
    console.log('Stopping recording...');
    cleanupResources();
  };

  const playAudioResponse = async (base64Audio) => {
    if (!audioContext.current) return;

    try {
      console.log('Playing audio response...');
      const audioData = atob(base64Audio);
      const arrayBuffer = new ArrayBuffer(audioData.length);
      const view = new Uint8Array(arrayBuffer);
      for (let i = 0; i < audioData.length; i++) {
        view[i] = audioData.charCodeAt(i);
      }
      
      const audioBuffer = await audioContext.current.decodeAudioData(arrayBuffer);
      console.log('Audio buffer decoded, duration:', audioBuffer.duration);
      
      const source = audioContext.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.current.destination);
      source.start();
      console.log('Audio playback started');
      
      return new Promise((resolve) => {
        source.onended = () => {
          console.log('Audio playback ended');
          resolve();
        };
      });
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  const convertFloat32ToInt16 = (float32Array) => {
    const buffer = new ArrayBuffer(float32Array.length * 2);
    const view = new DataView(buffer);
    for (let i = 0; i < float32Array.length; i++) {
      const s = Math.max(-1, Math.min(1, float32Array[i]));
      view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
    return buffer;
  };

  return (
    <button
      onClick={isRecording ? stopRecording : startRecording}
      className={`p-2 rounded-full ${
        isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
      } text-white transition-colors`}
      aria-label={isRecording ? 'Stop recording' : 'Start recording'}
    >
      {isRecording ? <Square className="w-4 h-4" /> : <Mic className="w-4 h-4" />}
      {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
    </button>
  );
};

export default RealtimeVoiceControls;