import React, { useState, useEffect } from 'react';
import { Clock, Calendar, Plus, X, Check, Edit2 } from 'lucide-react';
import { API_ENDPOINTS } from "../../config/api";

import axios from 'axios';
import './CallSchedulerManager.css';



const CallScheduleManager = ({ userId }) => {

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const [schedule, setSchedule] = useState(
    daysOfWeek.map(day => ({
      day,
      isSelected: false,
      times: []
    }))
  );
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    if (userId) {
      fetchSchedule();
    }
  }, [userId]);

  const fetchSchedule = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_ENDPOINTS.GET_SCHEDULE}/${userId}`);
      
      if (response.data.success && response.data.schedule) {
        // Transform received schedule into our format
        const savedSchedule = response.data.schedule;
        setSchedule(daysOfWeek.map(day => {
          const savedDay = savedSchedule.find(s => s.day === day);
          return {
            day,
            isSelected: Boolean(savedDay),
            times: savedDay?.times || []
          };
        }));
      }
    } catch (err) {
      setError('Failed to load schedule');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const saveSchedule = async () => {
    try {
      setLoading(true);
      // Format schedule data for saving
      const scheduleData = schedule
        .filter(item => item.times.length > 0)
        .map(item => ({
          day: item.day,
          times: item.times
        }));

      const response = await axios.post(API_ENDPOINTS.SAVE_SCHEDULE, {
        user_id: userId,
        schedule: scheduleData
      });

      if (response.data.success) {
        setIsEditing(false);
        fetchSchedule(); // Refresh the schedule
      }
    } catch (err) {
      setError('Failed to save schedule');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const addTimeToDay = (day) => {
    setSchedule(currentSchedule =>
      currentSchedule.map(item => {
        if (item.day === day && item.times.length < 3) {
          return {
            ...item,
            times: [...item.times, '']
          };
        }
        return item;
      })
    );
  };

  const updateTimeForDay = (day, timeIndex, newTime) => {
    setSchedule(currentSchedule =>
      currentSchedule.map(item => {
        if (item.day === day) {
          const newTimes = [...item.times];
          newTimes[timeIndex] = newTime;
          return {
            ...item,
            times: newTimes
          };
        }
        return item;
      })
    );
  };

  const removeTimeFromDay = (day, timeIndex) => {
    setSchedule(currentSchedule =>
      currentSchedule.map(item => {
        if (item.day === day) {
          const newTimes = item.times.filter((_, i) => i !== timeIndex);
          return {
            ...item,
            times: newTimes,
            isSelected: newTimes.length > 0
          };
        }
        return item;
      })
    );
  };

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  return (
    <div className="schedule-manager">
      <div className="schedule-header">
        <h2>
          <Clock className="header-icon" />
          Your Call Schedule
        </h2>
        {!isEditing && schedule.some(day => day.times.length > 0) && (
          <button 
            onClick={() => setIsEditing(true)}
            className="edit-button"
          >
            <Edit2 /> Edit Schedule
          </button>
        )}
      </div>

      {isEditing || !schedule.some(day => day.times.length > 0) ? (
        <div className="schedule-editor">
          <div className="days-grid">
            {schedule.map((dayItem) => (
              <div 
                key={dayItem.day}
                className={`day-card ${dayItem.times.length > 0 ? 'selected' : ''}`}
              >
                <div className="day-header">
                  <span className="day-name">{dayItem.day}</span>
                  {dayItem.times.length < 3 && (
                    <button 
                      onClick={() => addTimeToDay(dayItem.day)}
                      className="add-time-button"
                    >
                      <Plus />
                    </button>
                  )}
                </div>
                
                <div className="day-times">
                  {dayItem.times.map((time, index) => (
                    <div key={index} className="time-input-group">
                      <input
                        type="time"
                        value={time}
                        onChange={(e) => updateTimeForDay(dayItem.day, index, e.target.value)}
                        className="time-input"
                      />
                      <button 
                        onClick={() => removeTimeFromDay(dayItem.day, index)}
                        className="remove-time"
                      >
                        <X />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="schedule-actions">
            <button onClick={saveSchedule} className="save-button">
              <Check /> Save Schedule
            </button>
            {isEditing && (
              <button 
                onClick={() => {
                  setIsEditing(false);
                  fetchSchedule();
                }} 
                className="cancel-button"
              >
                <X /> Cancel
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="schedule-display">
          {schedule.map(dayItem => 
            dayItem.times.length > 0 && (
              <div key={dayItem.day} className="schedule-day">
                <strong>{dayItem.day}:</strong>
                <div className="times-list">
                  {dayItem.times.map((time, index) => {
                    const [hours, minutes] = time.split(':');
                    const period = hours >= 12 ? 'PM' : 'AM';
                    const displayHours = hours % 12 || 12;
                    return (
                      <span key={index} className="time-slot">
                        {`${displayHours}:${minutes} ${period}`}
                      </span>
                    );
                  })}
                </div>
              </div>
            )
          )}
        </div>
      )}

      {error && (
        <div className="schedule-error">
          {error}
        </div>
      )}
    </div>
  );
};


export default CallScheduleManager;