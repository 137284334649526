import React from "react";
import { useNavigate } from "react-router-dom";
import { FiCheck, FiChevronRight } from "react-icons/fi";
import "./Pricing.css";
import PublicLayout from "../../components/PublicLayout/PublicLayout";

const Pricing = () => {
  const navigate = useNavigate();

  const handleParishDemo = () => {
    navigate("/demo");
  };

  const individualFeatures = [
    "Personal Spiritual Direction AI",
    "Scripture Reflection Tools",
    "Daily Prayer Guidance",
    "Weekly & Monthly Activities",
    "Public Group Access",
    "Personalized Growth Path",
    "Assessment-Based Guidance",
    "Progress Tracking",
  ];

  const parishFeatures = [
    "All Individual Features",
    "Ministry Group Creation",
    "Custom Ministry Activities",
    "Priest-Guided AI Responses",
    "Monthly Activity Reports",
    "Team Onboarding Support",
    "Parish Analytics Dashboard",
    "Dedicated Support Team",
  ];

  return (
    <PublicLayout
      className="pricing-container"
      render={({ setShowAssessment }) => (
        <>
          <section className="pricing-hero">
            <h1 className="pricing-title">Choose Your Spiritual Journey</h1>
            <p className="pricing-subtitle">
              Start with a free month of guided spiritual growth
            </p>
          </section>

          <section className="pricing-plans">
            <div className="pricing-grid">
              <div className="pricing-card individual">
                <div className="pricing-card-header">
                  <h2>Individual Plan</h2>
                  <div className="pricing-amount">
                    <span className="price">$8</span>
                    <span className="period">/month</span>
                  </div>
                  <p className="trial-info">1 Month Free Trial</p>
                </div>
                <div className="pricing-features">
                  {individualFeatures.map((feature, index) => (
                    <div key={index} className="pricing-feature">
                      <FiCheck className="feature-icon" />
                      <span>{feature}</span>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => setShowAssessment(true)}
                  className="pricing-cta individual-cta"
                >
                  Start Free Month <FiChevronRight className="cta-icon" />
                </button>
              </div>

              <div className="pricing-card parish">
                <div className="pricing-card-header">
                  <h2>Parish Plan</h2>
                  <div className="pricing-amount">
                    <span className="contact">Contact Us</span>
                  </div>
                  <p className="pricing-note">
                    Monthly rate plus addition for active users
                  </p>
                </div>
                <div className="pricing-features">
                  {parishFeatures.map((feature, index) => (
                    <div key={index} className="pricing-feature">
                      <FiCheck className="feature-icon" />
                      <span>{feature}</span>
                    </div>
                  ))}
                </div>
                <button
                  onClick={handleParishDemo}
                  className="pricing-cta parish-cta"
                >
                  Schedule Demo <FiChevronRight className="cta-icon" />
                </button>
              </div>
            </div>
          </section>

          <section className="pricing-support">
            <div className="support-content">
              <h3>Need Help Choosing?</h3>
              <p>
                Contact us to discuss your parish's specific needs and goals
              </p>
              <p>Email: Adrian@CrossValidation.ai</p>
              <p>Phone: (401)935-4878</p>
            </div>
          </section>
        </>
      )}
    />
  );
};

export default Pricing;