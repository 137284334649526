import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { 
  FaTrophy, 
  FaUserPlus, 
  FaCheckCircle, 
  FaRegCircle, 
  FaUsers,
  FaHeart,
  FaRegHeart,
  FaComment,
  FaChevronRight,
  FaChevronUp,
  FaChevronDown,
  FaCalendar,
  FaPlus
} from 'react-icons/fa';
import { API_ENDPOINTS } from '../../config/api';
import './GroupChallenges.css';

const GroupChallenges = ({ groupId, userId }) => {
  // Existing state
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [participatingChallenges, setParticipatingChallenges] = useState({});
  const [challengeParticipants, setChallengeParticipants] = useState({});
  const [expandedChallenges, setExpandedChallenges] = useState({});
  const [comments, setComments] = useState({});
  const [newComments, setNewComments] = useState({});
  const [commentLikes, setCommentLikes] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [submittingComment, setSubmittingComment] = useState(false);

  // New state for challenge creation
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newChallenge, setNewChallenge] = useState({
    title: '',
    description: '',
    weeklyPrompts: Array(4).fill('')
  });

  // Updated schedule config state
  const [scheduleConfig, setScheduleConfig] = useState({
    durationType: 'weekly',
    numberOfPeriods: 4,
    scheduleType: 'weekly',
    scheduleDays: [],
    scheduleWeek: 0,
    startDate: new Date().toISOString().split('T')[0]
  });
  

  const calculatePromptDates = (challenge) => {
    if (!challenge.prompt_dates) {
      // If no pre-calculated dates, calculate them client-side
      const startDate = moment(challenge.start_date);
      const dates = [];

      for (let i = 0; i < challenge.weekly_prompts.length; i++) {
        let periodStart = moment(startDate);
        let periodEnd;

        if (challenge.schedule_type === 'specific_weekday') {
          if (challenge.duration_type === 'monthly') {
            periodStart = moment(startDate).add(i, 'months');
            if (challenge.schedule_week === -1) {
              // Last week of month
              periodStart.endOf('month');
              while (periodStart.day() !== challenge.schedule_days[0]) {
                periodStart.subtract(1, 'days');
              }
              
              // End date is day before next occurrence
              periodEnd = moment(periodStart).add(1, 'month').endOf('month');
              while (periodEnd.day() !== challenge.schedule_days[0]) {
                periodEnd.subtract(1, 'days');
              }
              periodEnd.subtract(1, 'days');
            } else {
              // Nth week of month
              periodStart.startOf('month');
              while (periodStart.day() !== challenge.schedule_days[0]) {
                periodStart.add(1, 'days');
              }
              periodStart.add(challenge.schedule_week, 'weeks');
              
              // Calculate end date
              periodEnd = moment(periodStart).add(1, 'month').startOf('month');
              while (periodEnd.day() !== challenge.schedule_days[0]) {
                periodEnd.add(1, 'days');
              }
              periodEnd.add(challenge.schedule_week, 'weeks').subtract(1, 'days');
            }
          } else {
            // Weekly schedule
            periodStart = moment(startDate).add(i, 'weeks');
            periodEnd = moment(periodStart).add(6, 'days');
          }
        } else if (challenge.schedule_type === 'specific_date') {
          periodStart = moment(startDate).add(i, 'months').date(challenge.schedule_days[0]);
          periodEnd = moment(periodStart).add(1, 'month').subtract(1, 'day');
        } else {
          // Regular weekly/monthly
          if (challenge.duration_type === 'weekly') {
            periodStart = moment(startDate).add(i, 'weeks');
            periodEnd = moment(periodStart).add(6, 'days');
          } else {
            periodStart = moment(startDate).add(i, 'months');
            periodEnd = moment(periodStart).add(1, 'month').subtract(1, 'day');
          }
        }

        dates.push({
          start: periodStart,
          end: periodEnd
        });
      }
      return dates;
    } else {
      // Use pre-calculated dates from the backend
      return challenge.prompt_dates.map(dateRange => ({
        start: moment(dateRange.start),
        end: moment(dateRange.end)
      }));
    }
  };


  useEffect(() => {
    console.log('GroupChallenges useEffect triggered with groupId:', groupId);
    fetchGroupChallengesAndParticipation();
  }, [groupId, userId]);


const toggleChallenge = async (challengeId) => {
    setExpandedChallenges(prev => ({
      ...prev,
      [challengeId]: !prev[challengeId]
    }));

    if (!expandedChallenges[challengeId]) {
      await fetchChallengeComments(challengeId);
    }
  };


  // New handler for period count changes
  const handlePeriodCountChange = (e) => {
    const count = parseInt(e.target.value);
    if (isNaN(count)) return;
    
    const validCount = Math.min(Math.max(1, count), 24);
    
    setScheduleConfig(prev => ({
      ...prev,
      numberOfPeriods: validCount
    }));

    setNewChallenge(prev => {
      const newPrompts = Array(validCount).fill('');
      for (let i = 0; i < Math.min(prev.weeklyPrompts.length, validCount); i++) {
        newPrompts[i] = prev.weeklyPrompts[i];
      }
      return {
        ...prev,
        weeklyPrompts: newPrompts
      };
    });
  };


  const fetchChallengeComments = async (challengeId) => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_CHALLENGE_COMMENTS
          .replace(':groupId', groupId)
          .replace(':challengeId', challengeId)
      );
      setComments(prev => ({
        ...prev,
        [challengeId]: response.data
      }));
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const fetchChallengeParticipants = async (challengeId) => {
    try {
      const participantsUrl = API_ENDPOINTS.GROUP_CHALLENGE_PARTICIPANTS
        .replace(':groupId', groupId)
        .replace(':challengeId', challengeId);
      
      const response = await axios.get(participantsUrl);
      setChallengeParticipants(prev => ({
        ...prev,
        [challengeId]: response.data
      }));
      return response.data;
    } catch (error) {
      console.error(`Error fetching participants for challenge ${challengeId}:`, error);
      return [];
    }
  };

  const fetchGroupChallengesAndParticipation = async () => {
    setLoading(true);
    try {
      const url = API_ENDPOINTS.GROUP_CHALLENGES_LIST.replace(':groupId', groupId);
      const response = await axios.get(url);
      setChallenges(response.data);

      const participationStatus = {};
      for (const challenge of response.data) {
        const participants = await fetchChallengeParticipants(challenge.id);
        participationStatus[challenge.id] = participants.some(p => p.id === parseInt(userId));
      }
      
      setParticipatingChallenges(participationStatus);
      setError(null);
    } catch (error) {
      console.error('GroupChallenges error fetching data:', error);
      setError('Failed to load challenges. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleParticipation = async (challengeId, e) => {
    e?.stopPropagation();
    try {
      const challenge = challenges.find(c => c.id === challengeId);
      if (!challenge) return;

      const joinUrl = API_ENDPOINTS.JOIN_GROUP_CHALLENGE
        .replace(':groupId', groupId)
        .replace(':challengeId', challengeId);

      await axios.post(joinUrl, {
        user_id: userId
      });

      await fetchChallengeParticipants(challengeId);
      
      setParticipatingChallenges(prev => ({
        ...prev,
        [challengeId]: true
      }));
    } catch (error) {
      console.error('Error updating challenge participation:', error);
      const errorMessage = error.response?.data?.error || 'Failed to update challenge participation';
      alert(errorMessage);
    }
  };

  // New scheduling handlers
  const handleDurationTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      durationType: type,
      numberOfPeriods: type === 'weekly' ? 4 : 3,
      scheduleType: type,
      scheduleDays: []
    }));

    // Update prompts array based on new duration
    const newCount = type === 'weekly' ? 4 : 3;
    setNewChallenge(prev => ({
      ...prev,
      weeklyPrompts: Array(newCount).fill('')
    }));
  };

  const handleScheduleTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      scheduleType: type,
      scheduleDays: [],
      scheduleWeek: type === 'specific_weekday' ? 0 : null
    }));
  };

  const handleDaySelection = (day) => {
    setScheduleConfig(prev => {
      const newDays = prev.scheduleDays.includes(day)
        ? prev.scheduleDays.filter(d => d !== day)
        : [...prev.scheduleDays, day].sort();
      return { ...prev, scheduleDays: newDays };
    });
  };

  const handleCommentSubmit = async (challengeId, weekIndex) => {
    const commentContent = newComments[`${challengeId}-${weekIndex}`];
    if (!commentContent?.trim() || submittingComment) return;
    
    setSubmittingComment(true);
    try {
      const response = await axios.post(
        API_ENDPOINTS.CREATE_CHALLENGE_COMMENT
          .replace(':groupId', groupId)
          .replace(':challengeId', challengeId),
        {
          user_id: userId,
          week_index: weekIndex,
          content: commentContent
        }
      );

      setComments(prev => ({
        ...prev,
        [challengeId]: [...(prev[challengeId] || []), response.data]
      }));

      setNewComments(prev => ({
        ...prev,
        [`${challengeId}-${weekIndex}`]: ''
      }));

      setTimeout(() => {
        const commentSection = document.querySelector(
          `[data-challenge="${challengeId}"][data-week="${weekIndex}"] .comments-section`
        );
        if (commentSection) {
          commentSection.scrollTop = commentSection.scrollHeight;
        }
      }, 100);

    } catch (error) {
      console.error('Error submitting comment:', error);
    } finally {
      setSubmittingComment(false);
    }
  };

  const handleLikeToggle = async (challengeId, commentId) => {
    try {
      const response = await axios.post(
        API_ENDPOINTS.TOGGLE_COMMENT_LIKE
          .replace(':groupId', groupId)
          .replace(':commentId', commentId),
        { user_id: userId }
      );
      
      setCommentLikes(prev => ({
        ...prev,
        [commentId]: response.data.likes
      }));
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  // New challenge creation handler
  const handleCreateChallenge = async (e) => {
    e.preventDefault();
    try {
      const challengeData = {
        title: newChallenge.title,
        description: newChallenge.description,
        weeklyPrompts: newChallenge.weeklyPrompts,
        user_id: userId,
        duration_type: scheduleConfig.durationType,
        duration_periods: scheduleConfig.numberOfPeriods,
        schedule_type: scheduleConfig.scheduleType,
        schedule_days: scheduleConfig.scheduleDays,
        schedule_week: scheduleConfig.scheduleWeek,
        start_date: scheduleConfig.startDate
      };

      await axios.post(
        API_ENDPOINTS.CREATE_CUSTOM_GROUP_CHALLENGE.replace(':groupId', groupId),
        challengeData
      );

      // Reset form
      setNewChallenge({
        title: '',
        description: '',
        weeklyPrompts: ['', '', '', '']
      });
      setScheduleConfig({
        durationType: 'weekly',
        numberOfPeriods: 4,
        scheduleType: 'weekly',
        scheduleDays: [],
        scheduleWeek: 0,
        startDate: new Date().toISOString().split('T')[0]
      });
      setShowCreateForm(false);

      // Refresh challenges list
      fetchGroupChallengesAndParticipation();

      alert('Challenge created successfully!');
    } catch (error) {
      console.error('Error creating challenge:', error);
      alert('Failed to create challenge. Please try again.');
    }
  };

  const renderParticipants = (challengeId) => {
    const participants = challengeParticipants[challengeId] || [];
    
    return (
      <div className="challenge-participants">
        <div className="participants-header">
          <FaUsers className="participants-icon" />
          <span>Current Participants ({participants.length})</span>
        </div>
        <div className="participants-list">
          {participants.map(participant => (
            <div key={participant.id} className="participant-item">
              <span className="participant-name">{participant.name}</span>
              <div className="participant-progress">
                {participant.period_progress?.map((status, index) => (
                  <span 
                    key={index} 
                    className={`progress-dot ${status === 'completed' ? 'completed' : ''}`}
                    title={`${scheduleConfig.durationType === 'weekly' ? 'Week' : 'Month'} ${index + 1}: ${status}`}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderScheduleConfiguration = () => (
    <div className="schedule-configuration">
      <div className="form-group">
        <label>Duration Type</label>
        <select 
          value={scheduleConfig.durationType}
          onChange={(e) => handleDurationTypeChange(e.target.value)}
          className="schedule-select"
        >
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      <div className="form-group">
        <label>Number of {scheduleConfig.durationType === 'weekly' ? 'Weeks' : 'Months'}</label>
        <input
          type="number"
          min="1"
          max={scheduleConfig.durationType === 'weekly' ? 52 : 12}
          value={scheduleConfig.numberOfPeriods}
          onChange={handlePeriodCountChange}
          className="schedule-input"
        />
      </div>

      <div className="form-group">
        <label>Schedule Type</label>
        <select
          value={scheduleConfig.scheduleType}
          onChange={(e) => handleScheduleTypeChange(e.target.value)}
          className="schedule-select"
        >
          {scheduleConfig.durationType === 'weekly' ? (
            <>
              <option value="weekly">Regular Weekly</option>
              <option value="specific_weekday">Specific Days</option>
            </>
          ) : (
            <>
              <option value="monthly">Regular Monthly</option>
              <option value="specific_date">Specific Date</option>
              <option value="specific_weekday">Specific Weekday</option>
            </>
          )}
        </select>
      </div>

      {scheduleConfig.scheduleType === 'specific_weekday' && (
        <div className="weekday-selection">
          {scheduleConfig.durationType === 'monthly' && (
            <div className="form-group">
              <label>Week of Month</label>
              <select
                value={scheduleConfig.scheduleWeek}
                onChange={(e) => setScheduleConfig(prev => ({
                  ...prev,
                  scheduleWeek: parseInt(e.target.value)
                }))}
                className="schedule-select"
              >
                <option value="0">First</option>
                <option value="1">Second</option>
                <option value="2">Third</option>
                <option value="3">Fourth</option>
                <option value="-1">Last</option>
              </select>
            </div>
          )}
          <div className="weekday-buttons">
            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, idx) => (
              <button
                key={idx}
                type="button"
                className={`weekday-button ${scheduleConfig.scheduleDays.includes(idx) ? 'selected' : ''}`}
                onClick={() => handleDaySelection(idx)}
              >
                {day}
              </button>
            ))}
          </div>
        </div>
      )}

      {scheduleConfig.scheduleType === 'specific_date' && (
        <div className="date-selection">
          <label>Select Date(s) of Month</label>
          <div className="date-grid">
            {Array.from({length: 31}, (_, i) => i + 1).map(date => (
              <button
                key={date}
                type="button"
                className={`date-button ${scheduleConfig.scheduleDays.includes(date) ? 'selected' : ''}`}
                onClick={() => handleDaySelection(date)}
              >
                {date}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="form-group">
        <label>Start Date</label>
        <input
          type="date"
          value={scheduleConfig.startDate}
          onChange={(e) => setScheduleConfig(prev => ({
            ...prev,
            startDate: e.target.value
          }))}
          min={new Date().toISOString().split('T')[0]}
          className="schedule-input"
        />
      </div>
    </div>
  );
  
  const renderWeekComments = (challengeId, weekIndex, weekComments = []) => {
      const sortedComments = [...weekComments]
        .filter(c => c.week_index === weekIndex)
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      
      const totalComments = sortedComments.length;
      const visibleComments = expandedComments[`${challengeId}-${weekIndex}`]
        ? sortedComments
        : sortedComments.slice(Math.max(0, totalComments - 3));

      return (
        <div className="week-comments" data-challenge={challengeId} data-week={weekIndex}>
          <div className="comments-section">
            {/* Show More button at top if there are hidden older comments */}
            {totalComments > 3 && !expandedComments[`${challengeId}-${weekIndex}`] && (
              <>
                <button
                  className="show-more-button"
                  onClick={() => setExpandedComments(prev => ({
                    ...prev,
                    [`${challengeId}-${weekIndex}`]: true
                  }))}
                >
                  <FaChevronUp />
                  View {totalComments - 3} Previous Comments
                </button>
                <div className="comments-divider" />
              </>
            )}

            {visibleComments.map(comment => (
              <div key={comment.id} className="comment-item">
                <div className="comment-header">
                  <span className="comment-author">{comment.user_name}</span>
                  <span className="comment-date">
                    {new Date(comment.created_at).toLocaleDateString()}
                  </span>
                </div>
                <p className="comment-content">{comment.content}</p>
                <div className="comment-actions">
                  <button 
                    className="like-button"
                    onClick={() => handleLikeToggle(challengeId, comment.id)}
                  >
                    {comment.likes?.includes(userId) 
                      ? <FaHeart className="text-red-500" />
                      : <FaRegHeart />
                    }
                    <span>{comment.likes?.length || 0}</span>
                  </button>
                </div>
              </div>
            ))}

            {/* Show Less button at bottom when expanded */}
            {totalComments > 3 && expandedComments[`${challengeId}-${weekIndex}`] && (
              <>
                <div className="comments-divider" />
                <button
                  className="show-less-button"
                  onClick={() => setExpandedComments(prev => ({
                    ...prev,
                    [`${challengeId}-${weekIndex}`]: false
                  }))}
                >
                  Show Most Recent Comments Only
                  <FaChevronDown />
                </button>
              </>
            )}
          </div>

          {participatingChallenges[challengeId] && (
            <div className="comment-input-section">
              <textarea
                value={newComments[`${challengeId}-${weekIndex}`] || ''}
                onChange={(e) => setNewComments(prev => ({
                  ...prev,
                  [`${challengeId}-${weekIndex}`]: e.target.value
                }))}
                placeholder="Add a comment..."
                className="comment-input"
              />
              <button
                className="submit-comment-button"
                onClick={() => handleCommentSubmit(challengeId, weekIndex)}
                disabled={submittingComment || !newComments[`${challengeId}-${weekIndex}`]?.trim()}
              >
                {submittingComment ? 'Posting...' : 'Post Comment'}
              </button>
            </div>
          )}
        </div>
      );
  };

  if (loading) return <div className="loading-message">Loading challenges...</div>;
  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">Error: {error}</p>
        <button onClick={fetchGroupChallengesAndParticipation} className="retry-button">
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="group-challenges">
      <div className="challenges-header">
        <h2><FaTrophy className="trophy-icon" /> Group Challenges</h2>
        <button 
          className="create-challenge-button"
          onClick={() => setShowCreateForm(!showCreateForm)}
        >
          <FaPlus /> Create New Challenge
        </button>
      </div>

      {showCreateForm && (
        <div className="create-challenge-form">
          <form onSubmit={handleCreateChallenge}>
            <div className="form-group">
              <label>Challenge Title</label>
              <input
                type="text"
                value={newChallenge.title}
                onChange={(e) => setNewChallenge(prev => ({
                  ...prev,
                  title: e.target.value
                }))}
                placeholder="Enter challenge title"
                required
                className="challenge-title-input"
              />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                value={newChallenge.description}
                onChange={(e) => setNewChallenge(prev => ({
                  ...prev,
                  description: e.target.value
                }))}
                placeholder="Enter challenge description"
                className="challenge-description-input"
              />
            </div>

            {renderScheduleConfiguration()}

            <div className="weekly-prompts-container">
              {newChallenge.weeklyPrompts.map((prompt, index) => (
                <div key={index} className="form-group">
                  <label>
                    {scheduleConfig.durationType === 'weekly' ? 'Week' : 'Month'} {index + 1} Prompt
                  </label>
                  <textarea
                    value={prompt}
                    onChange={(e) => setNewChallenge(prev => ({
                      ...prev,
                      weeklyPrompts: prev.weeklyPrompts.map((p, i) => 
                        i === index ? e.target.value : p
                      )
                    }))}
                    placeholder={`Enter prompt for ${scheduleConfig.durationType === 'weekly' ? 'week' : 'month'} ${index + 1}`}
                    required
                    className="weekly-prompt-input"
                  />
                </div>
              ))}
            </div>

            <div className="form-actions">
              <button type="submit" className="submit-button">
                Create Challenge
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => setShowCreateForm(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      {challenges.length === 0 ? (
        <p className="no-challenges">No challenges available at the moment.</p>
      ) : (
        <div className="challenges-grid">
          {challenges.map(challenge => (
            <div key={challenge.id} className="challenge-card">
              <div 
                className="challenge-card-header"
                onClick={() => toggleChallenge(challenge.id)}
              >
                <div className="challenge-title-section">
                  <h3 className="challenge-title">{challenge.title}</h3>
                  <button className={`expand-button ${expandedChallenges[challenge.id] ? 'expanded' : ''}`}>
                    <FaChevronDown />
                  </button>
                </div>
                <div className="challenge-meta">
                  <div className="challenge-schedule">
                    <FaCalendar />
                    <span>
                      {challenge.duration_type === 'weekly' ? 'Weekly' : 'Monthly'} - 
                      {challenge.schedule_type === 'specific_weekday' ? (
                        <>
                          {challenge.duration_type === 'monthly' ? 'Every ' : ''}
                          {challenge.schedule_week === -1 ? 'Last ' : 
                            challenge.schedule_week === 0 ? 'First ' :
                            challenge.schedule_week === 1 ? 'Second ' :
                            challenge.schedule_week === 2 ? 'Third ' :
                            challenge.schedule_week === 3 ? 'Fourth ' : ''
                          }
                          {challenge.schedule_days?.map(day => 
                            ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day]
                          ).join(', ')}
                        </>
                      ) : challenge.schedule_type === 'specific_date' ? (
                        ` Day${challenge.schedule_days?.length > 1 ? 's' : ''} ${challenge.schedule_days?.join(', ') || ''}`
                      ) : (
                        ` ${challenge.schedule_type === 'weekly' ? 'Every Week' : 'Every Month'}`
                      )}
                    </span>
                  </div>
                  <span className="challenge-date">
                    Created: {new Date(challenge.created_at).toLocaleDateString()}
                  </span>
                  {participatingChallenges[challenge.id] ? (
                    <div className="participating-badge">
                      <FaCheckCircle className="text-green-500" />
                      <span>Participating</span>
                    </div>
                  ) : (
                    <button 
                      className="participation-button"
                      onClick={(e) => handleParticipation(challenge.id, e)}
                    >
                      <FaUserPlus /> Join Challenge
                    </button>
                  )}
                </div>
              </div>

              <div className={`challenge-content ${expandedChallenges[challenge.id] ? 'expanded' : ''}`}>
                {challenge.description && (
                  <p className="challenge-description">{challenge.description}</p>
                )}

                {challenge.weekly_prompts && challenge.weekly_prompts.length > 0 && (
                  <div className="challenge-preview">
                    <h4 className="prompts-preview-title">
                      {challenge.duration_type === 'weekly' ? 'Weekly' : 'Monthly'} Prompts Preview
                    </h4>
                    <div className="prompts-preview-grid">
                      {(() => {
                        const promptDates = calculatePromptDates(challenge);
                        return challenge.weekly_prompts.map((prompt, index) => (
                          <div key={index} className="prompt-preview-card">
                            <div className="prompt-preview-week">
                              {challenge.duration_type === 'weekly' ? 'Week' : 'Month'} {index + 1}
                              <div className="prompt-dates">
                                Starts: {promptDates[index]?.start.format('MMM D, YYYY')}
                                <br />
                                Ends: {promptDates[index]?.end.format('MMM D, YYYY')}
                              </div>
                            </div>
                            <p className="prompt-preview-text">{prompt}</p>
                            <div className="comments-preview">
                              <FaComment /> 
                              {(comments[challenge.id] || [])
                                .filter(c => c.week_index === index).length} Comments
                            </div>
                            {renderWeekComments(challenge.id, index, comments[challenge.id])}
                          </div>
                        ));
                      })()}
                    </div>
                  </div>
                )}

                {renderParticipants(challenge.id)}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupChallenges;