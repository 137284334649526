import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { 
  FaUsers, FaPlus, FaTimes, FaCog, FaCheck, FaArrowRight, FaLock, FaGlobe, 
  FaSpinner, FaComments, FaUserFriends
} from 'react-icons/fa';
import { API_ENDPOINTS } from '../../config/api';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import './GroupDirectory.css';

const GroupDirectory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDescription, setNewGroupDescription] = useState('');
  const [isJoining, setIsJoining] = useState({});
  const userId = useSelector(state => state.user.userId);
  const tempUserId = useSelector(state => state.user.tempUserId);
  const isAdmin = useSelector(state => state.user.isAdmin);
  const navigate = useNavigate();

  // Get view type from URL params, default to community (original behavior)
  const viewType = searchParams.get('view') || 'community';

  useEffect(() => {
    fetchGroups();
  }, [userId, viewType]);

  const fetchGroups = async () => {
    try {
      const url = userId 
        ? `${API_ENDPOINTS.GET_PUBLIC_GROUPS}?user_id=${userId}&type=${viewType}`
        : `${API_ENDPOINTS.GET_PUBLIC_GROUPS}?type=${viewType}`;
      
      const response = await axios.get(url);
      setGroups(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching groups:', error);
      setError("Unable to load groups. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    
    const effectiveUserId = userId || tempUserId;
    if (!effectiveUserId || effectiveUserId > 4000) {
      setShowLoginPrompt(true);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(API_ENDPOINTS.CREATE_GROUP, {
        name: newGroupName,
        description: newGroupDescription,
        admin_id: userId,
        is_public: true,
        type: viewType // Send the current view type
      });
      
      setShowCreateModal(false);
      setNewGroupName('');
      setNewGroupDescription('');
      await fetchGroups();
      alert(`${viewType === 'topics' ? 'Topic' : 'Group'} created successfully!`);
    } catch (error) {
      console.error('Error creating group:', error);
      alert(error.response?.data?.error || 'Failed to create group. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };



  const handleJoinGroup = async (groupId) => {
    const effectiveUserId = userId || tempUserId;
    
    if (!effectiveUserId || effectiveUserId > 4000) {
      setSelectedGroupId(groupId);
      setShowLoginPrompt(true);
      return;
    }

    setIsJoining(prev => ({ ...prev, [groupId]: true }));
    try {
      const response = await axios.post(
        API_ENDPOINTS.JOIN_GROUP.replace(':groupId', groupId),
        { user_id: userId }
      );
      
      setGroups(groups.map(group => 
        group.id === groupId 
          ? { ...group, is_member: true }
          : group
      ));
      alert('Successfully joined the group!');
    } catch (error) {
      console.error('Error joining group:', error);
      alert(error.response?.data?.error || 'Failed to join group. Please try again.');
    } finally {
      setIsJoining(prev => ({ ...prev, [groupId]: false }));
    }
  };


  const navigateToGroupPage = (groupId) => {
    navigate(`/group/${groupId}`);
  };

  const toggleView = (type) => {
    setSearchParams(type === 'community' ? {} : { view: type });
  };

  // Existing render functions remain the same
  const renderLoginPrompt = () => (
    <div className="modal-overlay">
      <div className="login-prompt">
        <h2>Join the Community</h2>
        <p>Please log in or create an account to join faith groups.</p>
        <div className="login-prompt-actions">
          <Link to="/signin" className="signin-button">
            Sign In
          </Link>
          <Link to="/signup" className="signup-button">
            Create Account
          </Link>
        </div>
        <button 
          onClick={() => setShowLoginPrompt(false)} 
          className="close-modal"
        >
          <FaTimes />
        </button>
      </div>
    </div>
  );

  const renderGroupCard = (group) => (
    <div key={group.id} className="group-card">
      <div className="group-card-header">
        <h3>{group.name}</h3>
        <div className="group-visibility">
          {group.is_public ? (
            <span className="public-badge">
              <FaGlobe /> Public
            </span>
          ) : (
            <span className="private-badge">
              <FaLock /> Private
            </span>
          )}
        </div>
      </div>

      <p className="group-description">{group.description}</p>

      <div className="group-status">
        {group.is_member && (
          <span className="member-badge">
            <FaCheck /> Member
          </span>
        )}
        {group.is_admin && (
          <span className="admin-badge">
            <FaCog /> Admin
          </span>
        )}
      </div>

      <div className="group-actions">
        {!group.is_member ? (
          <button 
            onClick={() => handleJoinGroup(group.id)} 
            className="join-button"
            disabled={isJoining[group.id]}
          >
            {isJoining[group.id] ? (
              <>
                <FaSpinner className="icon-spin" /> Joining...
              </>
            ) : (
              <>
                <FaPlus /> Join {group.type === 'topic' ? 'Topic' : 'Group'}
              </>
            )}
          </button>
        ) : (
          <>
            <button 
              onClick={() => navigateToGroupPage(group.id)} 
              className="view-button"
            >
              <FaArrowRight /> View {group.type === 'topic' ? 'Topic' : 'Group'}
            </button>

            {group.is_admin && (
              <Link 
                to={`/group/${group.id}?tab=admin`} 
                className="admin-button"
              >
                <FaCog /> Manage
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );

  if (error && !isLoading) {
    return (
      <div className="group-directory-container">
        <Header />
        <div className="group-directory-content">
          <Navbar />
          <div className="error-container">
            <p className="error-message">{error}</p>
            <button onClick={fetchGroups} className="retry-button">
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="group-directory-container">
      <Header />
      <div className="group-directory-content">
        <Navbar />
        <div className="group-directory-main">
          <div className="directory-header">
            <div className="header-content">
              <div className="header-title-section">
                <h2>
                  {viewType === 'topics' ? (
                    <><FaComments /> Faith Topics</>
                  ) : (
                    <><FaUsers /> Faith Groups</>
                  )}
                </h2>
                <div className="view-toggle">
                </div>
              </div>
              <div className="header-actions">
                {viewType === 'topics' ? (
                  // For Topics view, only show create button if user is 287 or is admin
                  (userId === 287 || isAdmin) && (
                    <button 
                      onClick={() => setShowCreateModal(true)} 
                      className="create-group-button create-topic-button"
                    >
                      <FaPlus /> Create New Topic
                    </button>
                  )
                ) : (
                  // For Community view, always show create button
                  <button 
                    onClick={() => setShowCreateModal(true)} 
                    className="create-group-button"
                  >
                    <FaPlus /> Create New Group
                  </button>
                )}
              </div>
            </div>
            <p className="directory-description">
              {viewType === 'topics' 
                ? 'Join faith-based discussion topics and grow together.'
                : 'Join faith groups to share your journey and grow together in community.'}
            </p>
          </div>

          {isLoading ? (
            <div className="loading-container">
              <FaSpinner className="icon-spin" />
              <p>Loading {viewType === 'topics' ? 'topics' : 'groups'}...</p>
            </div>
          ) : (
            <div className="groups-grid">
              {groups.map(renderGroupCard)}
            </div>
          )}

          {showLoginPrompt && renderLoginPrompt()}

          {showCreateModal && (
            <div className="modal-overlay">
              <div className="modal">
                <h3>Create New {viewType === 'topics' ? 'Topic' : 'Faith Group'}</h3>
                <form onSubmit={handleCreateGroup}>
                  <div className="form-group">
                    <label>{viewType === 'topics' ? 'Topic' : 'Group'} Name</label>
                    <input
                      type="text"
                      value={newGroupName}
                      onChange={(e) => setNewGroupName(e.target.value)}
                      placeholder={`Enter ${viewType === 'topics' ? 'topic' : 'group'} name`}
                      required
                      maxLength={50}
                    />
                  </div>

                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      value={newGroupDescription}
                      onChange={(e) => setNewGroupDescription(e.target.value)}
                      placeholder={`Describe your ${viewType === 'topics' ? 'topic' : 'group'}'s purpose and mission`}
                      required
                      maxLength={500}
                    />
                  </div>

                  <div className="modal-actions">
                    <button type="submit" className="create-button">
                      Create {viewType === 'topics' ? 'Topic' : 'Group'}
                    </button>
                    <button 
                      type="button" 
                      onClick={() => setShowCreateModal(false)}
                      className="cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
                <button 
                  onClick={() => setShowCreateModal(false)} 
                  className="close-modal"
                >
                  <FaTimes />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupDirectory;