import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FiArrowRight,
  FiCheck,
  FiDownload,
  FiFileText,
  FiBookOpen,
} from "react-icons/fi";
import "./About.css";
import PublicLayout from "../../components/PublicLayout/PublicLayout";

const About = () => {
  const navigate = useNavigate();

  const researchPapers = [
    {
      title: "Quarterly S&P Machine Learning Alpha Strategy",
      description: "Systematic analysis and trading strategy for S&P sectors",
      file: "sp500.pdf",
      icon: <FiFileText size={24} />,
    },
    {
      title: "Twitter NLP Breaking News",
      description:
        "Analyzing breaking news on Twitter using Natural Language Processing",
      file: "twitter.pdf",
      icon: <FiFileText size={24} />,
    },
    {
      title: "FinBert Market Moving Text Analysis",
      description:
        "Using FinBert for market-moving text analysis in financial markets",
      file: "finbert.pdf",
      icon: <FiFileText size={24} />,
    },
  ];

  const handleDownloadPdf = (filename) => {
    const link = document.createElement("a");
    link.href = `/${filename}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCaseStudyClick = () => {
    navigate("/case-study");
  };

  return (
    <PublicLayout
      className="about-container"
      render={() => (
        <>
          {/* Hero Section */}
          <header className="about-hero">
            <div className="about-hero-content">
              <h1>Our Story</h1>
              <p className="about-subtitle">
                From Quantitative Finance to AI-Powered Spiritual Guidance
              </p>
            </div>
          </header>

          {/* Story Section */}
          <section className="founder-story">
            <div className="story-content">
              <h2>The Journey</h2>
              <div className="story-text">
                <p>
                  CrossValidation.ai is a startup in Menlo Park founded by
                  Adrian Bialonczyk, who previously built AI models for
                  quantitative investing after founding a hedge fund in
                  Cambridge, MA in 2016.
                </p>

                <p>
                  A pivotal moment in Adrian's faith journey occurred when he
                  met Focus missionaries at MIT. Through the Blessed Mother's
                  intercession, this encounter transformed his relationship with
                  Christ.
                </p>

                <p>
                  After moving to the Bay Area in 2020, Adrian led a young adult
                  group at St. Raymond's Parish, organizing adorations, homeless
                  outreach, and community events. His experiences with spiritual
                  direction revealed both its profound impact and opportunities
                  for enhancement through technology.
                </p>

                <p>
                  Launched in 2024, CrossValidation.ai aims to help make God the
                  center of daily life through 24/7 AI-assisted spiritual
                  guidance, complementing traditional spiritual direction while
                  making it more accessible and less intimidating.
                </p>
              </div>
            </div>
          </section>

          <section className="research-section">
            <div className="section-header">
              <h2>Research Publications</h2>
              <p>AI and Machine Learning Research Background</p>
            </div>
            <div className="research-grid">
              {researchPapers.map((paper, index) => (
                <div key={index} className="research-card">
                  <div className="research-icon">{paper.icon}</div>
                  <h3>{paper.title}</h3>
                  <p>{paper.description}</p>
                  <button
                    onClick={() => handleDownloadPdf(paper.file)}
                    className="download-button"
                  >
                    Download Paper <FiDownload className="button-icon" />
                  </button>
                </div>
              ))}
            </div>
          </section>

          {/* Case Study Section */}
          <section className="case-study-section">
            <div className="section-header">
              <h2>Implementation Study</h2>
              <p>St. Raymond's Young Adult Group Pilot Program</p>
            </div>
            <div className="case-study-content">
              <div className="case-study-info">
                <h3>The Rescue Project Integration</h3>
                <p>
                  A focused study on implementing AI-guided spiritual direction
                  within a parish young adult group during their gospel
                  reflection series.
                </p>
                <div className="study-highlights">
                  <div className="highlight-item">
                    <FiCheck className="highlight-icon" />
                    <span>3 active participants aged 30-33</span>
                  </div>
                  <div className="highlight-item">
                    <FiCheck className="highlight-icon" />
                    <span>2-week intensive pilot period</span>
                  </div>
                  <div className="highlight-item">
                    <FiCheck className="highlight-icon" />
                    <span>Daily prayer participation</span>
                  </div>
                  <div className="highlight-item">
                    <FiCheck className="highlight-icon" />
                    <span>100% completion rate</span>
                  </div>
                </div>
                <button
                  onClick={handleCaseStudyClick}
                  className="case-study-button"
                >
                  View Full Case Study <FiArrowRight className="button-icon" />
                </button>
              </div>
            </div>
          </section>

          {/* Spiritual Direction Guide Section */}
          <section className="guide-section">
            <div className="section-header">
              <h2>Guide to Spiritual Direction</h2>
              <p>Following Ignatian Spirituality</p>
            </div>
            <div className="guide-content">
              <div className="guide-info">
                <div className="guide-icon">
                  <FiBookOpen size={32} />
                </div>
                <h3>Spiritual Discernment Guide</h3>
                <p>
                  Comprehensive guide to spiritual direction and discernment,
                  including how AI can enhance and support your journey. Learn
                  about the principles of Ignatian spirituality and how to
                  integrate them with modern technology.
                </p>
                <button
                  onClick={() => handleDownloadPdf("spirituality.pdf")}
                  className="guide-download-button"
                >
                  Download Guide <FiDownload className="button-icon" />
                </button>
              </div>
            </div>
          </section>
        </>
      )}
    />
  );
};

export default About;