// StripeCheckout.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { LockIcon } from 'lucide-react';
import { API_ENDPOINTS } from '../../config/api';

const stripePromise = loadStripe('pk_live_51OoY4hIY48BI77g22zg36hsU7fRGBlvsMd09IJ8PHEqsvWXsOqf973Rn3I75ZYDQcZgNYAzmFlIKlGO0Pvs22oXq00IB4OTGNf');

const StripeCheckout = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { userId } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const createCheckoutSession = async () => {
      console.log('Starting checkout with userId:', userId);
      
      if (!userId) {
        console.error('No userId found');
        setError('User ID is required');
        setIsLoading(false);
        return;
      }
      
      setIsLoading(true);
      setError(null);
      
      try {
        const requestData = {
          userId,
          priceId: 'price_1QMgNbIY48BI77g2kzJzKkID',
          returnUrl: `${window.location.origin}/faith-journey`,
        };
        console.log('Request data:', requestData);

        const response = await fetch(API_ENDPOINTS.CREATE_CHECKOUT_SESSION, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
          credentials: 'include',
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to create checkout session: ${errorText}`);
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (err) {
        console.error('Checkout error:', err);
        setError(err.message || 'Failed to initialize checkout');
        setShowErrorModal(true);
      } finally {
        setIsLoading(false);
      }
    };

    createCheckoutSession();
  }, [userId, navigate]);

  const handlePaymentComplete = () => {
    console.log('Payment completed successfully');
    setShowSuccessModal(true);
    setTimeout(() => {
      setShowSuccessModal(false);
      navigate('/faith-journey');
    }, 2000);
  };

  return (
    <div className="stripe-checkout-container">
      <div className="subscription-details">
        <div className="plan-name">Premium Subscription</div>
        <div className="plan-price">$8<span className="plan-interval">/month</span></div>
      </div>

      {isLoading && (
        <div className="stripe-loading">
          <div className="stripe-loading-spinner" />
        </div>
      )}

      {error && !showErrorModal && (
        <div className="stripe-error">
          <p>{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="signup-submit-btn"
          >
            Try Again
          </button>
        </div>
      )}

      {!isLoading && !error && clientSecret && (
        <div className="stripe-element-container">
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ 
              clientSecret,
              onComplete: handlePaymentComplete
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      )}

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal-overlay">
          <div className="modal-content success">
            <h2>Payment Successful!</h2>
            <p className="mb-2">Your account has been upgraded to Premium.</p>
            <p className="text-sm text-gray-600">
              Redirecting to your faith journey...
            </p>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {showErrorModal && (
        <div className="modal-overlay">
          <div className="modal-content error">
            <h2>Payment Failed</h2>
            <p className="mb-4">There was an issue processing your payment.</p>
            <button 
              onClick={() => {
                setShowErrorModal(false);
                window.location.reload();
              }}
              className="retry-button"
            >
              Try Again
            </button>
          </div>
        </div>
      )}

      <div className="security-badge">
        <LockIcon size={16} />
        <span>Secure payment powered by Stripe</span>
      </div>
    </div>
  );
};

export default StripeCheckout;